import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useStore } from '../../store/store';

const LoggedOut = () => {
  const location = useLocation();
  const stepsDone = useStore((state) => state.stepsDone);
  const user = useStore((state) => state.user);
  if (location.state?.from !== '' && location.state?.from !== undefined) {
    sessionStorage.setItem('redirectPath', location.state?.from?.pathname);
  }
  const redirectTo = sessionStorage.getItem('redirectPath');
  return !user ? <Outlet /> : <Navigate to={redirectTo ?? '/fetching-data'} replace />;

  // return !user ? (
  //   <Outlet />
  // ) : user && !stepsDone ? (
  //   <Navigate to={redirectTo ?? '/onboarding'} replace />
  // ) : (
  //   <Navigate to={redirectTo ?? '/dashboard'} replace />
  // );
};

export default LoggedOut;
