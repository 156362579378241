import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import * as Button from '../../components/UI/Forms/Button';
import requestsService from '../../services/requestsService.service';
import DataTableBase from '../../components/UI/General/DataTableBase';
import Icon from '../../components/UI/General/Icon';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import generalStyles from '../../styles/general.module.css';
import { useStore } from '../../store/store';
import Box from '../../components/UI/General/Box';
import Toast from '../../components/UI/General/Toast';
import Export from '../../components/shared/Export';
import AddButton from '../../components/shared/AddButton';
import Modal from '../../components/UI/Modal/Modal';
import Text from '../../components/UI/Typography/Text';
import { useSubscriptionAllowed } from '../../hooks/useSubscriptionAllowed';
import TabsSlider from '../../components/UI/General/TabsSlider';

const Roles = () => {
  const company = useStore((state) => state.company);
  const [tableData, setTableData] = useState([]);
  const { tab } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const currentSubscription = useSubscriptionAllowed();
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const [toExport, setToExport] = useState([]);

  const archiveRole = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    requestsService
      .archiveRole(id)
      .then((r) => {
        const updData = tableData.filter((item) => item.roleId !== id);
        setTableData(updData);
        setToast({
          opened: true,
          message: 'Archived',
          type: 'success',
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  const activateRole = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    requestsService
      .activateRole(id)
      .then((r) => {
        const updData = tableData.filter((item) => item.roleId !== id);
        setTableData(updData);
        setToast({
          opened: true,
          message: 'Activated',
          type: 'success',
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };

  const columns = useMemo(
    () => [
      {
        name: 'Role Name',
        sortable: true,
        selector: (row) => row?.roleName,
        cell: (row) => (
          <span data-content={'Full Name'} className={generalStyles.tableValue}>
            <Link to={'/user-roles-and-permissions/edit/' + row.roleId}>
              <span>{row.roleName === 'CLIENT_ADMIN' ? 'Site Admin' : row.roleName === 'END_USER' ? 'User' : row.roleName}</span>
            </Link>
          </span>
        ),
      },
      {
        name: 'Role Description',
        sortable: true,
        selector: (row) => row?.roleDescription,
        cell: (row) => (
          <span data-content={'Role Description'} className={generalStyles.tableValue}>
            <span>{row.roleDescription}</span>
          </span>
        ),
      },
      {
        name: 'No of Users Assigned',
        sortable: true,
        selector: (row) => row?.numberOfUsersAssigned,
        cell: (row) => (
          <span data-content={'No of Users Assigned'} className={generalStyles.tableValue}>
            <span>{row.numberOfUsersAssigned}</span>
          </span>
        ),
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  {tab === 'active' ? (
                    <>
                      <Link to={'/user-roles-and-permissions/edit/' + row.roleId}>
                        <MenuItem>
                          {['CLIENT_ADMIN', 'END_USER'].includes(row.roleName) ? 'View' : 'Edit'}
                        </MenuItem>
                      </Link>
                      {!['CLIENT_ADMIN', 'END_USER'].includes(row.roleName) && (
                        <MenuItem onClick={() => archiveRole(parseInt(row.roleId))}>
                          Archive
                        </MenuItem>
                      )}
                    </>
                  ) : (
                    <MenuItem onClick={() => activateRole(parseInt(row.roleId))}>Activate</MenuItem>
                  )}
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [tab, archiveRole, activateRole],
  );

  useEffect(() => {
    const initData = () => {
      requestsService
        .getRolesByCompany(company.parentId !== undefined ? company.parentId : company.id)
        .then((r) => {
          if (r.data.message === 'Operation Successful') {
            const roles = r.data.data.filter(
              (item) =>
                item.isEnabled === (tab === 'active') &&
                !['AGENT', 'VENDOR', 'SUPER_ADMIN'].includes(item.roleName),
            );
            const csvData = r.data.data.map((item) => ({
              'Role Name': item.roleName,
              'Role Description': item.roleDescription,
              'No of Users Assigned': item.numberOfUsersAssigned,
              Status:
                item.isEnabled !== undefined && item.isEnabled !== false ? 'Active' : 'Archived',
            }));
            setToExport(csvData);
            setTableData(roles);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    initData();
  }, [tab, company]);

  return (
    <Box $radius={12} $mobExtend $asHolder>
      <div className={generalStyles.tabSection}>
        <TabsSlider>
          <NavLink
            to="/user-roles-and-permissions/active"
            className={({ isActive }) => (isActive ? 'active' : '')}
            $islink="true"
          >
            Active
          </NavLink>
          <NavLink
            to="/user-roles-and-permissions/archived"
            className={({ isActive }) => (isActive ? 'active' : '')}
            $islink="true"
          >
            Archived
          </NavLink>
        </TabsSlider>
        <div className={generalStyles.addItemButton}>
          <Export data={toExport} name="Roles" />
          <AddButton
            $click={() => {
              // if (!currentSubscription?.plan?.allowCustomRBACRoles) {
              //   setShowModal(true);
              //   return;
              // }
              navigate('/user-roles-and-permissions/create');
            }}
          />
        </div>
      </div>

      <DataTableBase data={tableData} columns={columns} />
      <Modal
        $show={showModal}
        $close={() => setShowModal(false)}
        $title="Subscription Limits"
        $radius={16}
        $closableOutside
      >
        <div className={generalStyles.subscriptionModal}>
          <Text>Advanced Role Management only available on Standard and Premium plans.</Text>
          <Button.Main
            $primary
            $style={'pink'}
            onClick={() => navigate('/accounts-billing/subscription')}
            type="button"
          >
            Upgrade
          </Button.Main>
        </div>
      </Modal>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </Box>
  );
};

export default Roles;
