import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Text from '../../components/UI/Typography/Text';
import Input from '../../components/UI/Forms/Input';
import * as Button from '../../components/UI/Forms/Button';
import Box from '../../components/UI/General/Box';
import spmsServiceService from '../../services/spmsService.service';
import 'react-datepicker/dist/react-datepicker.css';
import generalStyles from '../../styles/general.module.css';
import { useStore } from '../../store/store';
import CountryAndRegion from '../../components/UI/Forms/CountryAndRegion';
import Toast from '../../components/UI/General/Toast';
import styles from '../../styles/companySetup.module.css';
import ModalDiscard from '../../components/shared/ModalDiscard';

const TaxAdd = () => {
  const { taxId } = useParams();
  const { id } = useStore((state) => state.activeCompany);
  const [values, setValues] = useState(null);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const setIsDiscarded = useStore((state) => state.setIsDiscarded);
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      countryName: '',
      state: '',
      type: '',
      percentage: '',
    },
    values,
  });
  const navigate = useNavigate();
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  useEffect(() => {
    if (taxId && id) {
      spmsServiceService.getTax(id, taxId).then((res) => {
        const { countryName, state, ...others } = res.data.data;
        setValues({
          ...others,
          countryName: { label: countryName, value: countryName },
          state: { label: state, value: state },
        });
      });
    }
  }, [taxId, id]);

  const onSubmit = (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    let { countryName, state, type, percentage } = data;
    if (taxId) {
      const updData = {
        countryName: countryName.value,
        state: state.value,
        type: type,
        percentage: parseInt(percentage),
        taxId: taxId,
      };
      spmsServiceService
        .updateTax(id, updData)
        .then((res) => {
          setIsDiscarded(false)
          setToast({
            opened: true,
            message: 'Tax updated successfully',
            type: 'success',
            cb: () => navigate('/taxes'),
          });
        })
        .catch((reason) => {
          console.log(reason);
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message:
              reason.response.data.errors?.[0].message === undefined
                ? reason.response.data.message
                : reason.response.data.errors?.[0].message,
            type: 'fail',
          });
        });
    } else {
      const newData = {
        countryName: countryName.value,
        state: state.value,
        type: type,
        percentage: parseInt(percentage),
      };
      spmsServiceService
        .createTax(id, newData)
        .then((res) => {
          console.log(res);
          setIsDiscarded(false)
          setToast({
            opened: true,
            message: 'Tax created successfully',
            type: 'success',
            cb: () => navigate('/taxes'),
          });
        })
        .catch((reason) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message:
              reason.response.data.errors[0].message === undefined
                ? reason.response.data.message
                : reason.response.data.errors[0].message,
            type: 'fail',
          });
        });
    }
  };

  return (
    <>
      <form id="taxForm">
        <Box $mobExtend $asHolder $noOverflow>
          <div className={styles.title}>
            <Text weight={500} type="subtitle">
              {!taxId ? 'Add' : 'Edit'} Tax
            </Text>
          </div>
          <div className={generalStyles.fieldsFour}>
            <div className="inp-container">
              <Controller
                name="type"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Tax Name is required',
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Tax Name"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Name"
                    $labelRequired
                    {...field}
                  />
                )}
              />
              {errors.type && <p className="error-message">{errors?.type?.message}</p>}
            </div>
            <CountryAndRegion
              countryFieldName="countryName"
              regionFieldName="state"
              control={control}
              errors={errors}
              isRequired={true}
              isLabel
              isAllRegion
            />

            <div className="inp-container">
              <Controller
                name="percentage"
                control={control}
                rules={{
                  required: true,
                  pattern: /^[0-9]+$/,
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="10"
                    maxLength={3}
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Rate %"
                    $labelRequired
                    {...field}
                  />
                )}
              />
              {errors.percentage?.type === 'required' && (
                <p className="error-message">Rate % is required</p>
              )}
              {errors.percentage?.type === 'pattern' && (
                <p className="error-message">Only numbers allowed</p>
              )}
            </div>
          </div>
        </Box>
      </form>
      <div className={generalStyles.pageButtons}>
        <Button.Main
          $primary
          $style={isDirty ? 'blue' : 'gray'}
          type="submit"
          form="taxForm"
          onClick={handleSubmit((data) => onSubmit(data))}
          disabled={!isDirty || isButtonsBlocked}
        >
          Save
        </Button.Main>
        <Button.Main
          $primary
          $style={isDirty ? 'gray' : 'blue'}
          type="button"
          onClick={() => isDirty ? setShowModal(true) : navigate('/taxes')}
        >
          Discard
        </Button.Main>
      </div>
      <ModalDiscard setShowModal={setShowModal} showModal={showModal} navigateTo={'/taxes'} condition={isDirty}/>
      {toast.opened === true ? <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />: null}
    </>
  );
};

export default TaxAdd;
