import { Link, useNavigate } from 'react-router-dom';
import Box from '../../components/UI/General/Box';
import Text from '../../components/UI/Typography/Text';
import styles from '../../styles/companySetup.module.css';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button.jsx';
import AddButton from '../../components/shared/AddButton';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStore } from '../../store/store.js';
import Dropdown from '../../components/UI/General/Dropdown.jsx';
import Icon from '../../components/UI/General/Icon';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import spmsServiceService from '../../services/spmsService.service.js';
import Toast from '../../components/UI/General/Toast.jsx';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote.jsx';
import Search from '../../components/UI/General/Search.jsx';
import { useForm } from 'react-hook-form';
import { useShallow } from 'zustand/react/shallow';

const Locations = () => {
  const navigate = useNavigate();
  const company = useStore((state) => state.activeCompany);
  const countriesState = useStore(useShallow((state) => state.countries));
  const [triggerRefecth, setTriggerRefetch] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [debouncedValue, setDebouncedValue] = useState(filterText);
  const [isArchivingDisabled, setIsArchivingDisabled] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const {
    control,
    formState: { errors, isDirty, isValid },
    reset,
    watch,
    trigger,
    setValue,
    getValues,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(filterText);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  useEffect(() => {
    const checkArchivingAvailable = async () => {
      const res = await spmsServiceService.searchLocations(company.id, 0, 2, {
        active: true,
      });
      const totalActiveElements = res.data.data.totalElements;
      setIsArchivingDisabled(totalActiveElements <= 1);
    };
    checkArchivingAvailable();
  }, [triggerRefecth]);

  const getData = useCallback(
    async (page, size) => {
      const filterValues = getValues();
      const requestBody = {
        name: debouncedValue,
        country: filterValues.country?.map((country) => country.value).join(''),
      };
      if (!!filterValues?.status?.value) {
        requestBody.active = filterValues.status.value === 'active';
      }
      return await spmsServiceService.searchLocations(company.id, page, size, requestBody);
    },
    [debouncedValue, triggerRefecth, company],
  );

  const archiveLocation = (locationId) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .archiveLocation(company.id, locationId.toLowerCase())
      .then((r) => {
        setToast({
          opened: true,
          message: 'Archived',
          type: 'success',
        });
        setTriggerRefetch((state) => !state);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.message,
          type: 'fail',
        });
      });
  };
  const activateLocation = (locationId) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .activateLocation(company.id, locationId.toLowerCase())
      .then((r) => {
        setToast({
          opened: true,
          message: 'Activated',
          type: 'success',
        });
        setTriggerRefetch((state) => !state);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.message,
          type: 'fail',
        });
      });
  };

  const columns = useMemo(
    () => [
      {
        name: 'Location Name',
        cell: (row) => (
          <span data-content={'Location Name'} className={generalStyles.tableValue}>
            <Link to={'/locations/' + row.id}>
              <span>{row?.name}</span>
            </Link>
          </span>
        ),
      },
      {
        name: 'City',
        cell: (row) => (
          <span data-content={'City'} className={generalStyles.tableValue}>
            {row.city}
          </span>
        ),
      },
      {
        name: 'State/Province',
        cell: (row) => (
          <span data-content={'State/Province'} className={generalStyles.tableValue}>
            {row.state}
          </span>
        ),
      },
      {
        name: 'Country',
        cell: (row) => (
          <span data-content={'Country'} className={generalStyles.tableValue}>
            {row.country}
          </span>
        ),
      },
      {
        name: 'Status',
        cell: (row) => (
          <span data-content={'Status'} className={generalStyles.tableValue}>
            <span>{row.active ? 'Active' : 'Archived'}</span>
          </span>
        ),
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="#4B5865" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  <MenuItem onClick={() => navigate(`/locations/${row.id}`)}>Edit</MenuItem>
                  <>
                    {row.active ? (
                      <>
                        {!isArchivingDisabled && (
                          <MenuItem onClick={() => archiveLocation(row.id)}>Archive</MenuItem>
                        )}
                      </>
                    ) : (
                      <MenuItem onClick={() => activateLocation(row.id)}>Activate</MenuItem>
                    )}
                  </>
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [isArchivingDisabled, triggerRefecth],
  );


  return (
    <Box $noOverflow>
      <div className={styles.title}>
        <Text type="subtitle" weight={500}>
          Locations
        </Text>
      </div>
      <div className={generalStyles.tabSection}>
        <div/>
        <div className={generalStyles.addItemButton}>
          <AddButton $click={() => navigate('/locations/add')} />
        </div>
      </div>
      <DataTableBaseRemote
        columns={columns}
        selectableRows={false}
        paginationResetDefaultPage={false}
        fetchData={getData}
      />
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </Box>
  );
};

export default Locations;
