import React, { useEffect, useState } from 'react';
import Box from '../../UI/General/Box';
import styles from '../../../styles/companySetup.module.css';
import Input from '../../UI/Forms/Input';
import * as Button from '../../UI/Forms/Button.jsx';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Text from '../../UI/Typography/Text';
import generalStyles from '../../../styles/general.module.css';
import Label from '../../UI/Forms/Label';
import File from '../../UI/Forms/File';
import spmsServiceService from '../../../services/spmsService.service';
import Toast from '../../UI/General/Toast';
import { useStore } from '../../../store/store';
import CountryAndRegion from '../../UI/Forms/CountryAndRegion';
import { yupResolver } from '@hookform/resolvers/yup';
import { companyExtendedSchema } from '../../../utils/validation/companySchema.js';
import { useNavigate, useParams } from 'react-router-dom';
import requestsServiceService from '../../../services/requestsService.service';
import { timezones } from '../../../constants/timezones.js';
import { regExps } from '../../../utils/regExps.js';
import Textarea from '../../UI/Forms/Textarea.jsx';
import ModalDiscard from '../../shared/ModalDiscard';

const options = [
  { value: 'Agriculture', label: 'Agriculture' },
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Aerospace', label: 'Aerospace' },
  { value: 'Construction & Real Estate', label: 'Construction & Real Estate' },
  { value: 'Consumer Goods & Retail', label: 'Consumer Goods & Retail' },
  { value: 'Education', label: 'Education' },
  { value: 'Energy', label: 'Energy' },
  { value: 'Entertainment & Media', label: 'Entertainment & Media' },
  { value: 'Financial Services', label: 'Financial Services' },
  { value: 'Government', label: 'Government' },
  { value: 'Healthcare & Pharmaceuticals', label: 'Healthcare & Pharmaceuticals' },
  { value: 'Hospitality, Travel & Tourism', label: 'Hospitality, Travel & Tourism' },
  { value: 'Information Technology', label: 'Information Technology (IT)' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Non Profit & Social Services', label: 'Non Profit & Social Services' },
  { value: 'Professional Services', label: 'Professional Services' },
  { value: 'Transport & Logistics', label: 'Transport & Logistics' },
  { value: 'Telecommunication', label: 'Telecommunication' },
];

const AddNewSubsidiary = () => {
  const company = useStore((state) => state.activeCompany);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const setIsDiscarded = useStore((state) => state.setIsDiscarded);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid, dirtyFields },
    reset,
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(companyExtendedSchema),
    defaultValues: {
      autoGeneratedNumberAffix: company.purchaseOrderProperties?.autoGeneratedNumberAffix ?? 'SUFFIX',
    },
  });
  const countries = useStore((state) => state.countries);
  const currencies = useStore((state) => state.currencies);
  const setCompany = useStore((state) => state.setActiveCompany);
  const [files, setFiles] = useState({ companyLogo: '' });
  const [companyImages, setCompanyImages] = useState(null);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const { id: companyId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!companyId || !countries || !currencies) return;
    const updCurrency = currencies.find((el) => el.value === company.defaultCurrency);
    const updIndustry = options.find((item) => item.value === company.industry);
    const updCountry = countries.find((item) => item.value === company.country);
    const updTimezone = timezones.find((item) => item.label === company.timezone);
    reset({
      name: company.name,
      tradingName: company.tradingName,
      registrationNumber: company.registrationNumber ?? '',
      timezone: updTimezone,
      currency: updCurrency,
      industry: updIndustry,
      taxNumber: company.taxNumber,
      country: updCountry,
      poNumberSequence: company.poNumberSequence,
      requisitionNumberSequence: company.requisitionNumberSequence,
      poTotalDigits: {
        label: company?.purchaseOrderProperties?.poTotalDigits,
        value: company?.purchaseOrderProperties?.poTotalDigits,
      },
      requisitionTotalDigits: {
        label: company?.requisitionProperties?.requisitionTotalDigits,
        value: company?.requisitionProperties?.requisitionTotalDigits,
      },
      poPrefix: company?.purchaseOrderProperties?.poPrefix ?? '',
      requisitionPrefix: company?.requisitionProperties?.requisitionPrefix ?? '',
      poDisclaimer: company?.purchaseOrderProperties?.poDisclaimer ?? '',
    });
  }, [countries, companyId, currencies]);

  const customOnChange = (e) => {
    setValue('currency', { label: `${e.currencyName} (${e.currency})`, value: e.currency });
  };

  useEffect(() => {
    const getCompanyImages = async () => {
      if (!company) return;
      let companyLogo;
      if (company.logo) {
        const { logo } = company;
        const res = await spmsServiceService.getAttachmentsByKey(logo.key);
        companyLogo = res.data.data;
      }
      setCompanyImages({ companyLogo });
    };
    getCompanyImages();
  }, [company]);

  async function submitSubsidiary(data) {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    let {
      industry,
      country,
      currency,
      timezone,
      poTotalDigits,
      requisitionTotalDigits,
      ...prevData
    } = data;
    let finalData = {
      ...prevData,
      industry: industry.value,
      country: country.value,
      subsidiary: true,
      defaultCurrency: currency.value,
      timezone: timezone.label,
      companyLogo: files.companyLogo !== '' ? files.companyLogo : undefined,
      requisitionsRequired: true,
      poNumberSequence: data.poNumberSequence,
      requisitionNumberSequence: data.requisitionNumberSequence,
      id: company.id,
      purchaseOrderProperties: {
        poDisclaimer: data.poDisclaimer,
        poNumberFormat: 'PO',
        autoGeneratedNumberAffix: 'SUFFIX',
        addCurrentDate: false,
        poPrefix: data.poPrefix,
        poTotalDigits: poTotalDigits.value,
      },
      requisitionProperties: {
        requisitionNumberFormat: 'RQ',
        requisitionPrefix: data.requisitionPrefix,
        requisitionTotalDigits: requisitionTotalDigits.value,
      },
    };
    spmsServiceService
      .updateCompany(finalData)
      .then((res) => {
        setIsDiscarded(false)
        setToast({
          opened: true,
          message: 'Success!',
          type: 'success',
          cb: () => navigate(`/company-profile`),
        });
        setCompany(company.id);
        reset(data);
      })
      .catch((error) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: error?.response?.data?.message?.toString(),
          type: 'fail',
        });
      });
  }
  const readFile = (event) => {
    let filereader = new FileReader();
    if (!event.target.files.length) return;
    const fileType = event.target.files[0].name.split('.').at(-1);
    filereader.readAsDataURL(event.target.files[0]);

    filereader.onload = function () {
      setFiles({
        ...files,
        [event.target.name]: {
          document: filereader.result,
          name: `${event.target.name}.${fileType}`,
        },
      });
    };
    filereader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  return (
    <div>
      <Box $mobExtend $asHolder $noOverflow>
        {/* <div className={styles.title}>
          <Text weight={500} type="subtitle">
            Add Company
          </Text>
        </div> */}
        <form onSubmit={handleSubmit(submitSubsidiary)} className={styles.container} id="cForm">
          <div className={styles.companySection}>
            <div className={generalStyles.fieldsThree}>
              <div className="inp-container">
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      type="text"
                      $labelRequired
                      placeholder="Enter Legal Name"
                      className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                      $label="Legal Name"
                      id={field.name}
                      {...field}
                    />
                  )}
                />
                {errors.name && <p className="error-message">{errors.name?.message}</p>}
              </div>
              <div className="inp-container">
                <Controller
                  name="tradingName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      type="text"
                      placeholder="Enter Trading Name"
                      className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                      $label="Trading Name"
                      id={field.name}
                      {...field}
                    />
                  )}
                />
                {errors.tradingName && (
                  <p className="error-message">{errors.tradingName?.message}</p>
                )}
              </div>
              <div className="inp-container">
                <Controller
                  name="registrationNumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      type="text"
                      placeholder="Enter Registration Number"
                      className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                      $label="Registration Number"
                      id={field.name}
                      {...field}
                    />
                  )}
                />
                {errors.registrationNumber && (
                  <p className="error-message">{errors.registrationNumber?.message}</p>
                )}
              </div>
              <div className="inp-container">
                <Controller
                  name="taxNumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      type="text"
                      placeholder="Enter Tax Number"
                      className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                      $label="Tax Number"
                      id={field.name}
                      {...field}
                    />
                  )}
                />
                {errors.taxNumber && <p className="error-message">{errors.taxNumber?.message}</p>}
              </div>
              <CountryAndRegion
                countryFieldName="country"
                control={control}
                isDisable={false}
                countries={countries}
                errors={errors}
                isLabel
                customOnChange={customOnChange}
              />
              <div className="inp-container">
                <Label $isRequired $title="Industry" />
                <Controller
                  name="industry"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                      classNamePrefix="react-select"
                      isSearchable={true}
                      placeholder="Select Industry"
                      options={options}
                    />
                  )}
                />
                {errors.industry && (
                  <p className="error-message">{errors.industry?.value.message}</p>
                )}
              </div>
              <div className="inp-container">
                <Label $title="Default Currency" />
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                      classNamePrefix="react-select"
                      isSearchable={true}
                      placeholder="Select Currency"
                      options={currencies}
                    />
                  )}
                />
                {errors.currency && (
                  <p className="error-message">{errors.currency?.value.message}</p>
                )}
              </div>
              <div className="inp-container">
                <Label $isRequired $title="Timezone" />
                <Controller
                  name="timezone"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      className={
                        errors.hasOwnProperty(field.name)
                          ? 'react-select-container error'
                          : 'react-select-container'
                      }
                      classNamePrefix="react-select"
                      isSearchable={false}
                      placeholder="Select Timezone"
                      options={timezones.map((timezone) => ({
                        label: timezone.label,
                        value: timezone.value,
                      }))}
                    />
                  )}
                />
                {errors.timezone && (
                  <p className="error-message">{errors.timezone?.value.message}</p>
                )}
              </div>
              <div className="inp-container">
                <Label $title="Company Logo" />
                <Controller
                  name="companyLogo"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <File
                      $isInvalid={errors.hasOwnProperty(field.name)}
                      {...field}
                      value={field.value.filename}
                      $picture={
                        field.value !== '' && field.value instanceof Blob
                          ? URL.createObjectURL(field.value)
                          : field.value !== '' && !(field.value instanceof Blob)
                          ? 'data:image/jpg;base64,' + field.value
                          : companyImages?.companyLogo
                          ? companyImages?.companyLogo
                          : ''
                      }
                      onChange={(e) => {
                        field.onChange({
                          target: { value: e.target.files[0], name: field.name },
                        });
                        readFile(e);
                      }}
                      accept=".jpg, .jpeg, .png, .gif, .ico, .svg, .bmp, .pdf"
                    />
                  )}
                />
                {errors.companyLogo && (
                  <p className="error-message">{errors.companyLogo?.message}</p>
                )}
              </div>
            </div>
          </div>
          <div className={generalStyles.fieldsThree}>
            <div className={styles.poFormat}>
              <Label $title="Next RQ Serial Number" />
              <div className={styles.poFormatSections}>
                <div className={styles.poFormatNumber}>
                  {/* <Text type={'body-1'}>RQ-</Text> */}
                  <div className="inp-container">
                    <Controller
                      name="requisitionPrefix"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: {
                          message: "Can't save with empty value",
                          value: true,
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="VS"
                          className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                          id={field.name}
                          {...field}
                        />
                      )}
                    />
                    {errors.requisitionPrefix && (
                      <p className="error-message">{errors.requisitionPrefix?.message}</p>
                    )}
                  </div>
                  <Text type={'body-1'}>-</Text>
                  <div className="inp-container">
                    <Controller
                      name="requisitionNumberSequence"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: {
                          message: "Can't save with empty value",
                          value: true,
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="text"
                          placeholder="1"
                          className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                          id={field.name}
                        />
                      )}
                    />
                    {errors.requisitionNumberSequence && (
                      <p className="error-message">{errors.requisitionNumberSequence?.message}</p>
                    )}
                  </div>
                </div>
                <div className="inp-container">
                  <Label $title="Number of digits (for padding zeros)*" />
                  <Controller
                    name="requisitionTotalDigits"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={
                          errors.hasOwnProperty(field.name)
                            ? 'react-select-container error'
                            : 'react-select-container'
                        }
                        classNamePrefix="react-select"
                        isSearchable={false}
                        placeholder="4"
                        options={Array.from({ length: 10 }, (_, i) => i + 1).map((digit) => ({
                          label: digit,
                          value: digit,
                        }))}
                      />
                    )}
                  />
                  {errors.requisitionTotalDigits && (
                    <p className="error-message">{errors.requisitionTotalDigits?.value.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.poFormat}>
              <Label $title="Next PO Serial Number" />
              <div className={styles.poFormatSections}>
                <div className={styles.poFormatNumber}>
                  {/* <Text type={'body-1'}>PO-</Text> */}
                  <div className="inp-container">
                    <Controller
                      name="poPrefix"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: {
                          message: "Can't save with empty value",
                          value: true,
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="VS"
                          className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                          id={field.name}
                          {...field}
                        />
                      )}
                    />
                    {errors.poPrefix && <p className="error-message">{errors.poPrefix?.message}</p>}
                  </div>
                  <Text type={'body-1'}>-</Text>
                  <div className="inp-container">
                    <Controller
                      name="poNumberSequence"
                      defaultValue=""
                      control={control}
                      rules={{
                        required: {
                          message: "Can't save with empty value",
                          value: true,
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="text"
                          placeholder="1"
                          className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                          id={field.name}
                        />
                      )}
                    />
                    {errors.poNumberSequence && (
                      <p className="error-message">{errors.poNumberSequence?.message}</p>
                    )}
                  </div>
                </div>
                <div className="inp-container">
                  <Label $title="Number of digits (for padding zeros)*" />
                  <Controller
                    name="poTotalDigits"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={
                          errors.hasOwnProperty(field.name)
                            ? 'react-select-container error'
                            : 'react-select-container'
                        }
                        classNamePrefix="react-select"
                        isSearchable={false}
                        placeholder="4"
                        options={Array.from({ length: 10 }, (_, i) => i + 1).map((digit) => ({
                          label: digit,
                          value: digit,
                        }))}
                      />
                    )}
                  />
                  {errors.poTotalDigits && (
                    <p className="error-message">{errors.poTotalDigits?.value.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="inp-container">
              <Controller
                name="poDisclaimer"
                control={control}
                rules={{
                  pattern: {
                    value: regExps.notes,
                    message: "Only alpha characters, numbers and - ! . , & : ( ) % + = / @ ' $ € £",
                  },
                }}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    $low
                    $counter
                    $counterMax={500}
                    $label="PO Disclaimer"
                    placeholder="PO Disclaimer text goes here"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                  />
                )}
              />
              {errors.poDisclaimer && (
                <p className="error-message">{errors.poDisclaimer?.message}</p>
              )}
            </div>
          </div>
          <div className={generalStyles.pageButtons}>
            <Button.Main
              $primary
              $style={'blue'}
              type="submit"
              form="cForm"
              disabled={!isDirty || !isValid || isButtonsBlocked}
            >
              Save
            </Button.Main>
            <Button.Main
              $primary
              onClick={() => isDirty && !!Object.keys(dirtyFields).length ? setShowModal(true) : navigate('/company-profile')}
              type="button"
              $style={'gray'}
            >
              Discard
            </Button.Main>
          </div>
        </form>
      </Box>
      <ModalDiscard setShowModal={setShowModal} showModal={showModal} navigateTo={'/company-profile'} condition={isDirty && !!Object.keys(dirtyFields).length}/>
      {toast.opened ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </div>
  );
};

export default AddNewSubsidiary;
