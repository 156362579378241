import React, { useEffect, useState } from 'react';
import integrationsService from '../../services/integrations.service';
import moment from 'moment';
import spmsServiceService from '../../services/spmsService.service';
import style from '../../styles/integrations.module.css';
import Text from '../../components/UI/Typography/Text';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend';
import generalStyles from '../../styles/general.module.css';
import { Link } from 'react-router-dom';

const SettingsIntegrations = () => {
  const [integrations, setIntegrations] = useState({})

  useEffect(() => {
    integrationsService.getSystemIntegrations().then(async (r) => {
      if (r.data.message === "Operation Successful"){
        const withImage = await Promise.all(
          r.data.data.map(async (el) => {
            const image = el?.attachments?.reduce((latest, current) => moment(current.dateUploaded).isAfter(moment(latest.dateUploaded)) ? current : latest, el?.attachments[0])
            if (!!image?.key && !image?.key.includes('null')){
              const img = await Promise.all([
                await spmsServiceService.getAttachmentsByKey(image?.key).then(r => r.data.message === "Operation Successful" ? r.data.data : null)
              ])
              return {...el, attachments: img[0]}
            }else {
              return {...el, attachments: null}
            }
          })
        )
        const sorted = await withImage.reduce((acc, obj) => {
          const value = obj['productType']
          if (!acc[value]){
            acc[value] = []
          }
          acc[value].push(obj)
          return acc
        }, {})
        setIntegrations(sorted)
      }
    }).catch(err => {
      console.log(err);
    })
  },[])

  return (
    <div>
      <div className={style.titleBlock}>
        <Text weight={500} type={'h3'}>Integrations</Text>
        <Text type={'body-1'} className={style.subtitle}>SpendPro seamlessly integrates the below enterprise Accounting and Enterprise Resource Planning (ERP) solutions</Text>
      </div>

      {Object.keys(integrations).map(key => (
        <div key={key} className={style.section}>
          <Text weight={500} type={'h4'}>{readableTitleFromBackend(key)}</Text>
          <div className={generalStyles.fieldsThree}>
            {integrations[key].map((item, index) => (
              <div className={item.status === 'ACTIVE' ? style.itemBox + ' ' + style.itemBoxActive : style.itemBox +' '+ style.itemBoxInActive} key={index}>
                <img className={style.itemLogo} src={!!item.attachments ? item.attachments : '/assets/img/integrations/integrations.png'} alt={item.productName}/>
                <Text type={'body-1'}>{item.productName}</Text>
                <Link to={'/settings/integrations/edit/'+item.id} className={'link'}>Edit</Link>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SettingsIntegrations;
