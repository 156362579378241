import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Box from '../../components/UI/General/Box';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button';
import Text from '../../components/UI/Typography/Text';
import Label from '../../components/UI/Forms/Label';
import Select from 'react-select';
import Input from '../../components/UI/Forms/Input';
import Toast from '../../components/UI/General/Toast';
import File from '../../components/UI/Forms/File';
import integrationsService from '../../services/integrations.service';
import spmsServiceService from '../../services/spmsService.service';
import moment from 'moment';

const productTypes = [
  {
    label: 'Financial',
    value: 'Financial'
  },
  {
    label: 'Collaboration',
    value: 'Collaboration'
  },
  {
    label: 'Other',
    value: 'Other'
  },
]

const statuses = [
  {label: 'Active', value: 'Active'},
  {label: 'Inactive', value: 'Inactive'},
]

const CreateNewSystemIntegration = () => {
  const { integrationId } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState(null);
  const [files, setFiles] = useState(null);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      type: '',
      name: '',
      description: '',
      status: '',
      image: '',
    },
    values,
  });

  const readFile = (event) => {
    if (event.target.files[0] !== undefined) {
      let filereader = new FileReader();
      filereader.readAsDataURL(event.target.files[0]);
      filereader.onload = function () {
        setFiles({document: filereader.result.split(';base64,')[1], name: event.target.files[0].name});
      };
      filereader.onerror = function (error) {
        console.log('Error: ', error);
      };
    } else {
      setFiles(null);
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    const { type, name, description, status } = data;
    const newData = {
      productType: type.value.toUpperCase(),
      productName: name,
      description: description,
      status: status.value.toUpperCase(),
      attachments: files !== null ? files : {},
    };
    console.log('newData', newData);
    if (!!integrationId){
      integrationsService.editSystemIntegration(integrationId, newData).then(r => {
        console.log(r);
        setToast({
          opened: true,
          message: 'Integration updated successfully',
          type: 'success',
          cb: () => navigate('/settings/integrations')
        });
      }).catch(err => {
        console.log(err);
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: err.response.data.message !== undefined ? err.response.data.message : err.response.data.error,
          type: 'fail',
        });
      })
    }else {
      integrationsService.createSystemIntegration(newData).then(r => {
        console.log(r);
        setToast({
          opened: true,
          message: 'Integration created successfully',
          type: 'success',
          cb: () => navigate('/settings/integrations')
        });
      }).catch(err => {
        console.log(err);
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: err.response.data.message !== undefined ? err.response.data.message : err.response.data.error,
          type: 'fail',
        });
      })
    }
  }

  useEffect(() => {
    if (!!integrationId){
      integrationsService.getSingleSystemIntegration(integrationId).then(async r => {
        if (r.data.message === "Operation Successful"){
          console.log(r.data.data);
          const {productType,productName,description,status, attachments} = r.data.data;
          const image = attachments?.reduce((latest, current) => moment(current.dateUploaded).isAfter(moment(latest.dateUploaded)) ? current : latest, attachments[0])
          let img = '';
          if (!!image?.key && !image?.key.includes('null')){
            img = await Promise.all([
              await spmsServiceService.getAttachmentsByKey(image?.key).then(r => fetch(r.data.data).then(response => response.blob()))
            ])
          }
          console.log(img[0]);
          setValues({
            type: productTypes.find(el => el.value.toUpperCase() === productType.toUpperCase()),
            name: productName,
            description: description,
            status: statuses.find(el => el.value.toUpperCase() === status.toUpperCase()),
            image: !!img ? img[0] : img,
          })
        }
      })
    }
  }, [integrationId])

  return (
    <>
      <Box $mobExtend $asHolder>
        <div className={generalStyles.top}>
          <div className={generalStyles.title}>
            <Text type="subtitle" weight={500}>{!!integrationId ? 'Edit' : 'Create New'} System Integration Listing</Text>
            <div className={generalStyles.addItemButton}>
              <Button.Main
                $mid
                $style="blue"
                type="submit"
                onClick={handleSubmit((data) => onSubmit(data))}
                disabled={!isDirty || !isValid || isButtonsBlocked}
              >
                Submit
              </Button.Main>
              <Button.Main $mid $style="gray" type="button" onClick={()=> navigate('/settings/integrations')}>
                Discard
              </Button.Main>
            </div>
          </div>
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Label $title="Product Type" $isRequired />
              <Controller
                name="type"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Product Type is required',
                  },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Product Type"
                    menuPortalTarget={document.body}
                    menuPosition={'absolute'}
                    menuPlacement={'bottom'}
                    menuShouldScrollIntoView={false}
                    options={productTypes}
                  />
                )}
              />
              {errors.type && <p className="error-message">{errors.type.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Product Name is Required',
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Product Name"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Product Name"
                    $labelRequired
                    {...field}
                  />
                )}
              />
              {errors.name && <p className="error-message">{errors.name.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="description"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Description is Required',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Maximum 50 characters',
                  },
                  validate: {
                    allowed: (v) => /^[-&()0-9A-Za-z\s]*$/.test(v) || 'Alphabetic, numeric, &()- characters and space only',
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Description"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Description"
                    $labelRequired
                    {...field}
                  />
                )}
              />
              {errors.description && <p className="error-message">{errors.description.message}</p>}
            </div>
            <div className="inp-container">
              <Label $title="Status" $isRequired />
              <Controller
                name="status"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Status is required',
                  },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={errors.hasOwnProperty(field.name) ? 'react-select-container error' : 'react-select-container'}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Status"
                    menuPortalTarget={document.body}
                    menuPosition={'absolute'}
                    menuPlacement={'bottom'}
                    menuShouldScrollIntoView={false}
                    options={statuses}
                  />
                )}
              />
              {errors.status && <p className="error-message">{errors.status.message}</p>}
            </div>
            <div className="inp-container">
              <Label $title="Image" />
              <Controller
                name="image"
                control={control}
                rules={{
                  validate: {
                    isValid: (value) => {
                      if (value?.name) {
                        return (['jpg', 'jpeg', 'png', 'gif', 'svg', 'bmp'].includes(value.name.split('.').pop(),) || 'Wrong file type');
                      }
                    },
                  },
                }}
                render={({ field }) => (
                  <File
                    {...field}
                    value={field?.value?.filename}
                    $isInvalid={errors.hasOwnProperty(field.name)}
                    $picture={field.value !== '' && field.value instanceof Blob ? URL.createObjectURL(field.value) : field.value !== '' && !(field.value instanceof Blob) ? 'data:image/jpg;base64,' + field.value : ''}
                    $small
                    onChange={(e) => {
                      field.onChange({ target: { value: e.target.files[0], name: field.name } });
                      readFile(e);
                    }}
                    accept="image/png, image/jpeg"
                  />
                )}
              />
              {errors.image && <p className="error-message">{errors.image.message}</p>}
            </div>
          </div>
        </div>
      </Box>
      {toast.opened === true ? <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />: null}
    </>
  );
};

export default CreateNewSystemIntegration;
