import styles from '../../styles/inventory.module.css';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Text from '../../components/UI/Typography/Text';
import Input from '../../components/UI/Forms/Input';
import * as Button from '../../components/UI/Forms/Button';
import Box from '../../components/UI/General/Box';
import spmsServiceService from '../../services/spmsService.service';
import 'react-datepicker/dist/react-datepicker.css';
import generalStyles from '../../styles/general.module.css';
import { useStore } from '../../store/store';
import Toast from '../../components/UI/General/Toast';
import ModalDiscard from '../../components/shared/ModalDiscard';
import BackButton from '../../components/shared/BackButton';
import Select from 'react-select';
import File from '../../components/UI/Forms/File';
import SelectVendors from '../../components/shared/SelectVendors';
import * as yup from 'yup';
import { regExps } from '../../utils/regExps';
const unitOptions = [
  { label: 'Inch', value: 'INCH' },
  { label: 'Box', value: 'BOX' },
  { label: 'Piece', value: 'PIECE' },
  { label: 'Metre', value: 'METRE' },
  { label: 'Gram', value: 'GRAM' },
  { label: 'Dozen', value: 'DOZEN' },
  { label: 'Pack', value: 'PACK' },
  { label: 'Yard', value: 'YARD' },
  { label: 'Kilogram', value: 'KG' },
  { label: 'Millilitre', value: 'MILLILITRE' },
  { label: 'Litre', value: 'LITRE' },
  { label: 'Centimere', value: 'CENTIMETRE' },
  { label: 'Pair', value: 'PAIR' },
  { label: 'Roll', value: 'ROLL' },
];

const Details = ({ errors, control, setValue, vendorCategories, readFile }) => {
  const categoryWatcher = useWatch({ control, name: 'category' });
  const { itemId } = useParams();

  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const getImage = async () => {
      if (!itemId) return;
      let image;
      // if (company.logo) {
      //   const { logo } = company;
      //   const res = await spmsServiceService.getAttachmentsByKey(logo.key);
      //   companyLogo = res.data.data;
      // }
      // setImageUrl({ companyLogo });
    };
    getImage();
  }, [itemId]);

  return (
    <>
      <div className="inp-container">
        <Controller
          name="code"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'SKU is required',
            },
            maxLength: {
              value: 25,
              message: 'Must be at most 25 characters',
            },
            pattern: {
              value: regExps.alphaNumerical,
              message: 'Only alpha characters and numbers',
            },
          }}
          render={({ field }) => (
            <Input
              type="text"
              placeholder="Product ID/SKU*"
              className={errors.hasOwnProperty(field.name) && 'error'}
              {...field}
            />
          )}
        />
        {errors.code && <p className="error-message">{errors.code?.message}</p>}
      </div>
      <div className="inp-container">
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'Name is required',
            },
            maxLength: {
              value: 25,
              message: 'Must be at most 25 characters',
            },
            pattern: {
              value: regExps.alphaNumericalSpecial,
              message: "Only alpha characters, numbers and - . , & : ( ) + / # '",
            },
          }}
          render={({ field }) => (
            <Input
              type="text"
              placeholder="Name *"
              className={errors.hasOwnProperty(field.name) && 'error'}
              {...field}
            />
          )}
        />
        {errors.name && <p className="error-message">{errors.name?.message}</p>}
      </div>
      <div className="inp-container">
        <Controller
          name="description"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: true,
              message: 'Description is required',
            },
            maxLength: {
              value: 25,
              message: 'Must be at most 25 characters',
            },
            pattern: {
              value: regExps.alphaNumericalSpecial,
              message: "Only alpha characters, numbers and - . , & : ( ) + / # '",
            },
          }}
          render={({ field }) => (
            <Input
              type="text"
              placeholder="Description *"
              className={errors.hasOwnProperty(field.name) && 'error'}
              {...field}
            />
          )}
        />
        {errors.description && <p className="error-message">{errors.description?.message}</p>}
      </div>
      <div className="inp-container">
        <Controller
          name="unitOfMeasure"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Unit of Measure is required',
            },
          }}
          render={({ field }) => (
            <Select
              {...field}
              className={
                errors.hasOwnProperty(field.name)
                  ? 'react-select-container error'
                  : 'react-select-container'
              }
              classNamePrefix="react-select"
              isSearchable
              placeholder="Unit of Measure *"
              options={unitOptions}
            />
          )}
        />
        {errors.unitOfMeasure && <p className="error-message">{errors.unitOfMeasure?.message}</p>}
      </div>
      <div className="inp-container">
        <Controller
          name="category"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Category is required',
            },
          }}
          render={({ field }) => (
            <Select
              {...field}
              className={
                errors.hasOwnProperty(field.name)
                  ? 'react-select-container error'
                  : 'react-select-container'
              }
              classNamePrefix="react-select"
              isSearchable={false}
              placeholder="Select Category"
              options={Array.from(new Set(vendorCategories.map((cat) => cat.category))).map(
                (category) => ({
                  label: category,
                  value: category,
                }),
              )}
              onChange={(e) => {
                field.onChange(e);
                setValue('subCategory', null);
              }}
            />
          )}
        />
        {errors.category && <p className="error-message">{errors.category?.message}</p>}
      </div>
      <div className="inp-container">
        <Controller
          name="subCategory"
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Sub Category is required',
            },
          }}
          render={({ field }) => (
            <Select
              {...field}
              className={
                errors.hasOwnProperty(field.name)
                  ? 'react-select-container error'
                  : 'react-select-container'
              }
              classNamePrefix="react-select"
              isDisabled={!categoryWatcher?.value}
              isSearchable={false}
              placeholder="Select Sub Category"
              options={vendorCategories
                .filter((category) => category.category === categoryWatcher?.value)
                .map((subcat) => ({
                  label: subcat.subCategory,
                  value: subcat.subCategory,
                }))}
            />
          )}
        />
        {errors.subCategory && <p className="error-message">{errors?.subCategory.message}</p>}
      </div>
      <div className="inp-container">
        <Controller
          name="imageUrl"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <File
              $isInvalid={errors.hasOwnProperty(field.name)}
              {...field}
              value={field.value.filename}
              $picture={
                field.value !== '' && field.value instanceof Blob
                  ? URL.createObjectURL(field.value)
                  : field.value !== '' && !(field.value instanceof Blob)
                  ? 'data:image/jpg;base64,' + field.value
                  : imageUrl?.image
                  ? imageUrl?.image
                  : ''
              }
              onChange={(e) => {
                field.onChange({
                  target: { value: e.target.files[0], name: field.name },
                });
                readFile(e);
              }}
              accept=".jpg, .jpeg, .png, .gif, .ico, .svg"
            />
          )}
        />
        {errors.imageUrl && <p className="error-message">{errors.imageUrl?.message}</p>}
      </div>
    </>
  );
};

const Config = ({ errors, control }) => {
  return (
    <>
      <div className={generalStyles.fieldsTwo}>
        <div className="inp-container">
          <Controller
            name="minStockLevel"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: false,
              },
              maxLength: {
                value: 10,
                message: 'Must be at most 10 characters',
              },
              pattern: {
                value: regExps.numbers,
                message: 'Only numbers allowed',
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Min Stock Level"
                className={errors.hasOwnProperty(field.name) && 'error'}
                {...field}
              />
            )}
          />
          {errors.minStockLevel && <p className="error-message">{errors.minStockLevel?.message}</p>}
        </div>
        <div className="inp-container">
          <Controller
            name="maxStockLevel"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: false,
              },
              maxLength: {
                value: 10,
                message: 'Must be at most 10 characters',
              },
              pattern: {
                value: regExps.numbers,
                message: 'Only numbers allowed',
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Max Stock level"
                className={errors.hasOwnProperty(field.name) && 'error'}
                {...field}
              />
            )}
          />
          {errors.maxStockLevel && <p className="error-message">{errors.maxStockLevel?.message}</p>}
        </div>
      </div>
      <div className={generalStyles.fieldsTwo}>
        <div className="inp-container">
          <Controller
            name="reorderPoint"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: false,
              },
              maxLength: {
                value: 10,
                message: 'Must be at most 10 characters',
              },
              pattern: {
                value: regExps.numbers,
                message: 'Only numbers allowed',
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Reorder Point"
                className={errors.hasOwnProperty(field.name) && 'error'}
                {...field}
              />
            )}
          />
          {errors.reorderPoint && <p className="error-message">{errors.reorderPoint?.message}</p>}
        </div>
        <div className="inp-container">
          <Controller
            name="reorderQuantity"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: false,
              },
              maxLength: {
                value: 10,
                message: 'Must be at most 10 characters',
              },
              pattern: {
                value: regExps.numbers,
                message: 'Only numbers allowed',
              },
            }}
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Reorder Quantity"
                className={errors.hasOwnProperty(field.name) && 'error'}
                {...field}
              />
            )}
          />
          {errors.reorderQuantity && (
            <p className="error-message">{errors.reorderQuantity?.message}</p>
          )}
        </div>
      </div>
      <div className="inp-container">
        <Controller
          name="preferredVendorId"
          control={control}
          render={({ field: { ref, ...rest } }) => (
            <SelectVendors
              {...rest}
              selectRef={ref}
              className={
                errors.hasOwnProperty(rest.name)
                  ? 'react-select-container error'
                  : 'react-select-container'
              }
            />
          )}
        />
        {errors.preferredVendorId && (
          <p className="error-message">{errors.preferredVendorId?.message}</p>
        )}
      </div>
      <div className="inp-container">
        <Controller
          name="manufacturer"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: false,
            },
            maxLength: {
              value: 50,
              message: 'Must be at most 50 characters',
            },
            pattern: {
              value: regExps.alphaNumericalSpecial,
              message: "Only alpha characters, numbers and - . , & : ( ) + / # '",
            },
          }}
          render={({ field }) => (
            <Input
              type="text"
              placeholder="Brand/Manufacturer"
              className={errors.hasOwnProperty(field.name) && 'error'}
              {...field}
            />
          )}
        />
        {errors.manufacturer && <p className="error-message">{errors.manufacturer?.message}</p>}
      </div>
      <div className="inp-container">
        <Controller
          name="modelNumber"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: false,
            },
            maxLength: {
              value: 50,
              message: 'Must be at most 50 characters',
            },
            pattern: {
              value: regExps.alphaNumericalSpecial,
              message: "Only alpha characters, numbers and - . , & : ( ) + / # '",
            },
          }}
          render={({ field }) => (
            <Input
              type="text"
              placeholder="Model number"
              className={errors.hasOwnProperty(field.name) && 'error'}
              {...field}
            />
          )}
        />
        {errors.modelNumber && <p className="error-message">{errors.modelNumber?.message}</p>}
      </div>
      <div className="inp-container">
        <Controller
          name="barcode"
          control={control}
          defaultValue=""
          rules={{
            required: {
              value: false,
            },
            maxLength: {
              value: 50,
              message: 'Must be at most 50 characters',
            },
            pattern: {
              value: regExps.alphaNumericalSpecial,
              message: "Only alpha characters, numbers and - . , & : ( ) + / # '",
            },
          }}
          render={({ field }) => (
            <Input
              type="text"
              placeholder="Bar Code/QR Code"
              className={errors.hasOwnProperty(field.name) && 'error'}
              {...field}
            />
          )}
        />
        {errors.barcode && <p className="error-message">{errors.barcode?.message}</p>}
      </div>
    </>
  );
};

const AddInventoryItem = () => {
  const { itemId } = useParams();
  const { id } = useStore((state) => state.activeCompany);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const setIsDiscarded = useStore((state) => state.setIsDiscarded);
  const [files, setFiles] = useState({ imageUrl: '' });
  const [vendorCategories, setVendorCategories] = useState([]);
  const [step, setStep] = useState('details');
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors, isDirty, dirtyFields },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      description: '',
      code: '',
      unitOfMeasure: undefined,
      imageUrl: '',
      category: undefined,
      subCategory: undefined,
      configuration: {
        minStockLevel: '',
        maxStockLevel: '',
        reorderPoint: '',
        reorderQuantity: '',
        preferredVendorId: undefined,
        // integratedSystemId: 'string',
        modelNumber: '',
        barcode: '',
        manufacturer: '',
      },
    },
  });
  const navigate = useNavigate();
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const nextStep = async () => {
    const isFirstStepDone = await trigger([
      'name',
      'description',
      'unitOfMeasure',
      'category',
      'subCategory',
      'code',
    ]);
    console.log('isFirstStepDone', isFirstStepDone);
    if (('isFirstStepDone', isFirstStepDone)) setStep('config');
  };

  useEffect(() => {
    if (!itemId || !vendorCategories?.length) return;
    spmsServiceService.getInventoryItem(id, itemId).then((res) => {
      const result = res.data.data;
      console.log('result', result);
      const unit = unitOptions.find((option) => option.value === result.unitOfMeasure);
      const itemData = {
        name: result.name,
        code: result.code,
        description: result.description,
        unitOfMeasure: unit,
        category: { label: result.category, value: result.category },
        subCategory: { label: result.subCategory, value: result.subCategory },
        minStockLevel: result.configuration.minStockLevel,
        maxStockLevel: result.configuration.maxStockLevel,
        reorderPoint: result.configuration.reorderPoint,
        reorderQuantity: result.configuration.reorderQuantity,
        barcode: result.configuration.barcode,
        modelNumber: result.configuration.modelNumber,
        manufacturer: result.configuration.manufacturer,

        // preferredVendorId: 'string',
      };
      reset(itemData);
    });
  }, [itemId, vendorCategories]);

  useEffect(() => {
    spmsServiceService
      .getVendorCategories()
      .then((res) => {
        const categories = res.data.data;
        if (!res?.data?.data?.length) {
          setVendorCategories([]);
          return;
        }
        setVendorCategories(categories);
      })
      .catch((err) => {
        setVendorCategories([]);
      });
  }, []);
  console.log(files);

  const onSubmit = (data) => {
    // setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    console.log(data);

    const requestBody = {
      name: data.name,
      description: data.description,
      code: data.code,
      unitOfMeasure: data.unitOfMeasure.value,
      category: data.category.value,
      subCategory: data.subCategory.value,
      imageUrl: files.imageUrl !== '' ? files.imageUrl : undefined,
      availableStock: 0,
      configuration: {
        minStockLevel: data.minStockLevel,
        maxStockLevel: data.maxStockLevel,
        reorderPoint: data.reorderPoint,
        reorderQuantity: data.reorderQuantity,
        preferredVendorId: data.preferredVendorId?.value,
        modelNumber: data.modelNumber,
        barcode: data.barcode,
        manufacturer: data.manufacturer,
      },
    };
    console.log('requestBody', requestBody);

    return;
    if (itemId) {
      spmsServiceService
        .editInventoryItem(id, itemId, requestBody)
        .then((res) => {
          setIsDiscarded(false);
          setToast({
            opened: true,
            message: 'Item updated successfully',
            type: 'success',
            cb: () => navigate('/inventory'),
          });
        })
        .catch((reason) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message:
              reason.response.data.errors?.[0].message === undefined
                ? reason.response.data.message
                : reason.response.data.errors?.[0].message,
            type: 'fail',
          });
        });
    } else {
      spmsServiceService
        .createInventoryItem(id, requestBody)
        .then((res) => {
          setIsDiscarded(false);
          setToast({
            opened: true,
            message: 'Item created successfully',
            type: 'success',
            cb: () => navigate('/inventory'),
          });
        })
        .catch((reason) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message:
              reason.response.data.errors[0].message === undefined
                ? reason.response.data.message
                : reason.response.data.errors[0].message,
            type: 'fail',
          });
        });
    }
  };

  const readFile = (event) => {
    let filereader = new FileReader();
    if (!event.target.files.length) return;
    const fileType = event.target.files[0].name.split('.').at(-1);
    filereader.readAsDataURL(event.target.files[0]);

    filereader.onload = function () {
      setFiles({
        ...files,
        [event.target.name]: {
          document: filereader.result,
          name: `${event.target.name}.${fileType}`,
        },
      });
    };
    filereader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  return (
    <Box $mobExtend $asHolder $noOverflow>
      <div className={generalStyles.top}>
        <div>
          <BackButton />
        </div>
        <div className={`${generalStyles.title} ${generalStyles.underline}`}>
          <Text type="subtitle" weight={500}>
            {!!itemId ? 'Edit' : 'Create'} Inventory Item
          </Text>
          <div className={generalStyles.addItemButton}>
            <Button.Main
              $mid
              $style="blue"
              type="submit"
              form="reqForm"
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty || isButtonsBlocked}
            >
              Save
            </Button.Main>
            <Button.Main
              $mid
              $style="gray"
              type="button"
              onClick={() =>
                isDirty && !!Object.keys(dirtyFields).length ? setShowModal(true) : navigate(-1)
              }
            >
              Discard
            </Button.Main>
          </div>
        </div>
      </div>
      <div className={styles.inventoryForm}>
        <div className={styles.inputsContainer}>
          <form className={styles.inputs}>
            {step === 'details' ? (
              <Details
                errors={errors}
                control={control}
                setValue={setValue}
                vendorCategories={vendorCategories}
                readFile={readFile}
              />
            ) : (
              <Config errors={errors} control={control} />
            )}
          </form>
          <div className={styles.buttonContainer}>
            <div>
              {step !== 'details' && (
                <Button.Main $mid $style="gray" type="button" onClick={() => setStep('details')}>
                  Back
                </Button.Main>
              )}
            </div>
            <div>
              {step !== 'config' && (
                <Button.Main $mid $style="blue" type="button" onClick={nextStep}>
                  Next
                </Button.Main>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalDiscard
        setShowModal={setShowModal}
        showModal={showModal}
        navigateTo={'/inventory'}
        condition={isDirty}
      />
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </Box>
  );
};

export default AddInventoryItem;
