import styles from '../../styles/approvalWorkflow.module.css';
import generalStyles from '../../styles/general.module.css';
import { useNavigate, useParams, useLocation } from 'react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Button from '../../components/UI/Forms/Button.jsx';
import { v4 as uuidv4 } from 'uuid';
import Text from '../../components/UI/Typography/Text';
import Input from '../../components/UI/Forms/Input';
import SingleCondition from '../../components/Admins/ApprovalWorkflow/AddRule/SingleCondition';
import spmsServiceService from '../../services/spmsService.service';
import ApprovalHierarchy from '../../components/Admins/ApprovalWorkflow/AddRule/ApprovalHierarchy';
import Box from '../../components/UI/General/Box.jsx';
import Icon from '../../components/UI/General/Icon';
import requestsService from '../../services/requestsService.service.js';
import Tooltip from '../../components/UI/General/Tooltip';
import Label from '../../components/UI/Forms/Label.jsx';
import Select from 'react-select';
import BackButton from '../../components/shared/BackButton.jsx';
import Toast from '../../components/UI/General/Toast.jsx';

const hieararchyOptions = [
  {
    label: 'Auto Approve',
    value: 'Auto_Approve',
  },
  {
    label: 'Everyone',
    value: 'Defined_List',
  },
  // {
  //   label: 'Limit of Authority',
  //   value: 'LOA',
  // },
];

const ApprovalAddRule = () => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    watch,
    setValue,
    unregister,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      hierarchy: { label: 'Auto Approve', value: 'Auto_Approve' },
    },
  });

  const { companyId, ruleId } = useParams();
  const [company, setCompany] = useState(null);
  const [module, setModule] = useState(null);
  const [rule, setRule] = useState();
  const [conditionIds, setConditionIds] = useState(ruleId ? [] : [uuidv4()]);
  const [isAddConditionActive, setIsAddConditionActive] = useState(false);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [users, setUsers] = useState([]);
  const hierarchy = watch('hierarchy');
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const getHierarchyOptions = useCallback(() => {
    return ['PURCHASE_ORDER_INVOICE', 'VENDOR'].includes(module?.type)
      ? hieararchyOptions.filter((option) => option.value !== 'LOA')
      : hieararchyOptions;
  }, [module]);

  useEffect(() => {
    // unregister(['approvers']);
    if (hierarchy.value !== 'Auto_Approve') {
      requestsService
        .getData(100, 0, {
          isEnabled: true,
          companyId,
        })
        .then((res) => {
          const users = res.data.data.content;
          const activeUsers = users.filter((user) => user.enabled);
          const filteredUsers =
            hierarchy.value === 'LOA'
              ? activeUsers.filter((user) => user.id !== module?.defaultApprover?.userId)
              : activeUsers;
          setUsers(filteredUsers);
        });
    }
  }, [hierarchy]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    spmsServiceService
      .getCompany(companyId)
      .then((res) => setCompany(res.data.data))
      .catch((err) => console.log(err));
    if (location.state) {
      spmsServiceService
        .getApprovalModuleById(companyId, location.state.moduleId, true)
        .then((res) => {
          const reqModule = res.data.data;
          setModule(reqModule);
          if (!ruleId || !res.data.data.rules) return;
          const currentRule = res.data.data.rules.find((rule) => rule.id === ruleId);
          setRule(currentRule);

          const conditions = currentRule.approvalMatrixRuleConditions;
          setConditionIds(
            !!conditions.length ? conditions.map((condition) => condition.id) : [uuidv4()],
          );
          setValue('name', currentRule.name);
          setValue('description', currentRule.description);
          setValue(
            'hierarchy',
            hieararchyOptions.find((option) => option.value === currentRule.approvalHierarchyType),
          );
        });
    }
  }, []);

  const addNewCondition = () => {
    setConditionIds((conditionIds) => [...conditionIds, uuidv4()]);
  };

  const deleteCondition = (id) => {
    const conditionToDelete = getValues(`subject-${id}`);
    let subCategorySubjectId;
    if (conditionToDelete?.value === 'VendorCategory') {
      for (const [key, value] of Object.entries(getValues())) {
        if (value?.value === 'VendorSubcategory') {
          subCategorySubjectId = key.replace('subject-', '');
          unregister([
            `subject-${subCategorySubjectId}`,
            `subjectCondition-${subCategorySubjectId}`,
            `conditionValue-${subCategorySubjectId}`,
          ]);
        }
      }
    }
    unregister([`subject-${id}`, `subjectCondition-${id}`, `conditionValue-${id}`]);
    const filteredConditionIds = conditionIds.filter((conditionId) => {
      return subCategorySubjectId
        ? conditionId !== id && conditionId !== subCategorySubjectId
        : conditionId !== id;
    });
    if (!filteredConditionIds.length) {
      setConditionIds([uuidv4()]);
    } else setConditionIds(filteredConditionIds);
    setValue('dirty', true, { shouldDirty: true });
  };

  const getAllSubjects = () => {
    const subjects = [];
    for (const [key, value] of Object.entries(getValues())) {
      if (key.includes('subject-') && !!value) subjects.push(value);
    }
    return subjects;
  };

  const onSubmit = async (data) => {
    setToast((item) => ({ ...item, opened: false }));
    setIsButtonsBlocked(true);
    let conditions = [];
    if (conditionIds.length && data[`subject-${conditionIds[0]}`]) {
      conditions = conditionIds.map((conditionId) => {
        const subject = data[`subject-${conditionId}`].value;
        const subjectCondition = data[`subjectCondition-${conditionId}`].value;
        const conditionValue =
          subject === 'Amount'
            ? [
                {
                  key: data[`conditionValue-${conditionId}`],
                  value: data[`conditionValue-${conditionId}`],
                },
              ]
            : data[`conditionValue-${conditionId}`].map((item) => ({
                key: item.label,
                value: item.value,
              }));
        const condition = { subject, subjectCondition, conditionValue };
        if (Number(conditionId)) condition.id = conditionId;
        return condition;
      });
    }
    const approvers = data[`approvers-${data.hierarchy.value}`] ?? [];
    const approversData = approvers.map((singleApprover) => {
      const approver = users.find((user) => user.id === singleApprover.value);
      return {
        userId: approver.id,
        email: approver.email,
        phoneNumber: approver.phoneNumber,
        limitOfAuthority: approver.customCompanies.find(
          (company) => company.companyId === companyId,
        ).limitOfAuthority,
        name: `${approver.firstName} ${approver.lastName}`,
      };
    });
    const newRule = {
      ...rule,
      name: data.name,
      description: data.description,
      approvalMatrixRuleConditions: conditions,
      approvalHierarchyType: data.hierarchy.value,
      approvers: approversData,
    };
    let updatedModule;
    if (ruleId) {
      const filteredRules = module.rules.filter((rule) => rule.id !== ruleId);
      updatedModule = { ...module, rules: [...filteredRules, newRule] };
    } else {
      newRule.order = module.rules.length + 1;
      updatedModule = { ...module, rules: [newRule, ...module.rules] };
    }
    spmsServiceService
      .editApprovalModuleById(companyId, module.id, updatedModule)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Saved',
          type: 'success',
          cb: () => {
            navigate('/approval', { state: { tab: module.type } });
            setIsButtonsBlocked(false);
          },
        });
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.response.data.message,
          type: 'fail',
        });
        setIsButtonsBlocked(false);
      });
  };

  return (
    <Box $mobExtend $asHolder $radius={12} $noOverflow>
      <div className={generalStyles.top}>
        <div>
          <BackButton />
        </div>
        <div className={`${generalStyles.title} ${generalStyles.underline}`}>
          <Text type="subtitle" weight={500}>
            {!!ruleId ? 'Edit' : 'Create'} New Rule
          </Text>
          <div className={generalStyles.addItemButton}>
            <Button.Main
              disabled={!isDirty || isButtonsBlocked}
              $mid
              $style={isDirty ? 'blue' : 'gray'}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button.Main>
            <Button.Main
              onClick={() => navigate('/approval', { state: { tab: module.type } })}
              type="button"
              $mid
              $style={isDirty ? 'gray' : 'blue'}
            >
              Discard
            </Button.Main>
          </div>
        </div>
      </div>
      <form className={styles.addRuleContainer}>
        <div className={styles.row}>
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="name"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Rule Name is Required',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Maximum 50 characters',
                  },
                  validate: {
                    allowed: (v) =>
                      /^(?![\s]+$)[A-Za-z0-9\s]+$/.test(v) || 'Alphanumeric characters only',
                  },
                }}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Rule Name"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Rule Name"
                    $labelRequired
                    $tooltip="Summary of rule"
                    {...field}
                  />
                )}
              />
              {errors.name && <p className="error-message">{errors.name?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="description"
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: 'Rule Description is Required',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Maximum 50 characters',
                  },
                  validate: {
                    allowed: (v) =>
                      /^(?![\s]+$)[A-Za-z0-9\s]+$/.test(v) || 'Alphanumeric characters only',
                  },
                }}
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Rule Description"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Rule Description"
                    $labelRequired
                    $tooltip="Add short description of the rule"
                    {...field}
                  />
                )}
              />
              {errors.description && <p className="error-message">{errors.description?.message}</p>}
            </div>
            <div className="inp-container">
              <Label $isRequired $title="Approval Type" />
              <Controller
                name="hierarchy"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Approval Type is Required',
                  },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Approval Type"
                    options={getHierarchyOptions()}
                  />
                )}
              />
              {errors.category && <p className="error-message">{errors.category?.value.message}</p>}
            </div>
          </div>
        </div>
        <div>
          <div className={styles.conditionsTitle}>
            <Text type="subtitle" weight={500} style={{ display: 'flex', alignItems: 'center' }}>
              Conditions <Tooltip text="Add criteria for the rule to execute" />
            </Text>
          </div>
          <div className={styles.conditions}>
            {conditionIds.map((condtionId) => (
              <SingleCondition
                key={condtionId}
                setValue={setValue}
                watch={watch}
                errors={errors}
                control={control}
                conditionId={condtionId}
                companyId={companyId}
                deleteCondition={deleteCondition}
                conditionIds={conditionIds}
                moduleType={module?.type}
                getValues={getValues}
                prevCondition={
                  rule?.approvalMatrixRuleConditions.find((cond) => cond.id === condtionId) ?? null
                }
                selectedSubjects={getAllSubjects()}
                setIsAddConditionActive={setIsAddConditionActive}
              />
            ))}
          </div>
          {isAddConditionActive && (
            <div className={styles.addConditionButton}>
              <Button.ActionLabeled onClick={addNewCondition}>
                <Button.Action $style="blue" $variant="circle" $width={20} $height={20}>
                  <div className={styles.icon}>
                    <Icon $width={20} $height={20} $icon="add" $color="white" />
                  </div>
                </Button.Action>
                <Text weight={700} type="subtitle">
                  Add Conditions
                </Text>
              </Button.ActionLabeled>
            </div>
          )}
        </div>
        {hierarchy.value !== 'Auto_Approve' && (
          <div className={styles.container}>
            <ApprovalHierarchy
              errors={errors}
              control={control}
              watch={watch}
              unregister={unregister}
              companyId={companyId}
              currency={company?.defaultCurrency}
              setValue={setValue}
              prevUsers={rule?.approvers}
              users={users}
            />
          </div>
        )}
      </form>
      {toast.opened && (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      )}
    </Box>
  );
};

export default ApprovalAddRule;
