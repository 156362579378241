import React, { useEffect, useState, Suspense, useMemo, useCallback } from 'react';
import Text from '../../components/UI/Typography/Text';
import generalStyles from '../../styles/general.module.css';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import SpmsService from '../../services/spmsService.service';
import { useStore } from '../../store/store';
import Box from '../../components/UI/General/Box';
import { reducedText } from '../../utils/reducedText';
import { nf } from '../../utils/nf';
import BackButton from '../../components/shared/BackButton';
import { useLocation } from 'react-router-dom';

const InsightsOrdersOverview = () => {
  const location = useLocation();
  const activeCompany = useStore((state) => state.activeCompany);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [trigger, setTrigger] = useState(false);

  console.log(location?.state);

  const columns = useMemo(
    () => [
      {
        name: 'Order No',
        wrap: true,
        sortable: true,
        selector: (row) => row?.title,
        cell: (row) => {
          return (
            <span data-content={'Order No'} className={generalStyles.tableValue}>
              <span>{row?.purchaseOrderNo}</span>
            </span>
          );
        },
      },
      {
        name: 'Title',
        wrap: true,
        sortable: true,
        selector: (row) => row?.title,
        cell: (row) => {
          return (
            <span data-content={'Title'} className={generalStyles.tableValue}>
              <span>{reducedText(row?.title)}</span>
            </span>
          );
        },
      },
      {
        name: 'Department',
        cell: (row) => (
          <span data-content={'Department'} className={generalStyles.tableValue}>
            <span>{row?.department?.name}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.department?.name,
      },
      {
        name: 'Location',
        cell: (row) => (
          <span data-content={'Location'} className={generalStyles.tableValue}>
            <span>{row?.locationName}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.locationName,
      },
      {
        name: 'Vendor',
        cell: (row) => (
          <span data-content={'Vendor'} className={generalStyles.tableValue}>
            <span>{row?.vendor?.legalName}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.vendor?.legalName,
      },
      {
        name: 'Requester',
        cell: (row) => (
          <span data-content={'Requester'} className={generalStyles.tableValue}>
            <span>{row?.requester?.name}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.requester?.name,
      },
      {
        name: 'Total Amount',
        cell: (row) => {
          const cur = row?.currency?.code ?? '';
          return (
            <span data-content={'Total Amount'} className={generalStyles.tableValue}>
              <span>{cur + ' ' + nf.format(row?.total)}</span>
            </span>
          );
        },
        sortable: true,
        selector: (row) => row?.total,
      },
    ],
    [],
  );

  const getData = useCallback(
    (page, size) => {
      const search = {
        purchaseOrderState: 'APPROVED',
        companyId: activeCompany.id,
      };
      return SpmsService.getPurchaseOrders(size, page, search, activeCompany.id);
    },
    [trigger, activeCompany.id],
  );

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      getData(0, 10);
    }
    return () => {
      ignore = true;
    };
  }, [getData]);

  return (
    <>
      <Box $mobExtend $asHolder $noOverflow>
        <div><BackButton to={'/insights'} /></div>
        <br/>
        <Text type="subtitle" weight={500}>
          Insights Orders Overview
        </Text>
        <br/>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBaseRemote
            columns={columns || []}
            selectableRows={false}
            paginationResetDefaultPage={resetPaginationToggle}
            fetchData={getData}
          />
        </Suspense>
      </Box>
    </>
  );
};

export default InsightsOrdersOverview;

