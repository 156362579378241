import React, { useEffect, useState } from 'react';
import UserAccount from '../../components/Admins/Users/UserAccount';
import { useNavigate, useParams } from 'react-router-dom';
import Toast from '../../components/UI/General/Toast';
import requestsService from '../../services/requestsService.service';
import { useStore } from '../../store/store';
import { useForm, useWatch } from 'react-hook-form';
import spmsServiceService from '../../services/spmsService.service';
import generalStyles from '../../styles/general.module.css';
import style from '../../styles/users.module.css';
import * as Button from '../../components/UI/Forms/Button';
import Box from '../../components/UI/General/Box';
import ModalDiscard from '../../components/shared/ModalDiscard';
import BackButton from '../../components/shared/BackButton';
import Text from '../../components/UI/Typography/Text';

const Create = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const company = useStore((state) => state.company);
  const user = useStore((state) => state.user);
  const updateUser = useStore((state) => state.updateUser);
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [values, setValues] = useState();
  const [linkedCompanies, setLinkedCompanies] = useState([]);
  const [isUserInvited, setIsUserInvited] = useState(false);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const setIsDiscarded = useStore((state) => state.setIsDiscarded);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      phoneNumber: '',
      roleName: [],
    },
    values,
  });
  const companiesChanged = useWatch({
    control,
    name: 'companiesChanged',
    defaultValue: false,
  });

  const submitUser = async (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    const { roles, roleName, ...other } = data;
    const newRoles = linkedCompanies.flatMap((lcompany) => {
      return lcompany.roles.map((role) => {
        return {
          company: { id: lcompany.companyId, name: lcompany.companyName },
          role: { id: role.value, name: role.label },
        };
      });
    });
    const newData = {
      ...other,
      customCompanies: linkedCompanies.map((company) => {
        const { department, branch } = company;
        console.log('company', company);
        return {
          ...company,
          department: department?.id,
          branchId: branch?.id,
          roles: company.roles.map((role) => role.label),
        };
      }),
      roles: newRoles,
    };
    if (!linkedCompanies.length) {
      console.log('test');
      console.log(toast);
      setIsButtonsBlocked(false);
      setToast({
        opened: true,
        message: 'No companies linked to a user',
        type: 'fail',
        cb: () => setToast((item) => ({ ...item, opened: false })),
      });
    } else {
      try {
        if (userId) {
          const response = await requestsService.updateUserInfo(userId, newData);
          if (response.data.message === 'Operation Successful') {
            setIsDiscarded(false);
            setToast({
              opened: true,
              message: 'User updated',
              type: 'success',
              cb: () => {
                if (response.data.data.id === user.id) {
                  updateUser(response.data.data);
                }
                return navigate('/users/overview/active');
              },
            });
          }
        } else {
          const response = await requestsService.registerNewUserAsAdmin(newData);
          if (response.data.message === 'Operation Successful') {
            setIsDiscarded(false);
            setToast({
              opened: true,
              message: 'User created',
              type: 'success',
              cb: () => navigate('/users/overview/active'),
            });
          }
        }
      } catch (error) {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: error.response.data.message,
          type: 'fail',
        });
      }
    }
  };

  useEffect(() => {
    const fetchLimitTo = async () => {
      const allCompanies = await Promise.all(
        user.customCompanies.map(async (company) => {
          let finalCompany;
          const res = await spmsServiceService.getMetrics(company.companyId);
          const metrix = res.data.data;
          finalCompany =
            metrix.hasDepartments && metrix.hasLocations && metrix.hasGl ? company : null;
          return finalCompany;
        }),
      );
      const fullCompanies = allCompanies.filter((company) => !!company);
      const companiesOptions = fullCompanies
        .map((item) => ({
          label: item.companyName,
          value: item.companyId,
        }))
        .sort((a, b) => a.value - b.value);
      setCompanies(companiesOptions);
      await requestsService.getRolesByCompany(company.id).then((r) => {
        if (r.data.status === 200) {
          const isUserMain = company.createdBy.userId === userId;
          const updRoles = r.data.data
            .filter((item) => item.isEnabled === true)
            .map((item) => ({
              label: item.roleName,
              value: item.roleId,
              isDisabled: item.roleName === 'CLIENT_ADMIN' && isUserMain,
            }));
          setRoles(updRoles);
        }
      });
    };
    if (company !== null && company.id !== undefined) {
      fetchLimitTo();
    }
  }, [company]);

  useEffect(() => {
    if (userId !== undefined && roles !== undefined) {
      requestsService.getUserDetailsById(userId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          const { customCompanies, id, status, ...other } = r.data.data;
          setIsUserInvited(status === 'INVITED');
          let updCustomCompanies;
          if (
            customCompanies !== undefined &&
            Array.isArray(customCompanies) &&
            customCompanies.length > 0
          ) {
            const tempCompanies = companies.map((item) => item.value);
            const tempCustomCompanies = customCompanies.filter((el) =>
              tempCompanies.includes(el.companyId),
            );
            updCustomCompanies = tempCustomCompanies
              .map((item) => {
                const companyRoles = r.data.data.roles
                  .filter((role) => role.company.id === item.companyId)
                  .map((role) => role.role.id);
                const updRoles = roles.filter((role) => companyRoles?.includes(role.value));
                return {
                  ...item,
                  roles: updRoles,
                  companyName: companies.filter((el) => el.value === item.companyId)[0].label,
                  // department: item.departmentId,
                };
              })
              .sort((a, b) => a.companyId - b.companyId);
          } else {
            updCustomCompanies = [];
          }
          setValues({
            ...other,
            userId: id,
            companiesChanged: false,
          });
          setLinkedCompanies(updCustomCompanies);
        }
      });
    }
  }, [userId, roles, companies]);
  return (
    <Box $radius={12} $noOverflow>
      <div className={generalStyles.top}>
        <div>
          <BackButton />
        </div>
        <div className={generalStyles.title}>
          <div className={generalStyles.titleText}>
            <Text type="subtitle" weight={500}>
              {!!userId ? 'Edit' : 'Create'} User
            </Text>
          </div>
          <div className={generalStyles.addItemButton}>
            <Button.Main
              $mid
              $style="blue"
              type="button"
              onClick={handleSubmit(submitUser)}
              disabled={!((companiesChanged || isDirty) && !isButtonsBlocked)}
            >
              {userId ? 'Edit' : 'Invite'} User
            </Button.Main>
            <Button.Main
              $mid
              $style="gray"
              onClick={() => (isDirty ? setShowModal(true) : navigate('/users/overview/active'))}
              type="button"
            >
              Discard
            </Button.Main>
          </div>
        </div>
      </div>
      <form>
        <UserAccount
          control={control}
          errors={errors}
          roles={roles}
          companies={companies}
          userId={userId}
          setLinkedCompanies={setLinkedCompanies}
          linkedCompanies={linkedCompanies}
          setValue={setValue}
          isUserInvited={isUserInvited}
        />
      </form>
      <ModalDiscard
        setShowModal={setShowModal}
        showModal={showModal}
        navigateTo={'/users/overview/active'}
        condition={isDirty}
      />
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </Box>
  );
};

export default Create;
