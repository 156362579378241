import { Controller, useForm } from 'react-hook-form';
import styles from '../../styles/onboarding.module.css';
import generalStyles from '../../styles/general.module.css';
import Text from '../../components/UI/Typography/Text.jsx';
import Input from '../../components/UI/Forms/Input.jsx';
import * as Button from '../../components/UI/Forms/Button.jsx';
import GradientText from '../../components/UI/General/GradientText.jsx';
import { yupResolver } from '@hookform/resolvers/yup';
import { glSchema } from '../../utils/validation/glSchema.js';
import spmsServiceService from '../../services/spmsService.service.js';
import { useStore } from '../../store/store.js';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const GLOnboarding = ({ previousStep, setToast }) => {
  const companyId = useStore((state) => state.company?.id);
  const setActiveCompany = useStore((state) => state.setActiveCompany);
  const setSteps = useStore((state) => state.setSteps);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const { companyId: paramsCompanyId } = useParams();
  const stepsDone = useStore((state) => state.stepsDone);
  const navigate = useNavigate();
  const selectedCompanyId = paramsCompanyId
    ? paramsCompanyId
    : !paramsCompanyId && stepsDone
    ? null
    : companyId;
  const {
    handleSubmit,
    control,
    trigger,
    formState: { errors, isDirty },
    getValues,
  } = useForm({ mode: 'onChange', resolver: yupResolver(glSchema) });

  let count = 0;

  const onSubmit = (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    const requestBody = {
      glCode: data.glCode,
      description: data.glDescription,
      companyId: selectedCompanyId,
    };
    spmsServiceService
      .createGlAccount(requestBody)
      .then((_res) => {
        if (!stepsDone) {
          count++;
          if (count === 1) {
            window.dataLayer.push({
              event: 'create_account_account_code',
              General_Ledger: {
                unique_cost_code: requestBody.glCode, // Unique cost code
                short_description_of_code: requestBody.description, // Short description of the cost code
              },
            });
          }
          setSteps();
        } else {
          setActiveCompany(selectedCompanyId);
        }
        setToast({
          opened: true,
          message: 'GL created',
          type: 'success',
          cb: () => {
            if (stepsDone) {
              navigate('/dashboard');
            }
            setIsButtonsBlocked(false);
          },
        });
      })
      .catch((err) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
  };
  return (
    <div className={styles.onboardForm}>
      <div className={styles.formHeading}>
        <Text type="h2" weight={600}>
          Account Code
        </Text>
        <GradientText $from="#313439b2" $to="#3778ffb2">
          <Text type="h5" weight={600}>
            Add information about your account code
          </Text>
        </GradientText>
      </div>
      <div className={styles.inputs}>
        <div className="inp-container">
          <Controller
            name="glCode"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Unique cost code *"
                className={errors.hasOwnProperty(field.name) && 'error'}
                {...field}
              />
            )}
          />
          {errors.glCode && <p className="error-message">{errors.glCode?.message}</p>}
        </div>
        <div className="inp-container">
          <Controller
            name="glDescription"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Short description of code *"
                className={errors.hasOwnProperty(field.name) && 'error'}
                {...field}
              />
            )}
          />
          {errors.glDescription && <p className="error-message">{errors.glDescription?.message}</p>}
        </div>

        <div className={generalStyles.pageButtons}>
          <Button.Main
            $primary
            $full
            $style="blue"
            $iconRight
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || isButtonsBlocked}
          >
            Go to Dashboard
          </Button.Main>
          {stepsDone && (
            <Button.Main
              $primary
              $full
              $style="gray"
              $iconRight
              type="submit"
              onClick={() => navigate('/dashboard')}
            >
              Discard
            </Button.Main>
          )}
        </div>
      </div>
      {/* <div className={styles.formBackButton}>
        <Button.ActionLabeled $primary $full $style="blue" type="button" onClick={previousStep}>
          <Text type="body-1" weight={500}>
            Back to previous step
          </Text>
        </Button.ActionLabeled>
      </div> */}
    </div>
  );
};

export default GLOnboarding;
