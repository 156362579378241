import { useStore } from '../../store/store';
import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/notifications.module.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import spmsServiceService from '../../services/spmsService.service';
import Dropdown from '../../components/UI/General/Dropdown';
import Icon from '../../components/UI/General/Icon';
import * as Button from '../../components/UI/Forms/Button.jsx';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import Input from '../../components/UI/Forms/Input';
import TabsSlider from '../../components/UI/General/TabsSlider';
import AddButton from '../../components/shared/AddButton';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import Toast from '../../components/UI/General/Toast';
import Box from '../../components/UI/General/Box';
import Text from '../../components/UI/Typography/Text';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend.js';
import Search from '../../components/UI/General/Search.jsx';
import { useForm } from 'react-hook-form';
import Filter from '../../components/UI/General/Filter.jsx';

const InventoryOverview = () => {
  const companyId = useStore((state) => state.activeCompany?.id);
  const [tab, setTab] = useState('all');
  const [filterText, setFilterText] = useState('');
  const [debouncedValue, setDebouncedValue] = useState(filterText);
  const [trigger, setTrigger] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const navigate = useNavigate();

  const { control, setValue, getValues } = useForm({
    mode: 'onChange',
  });

  const filterData = [
    {
      name: 'subCategory',
      label: 'Sub Category',
      isMulti: false,
      options: [],
    },
    {
      name: 'status',
      label: 'Status',
      isMulti: false,
      options: [
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Archived', value: 'ARCHIVED' },
      ],
    },
  ];

  const getData = useCallback(
    async (page, size) => {
      const requestBody = {
        // name: 'string',
        // code: 'string',
        // description: 'string',
        // prefere: 'string',
      };

      return await spmsServiceService.searchInventoryItems(companyId, page, size, requestBody);
    },
    [tab, debouncedValue, trigger, companyId],
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(filterText);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  const columns = useMemo(
    () => [
      {
        name: 'SKU',
        sortable: true,
        selector: (row) => row.name,
        cell: (row) => (
          <span data-content={'SKU'} className={generalStyles.tableValue}>
            <Link to={'/inventory/' + companyId + '/edit/' + row.id}>
              <span>{row?.code}</span>
            </Link>
          </span>
        ),
      },
      {
        name: 'Name',
        sortable: true,
        selector: (row) => row.module,
        cell: (row) => (
          <span data-content={'Name'} className={generalStyles.tableValue}>
            <span>{row?.name}</span>
          </span>
        ),
      },
      {
        name: 'Description',
        sortable: true,
        selector: (row) => row.triggerEvent,
        cell: (row) => (
          <span data-content={'Description'} className={generalStyles.tableValue}>
            <span>{row?.description}</span>
          </span>
        ),
      },
      {
        name: 'Unit of Measure',
        cell: (row) => (
          <div data-content={'Unit of Measure'} className={generalStyles.tableValue}>
            <div className={styles.users}>{readableTitleFromBackend(row.unitOfMeasure)}</div>
          </div>
        ),
      },
      {
        name: 'Sub Category',
        cell: (row) => (
          <div data-content={'Sub Category'} className={generalStyles.tableValue}>
            {row.subCategory}
          </div>
        ),
      },
      {
        name: 'Available Stock',
        cell: (row) => (
          <div data-content={'Available Stock'} className={generalStyles.tableValue}>
            {/* {row.status} */}
          </div>
        ),
      },
      {
        name: 'Status',
        cell: (row) => (
          <div data-content={'Status'} className={generalStyles.tableValue}>
            {readableTitleFromBackend(row.status)}
          </div>
        ),
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => {
          return (
            <div className={generalStyles.actionMenuHolder}>
              <Dropdown collapsible className={generalStyles.actionMenu}>
                <Dropdown.Header>
                  <Button.Action $style="white" $width={32} $height={32}>
                    <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                  </Button.Action>
                </Dropdown.Header>
                <Dropdown.Body>
                  {row.status === 'ACTIVE' ? (
                    <Menu className={generalStyles.actionMenuList}>
                      <MenuItem onClick={() => archiveInventoryItem(row.id)}>Archive</MenuItem>
                    </Menu>
                  ) : (
                    <Menu className={generalStyles.actionMenuList}>
                      <MenuItem onClick={() => activateInventoryItem(row.id)}>Activate</MenuItem>
                    </Menu>
                  )}
                </Dropdown.Body>
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [tab, companyId],
  );

  const archiveInventoryItem = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .archiveInventoryItem(companyId, id)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Notification archived',
          type: 'success',
        });
        setTrigger((trigger) => !trigger);
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.toString(),
          type: 'fail',
        });
      });
  };
  const activateInventoryItem = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .activateInventoryItem(companyId, id)
      .then((_res) => {
        setToast({
          opened: true,
          message: 'Item activated',
          type: 'success',
        });
        setTrigger((trigger) => !trigger);
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.toString(),
          type: 'fail',
        });
      });
  };

  const filterCmp = useMemo(
    () => (
      <Search
        type="text"
        $iconName="search"
        $iconColor="#fff"
        placeholder="Search by Code"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText],
  );

  return (
    <Box $radius={12} $mobExtend $asHolder>
      <div className={styles.title}>
        <Text type="subtitle" weight={500}>
          Inventory
        </Text>
      </div>
      <div className={generalStyles.tabSection}>
        <div className={generalStyles.search}>
          {filterCmp}
          <div className={generalStyles.filters}>
            <Filter
              control={control}
              filterData={filterData}
              setTriggerRefetch={setTrigger}
              setValue={setValue}
            />
          </div>
        </div>
        <div className={generalStyles.addItemButton}>
          <AddButton $click={() => navigate(`/inventory/${companyId}/add`)} />
        </div>
      </div>
      <DataTableBaseRemote
        columns={columns}
        selectableRows={false}
        paginationResetDefaultPage={false}
        fetchData={getData}
      />
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </Box>
  );
};

export default InventoryOverview;
