import React, { useEffect, useState } from 'react';
import style from '../../styles/subscriptions.module.css';
import Text from '../../components/UI/Typography/Text';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button';
import Modal from '../../components/UI/Modal/Modal';
import styles from '../../styles/home.module.css';
import GradientText from '../../components/UI/General/GradientText';
import spmsServiceService from '../../services/spmsService.service';
import { useStore } from '../../store/store';
import { useSubscriptionAllowed } from '../../hooks/useSubscriptionAllowed';
import { useSearchParams } from 'react-router-dom';

const Subscription = () => {
  const [searchParams] = useSearchParams();
  const activeCompany = useStore((state) => state.activeCompany);
  const [pauseModal, setPauseModal] = useState(false);
  const [table, setTable] = useState([]);
  const [currentSwitchSettings, setCurrentSwitchSettings] = useState({ interval_count: 1, interval: 'month' });
  const [switchSettings, setSwitchSettings] = useState([]);
  const accessSubscription = useSubscriptionAllowed();
  console.log(accessSubscription);

  useEffect(() => {
    spmsServiceService.getAllSubscriptionsPlans().then(async (r) => {
      if (r.data.message === "Operation Successful"){
        const f = await Promise.all(
          r.data.data.map(async plan => {
            return await spmsServiceService.getPlanPrices(plan.id).then(r => {
              console.log(r.data.data);
              return { ...plan, allProductPrices: r.data.data }
            })
          })
        )
        const unique = () => {
          const unique = new Set()
          return f.map(el=>el.allProductPrices).flat().map(el => ({interval: el.recurring.interval, interval_count: el.recurring.interval_count})).filter(obj => {
            const key = `${obj.interval}-${obj.interval_count}`
            if (unique.has(key)) return false
            unique.add(key)
            return true
          }).sort((a, b) => a.interval === b.interval ? a.interval_count - b.interval_count : a.interval.localeCompare(b.interval))
        }
        setTable(f);
        setSwitchSettings(unique())
      }
    });
  }, []);

  const switchPlanPrice = (data) => {
    const r = switchSettings.find(el => el.interval_count === data.interval_count && el.interval === data.interval)
    setCurrentSwitchSettings(r)
  }

  const selectPlanStripe = (id) => {
    const body = {
      successUrl: window.location.href+'?status=success',
      cancelUrl: window.location.href+'?status=cancel',
      priceId: id,
      quantity: 1
    }
    spmsServiceService.stripeCheckoutSession(activeCompany.id, body).then(r => {
      console.log(r);
      window.location.href = r.data.data.url
    })
  }

  return (
    <div>

      {searchParams?.get('status') === 'cancel' && (
        <h1>you cancelled subscription</h1>
      )}
      {searchParams?.get('status') === 'success' && (
        <h1>you successfully subscribed!</h1>
      )}

      <div className={style.titleBlock}>
        <Text weight={500} type={'h3'}>Subscription</Text>
        <Text type={'body-1'} className={style.subtitle}>Current Plan Information</Text>
      </div>

      <div className={style.info}>
        <div className={style.infoCard}>
          <div className={style.infoCardHead}>
            <Text type={'body-1'}>Current Plan</Text>
            <div className={style.infoCardLinks}>
              <Text type={'body-2'} className="link" onClick={() => setPauseModal(true)}>Pause</Text>
              <Text type={'body-2'} className="link" onClick={(e) => (e)}>Cancel</Text>
            </div>
          </div>
          <div className={style.infoCardBody}>
            <Text type={'subtitle'} weight={600}>STARTER MONTHLY</Text>
            <Text type={'body-1'}>Start Date: 1 January 2025</Text>
          </div>
        </div>
        <div className={style.infoCard}>
          <div className={style.infoCardHead}>
            <Text type={'body-1'}>Credit Card</Text>
            <div className={style.infoCardLinks}>
              <Text type={'body-2'} className="link" onClick={(e) => (e)}>Manage Billing Information</Text>
            </div>
          </div>
          <div className={style.infoCardBody}>
            <Text type={'subtitle'} weight={600}>**** **** **** 8765</Text>
            <Text type={'body-1'}>Expiry Date: 1 January 2028</Text>
          </div>
        </div>
        <div className={style.infoCard}>
          <div className={style.infoCardHead}>
            <Text type={'body-1'}>Next Invoice</Text>
            <div className={style.infoCardLinks}>
              <Text type={'body-2'} className="link" onClick={(e) => (e)}>BILLING INFO</Text>
            </div>
          </div>
          <div className={style.infoCardBody}>
            <div className={style.infoCardBodyText}>
              <Text type={'subtitle'} weight={600}>$50.00</Text>&nbsp;
              <Text type={'body-1'} weight={400}>(5 active users)</Text>
            </div>
            <Text type={'body-1'}>1 February 2025</Text>
          </div>
        </div>
      </div>

      <div className={style.switch}>
        {switchSettings.map(item => (
          <Button.Main $mid $reverseColor $style="blue" type="button" className={
            item.interval+item.interval_count === currentSwitchSettings.interval+currentSwitchSettings.interval_count ? 'active' : null
          } onClick={() => switchPlanPrice(item)} key={item.interval+item.interval_count}>
            <Text weight={400}>{item.interval_count > 1 ? item.interval_count : null} {item.interval} Plans</Text>
          </Button.Main>
        ))}
      </div>

      <div className={style.pricingTable}>
        {table.map(item => {
          const price = item.allProductPrices.find(p => p.recurring.interval_count === currentSwitchSettings.interval_count && p.recurring.interval === currentSwitchSettings.interval)
          return(
            <div key={item.id} className={styles.pricingCol}>
              <div className={styles.pricingTableHeadTop}>
                <h3 className={styles.pricingTableHeadTitle}>
                  <GradientText $from="#313439" $to="#3778FF">{item.name}</GradientText>
                </h3>
                <span className={styles.rainbowText}>Most Popular</span>
              </div>

              <div className={styles.pricingTableHeadContent}>
                <div className={styles.pricingTableHeadPrice}>
                  <span>{`${price?.currency} ${price?.unit_amount / 100}`}</span>
                  <span className={styles.pricingTableHeadPricePeriod}>/{price.recurring.interval}</span>
                </div>
                <div className={styles.pricingTableHeadPriceDescription}>
                  <Button.Main $full $primary $style="blue" onClick={() => selectPlanStripe(price.id)}>
                    {price.id === accessSubscription?.plan?.default_price?.id ? 'Subscribe' : 'Upgrade'}
                  </Button.Main>
                </div>
                <div className={styles.pricingBenefits}>
                  {item.marketingFeatures.map((el, index) => (
                    <div key={index} className={styles.pricingBenefit}>
                      <img src="/assets/img/general/check.svg" alt="check-icon"/>
                      <Text weight={400} type={'body-1'}>{el.name}</Text>
                    </div>
                  ))}
                </div>
              </div>

            </div>
          );
        })}
      </div>

      <Modal
        $show={pauseModal}
        $close={() => setPauseModal(false)}
        $radius={6}
        $maxWidth={'504px'}
        $title={'Pause your subscription'}
      >
        <div className={generalStyles.modalText}>
          <Text type={'body-2'}>You can pause your subscription renewal for 1 month (e.g. if your subscription is set to
            renew on January 15, 2030, you can delay it to February 15, 2030). After pausing, you will still be able to
            use premium features until the end of the original billing cycle.</Text><br/>
          <Text type={'body-2'}>Your subscription will reactivate one month later and the payment method on file will be
            charged. You can always add more pauses or cancel your subscription later.</Text>
        </div>
      </Modal>

    </div>
  );
};

export default Subscription;
