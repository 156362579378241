import React, { useEffect } from 'react';
import generalStyles from '../../styles/general.module.css';
import Text from '../UI/Typography/Text';
import * as Button from '../UI/Forms/Button';
import Modal from '../UI/Modal/Modal';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../store/store';

const ModalDiscard = (props) => {
  const navigate = useNavigate();
  const setIsDiscarded = useStore((state) => state.setIsDiscarded);

  useEffect(()=>{
    if (props.condition === true){
      setIsDiscarded(true)
    }else {
      setIsDiscarded(false)
    }
  },[props.condition])

  return (
    <Modal
      $show={props.showModal}
      $close={() => props.setShowModal(false)}
      $radius={8}
      $maxWidth={'464px'}
    >
      <div className={generalStyles.discardModal}>
        <Text weight={600} type={'h3'}>Your changes won’t be saved</Text>
        <Text type={'body-1'}>We won’t be able to save your data if you move away from this page.</Text>
        <div className={generalStyles.discardModalButtons}>
          <Button.Main $mid $style={'blank'} onClick={() => {
            props.setShowModal(false);
          }} type="button">
            Go back
          </Button.Main>
          <Button.Main $mid $style={'blue'} onClick={() => (setIsDiscarded(false), navigate(props.navigateTo)) } type="button">
            Continue
          </Button.Main>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDiscard;
