import { Controller, useForm } from 'react-hook-form';
import styles from '../../styles/onboarding.module.css';
import generalStyles from '../../styles/general.module.css';
import Text from '../../components/UI/Typography/Text.jsx';
import Input from '../../components/UI/Forms/Input.jsx';
import * as Button from '../../components/UI/Forms/Button.jsx';
import GradientText from '../../components/UI/General/GradientText.jsx';
import spmsServiceService from '../../services/spmsService.service.js';
import { useStore } from '../../store/store.js';
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useNavigate, useParams } from 'react-router-dom';

const DepartmentOnboarding = ({ nextStep, previousStep, setToast }) => {
  const companyId = useStore((state) => state.company?.id);
  const steps = useStore(useShallow((state) => state.steps));
  const setSteps = useStore((state) => state.setSteps);
  const [departmentId, setDepartmentId] = useState(null);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const { companyId: paramsCompanyId } = useParams();
  const stepsDone = useStore((state) => state.stepsDone);
  const navigate = useNavigate();
  const selectedCompanyId = paramsCompanyId
    ? paramsCompanyId
    : !paramsCompanyId && stepsDone
    ? null
    : companyId;
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    setValue,
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    const departmentStep = steps.find((step) => step.type === 'department');
    if (!departmentStep.done) return;
    spmsServiceService.getDepartments(selectedCompanyId).then((res) => {
      if (res.data.data) {
        const department = res.data.data[0];
        if (department) {
          setDepartmentId(department.id);
          setValue('departmentName', department.name);
          setValue('departmentId', department.externalId);
        }
      }
    });
  }, []);

  let count = 0;

  const onSubmit = (data) => {
    if (!isDirty) {
      nextStep();
      return;
    }
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    const requestBody = {
      name: data.departmentName,
      externalId: data.departmentId,
    };
    if (departmentId) {
      spmsServiceService
        .updateDepartment(selectedCompanyId, departmentId, requestBody)
        .then((_res) => {
          if (!stepsDone) {
            count++;
            if (count === 1) {
              window.dataLayer.push({
                event: 'create_account_department',
                Department: {
                  unique_department_id: requestBody.externalId, // Unique identifier for the department
                  department_name: requestBody.name, // Name of the department
                },
              });
            }
          }
          setToast({
            opened: true,
            message: 'Saved',
            type: 'success',
            cb: () => {
              nextStep();
              setIsButtonsBlocked(false);
            },
          });
        })
        .catch((err) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
        });
    } else
      spmsServiceService
        .createDepartment(selectedCompanyId, requestBody)
        .then((_res) => {
          if (!stepsDone) {
            count++;
            if (count === 1) {
              window.dataLayer.push({
                event: 'create_account_department',
                Department: {
                  unique_department_id: requestBody.externalId, // Unique identifier for the department
                  department_name: requestBody.name, // Name of the department
                },
              });
            }
            setSteps();
          }
          setToast({
            opened: true,
            message: 'Department created',
            type: 'success',
            cb: () => {
              nextStep();
              setIsButtonsBlocked(false);
            },
          });
        })
        .catch((err) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
        });
  };

  return (
    <div className={styles.onboardForm}>
      <div className={styles.formHeading}>
        <Text type="h2" weight={600}>
          Department
        </Text>
        <GradientText $from="#313439b2" $to="#3778ffb2">
          <Text type="h5" weight={600}>
            Add information about your department
          </Text>
        </GradientText>
      </div>
      <div className={styles.inputs}>
        <div className="inp-container">
          <Controller
            name="departmentId"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Department ID is required',
              },
              maxLength: {
                value: 15,
                message: 'Maximum 15 characters',
              },
              pattern: {
                value: /^[a-zA-Z0-9@&()'-\s]+$/,
                message: 'Department ID is not valid',
              },
              validate: (value) => value.trim() !== '' || 'Department ID is required',
            }}
            defaultValue=""
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Unique Department ID *"
                className={errors.hasOwnProperty(field.name) && 'error'}
                {...field}
              />
            )}
          />
          {errors.departmentId && <p className="error-message">{errors.departmentId?.message}</p>}
        </div>
        <div className="inp-container">
          <Controller
            name="departmentName"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Department name is required',
              },
              maxLength: {
                value: 50,
                message: 'Maximum 50 characters',
              },
              pattern: {
                value: /^[a-zA-Z0-9@&()'-\s]+$/,
                message: 'Department name is not valid',
              },
              validate: (value) => value.trim() !== '' || 'Department name is required',
            }}
            defaultValue=""
            render={({ field }) => (
              <Input
                type="text"
                placeholder="Department name *"
                className={errors.hasOwnProperty(field.name) && 'error'}
                {...field}
              />
            )}
          />
          {errors.departmentName && (
            <p className="error-message">{errors.departmentName?.message}</p>
          )}
        </div>
        <div className={generalStyles.pageButtons}>
          <Button.Main
            $primary
            $full
            $style="blue"
            $iconRight
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || isButtonsBlocked}
          >
            {departmentId && !isDirty ? 'Continue' : 'Save & Continue'}
          </Button.Main>
          {stepsDone && (
            <Button.Main
              $primary
              $full
              $style="gray"
              $iconRight
              type="submit"
              onClick={() => navigate('/dashboard')}
            >
              Discard
            </Button.Main>
          )}
        </div>
      </div>
      {/* <div className={styles.formBackButton}>
        <Button.ActionLabeled $primary $full $style="blue" type="button" onClick={previousStep}>
          <Text type="body-1" weight={500}>
            Back to previous step
          </Text>
        </Button.ActionLabeled>
      </div> */}
    </div>
  );
};

export default DepartmentOnboarding;
