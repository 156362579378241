import React, { useEffect } from 'react';
import styles from '../styles/home.module.css';
import { Link } from 'react-router-dom';
import * as Button from '../components/UI/Forms/Button';
import Text from '../components/UI/Typography/Text';

const GetDemo = () => {
  useEffect(()=> {
    const script = document.createElement('script');
    script.src='https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script)
    }
  },[])
  return (
    <>
      <header className={styles.header}>
        <div className={styles.container}>
          <div className={styles.headerInner}>
            <label htmlFor="showMobileMenu" className={styles.mobileMenu}>
              <span/>
              <span/>
              <span/>
            </label>
            <div className={styles.headerLogo}>
              <Link to="/">
                <img src="assets/img/home/logo.svg" alt="SpendPro"/>
              </Link>
            </div>
            <input type="checkbox" name="showMobileMenu" id="showMobileMenu"/>
            <div className={styles.headerActions}>
              <Link to="/login">
                <button className={styles.loginButton}>
                  Login
                </button>
              </Link>
              <Link to="/register">
                <Button.Main className={styles.registerButton} $primary $style="blue">
                  Start Free Trial
                </Button.Main>
              </Link>
            </div>
          </div>
        </div>
      </header>
      <main>
        <section className={styles.hero}>
          <div className={styles.container}>
            <div className="meetings-iframe-container" data-src="https://meetings.hubspot.com/spendpro?embed=true"></div>
          </div>
        </section>
      </main>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <div className={styles.footerContainer}>
            <div className={styles.footerInner}>
              <div className={styles.footerCol}>
                <img src="assets/img/home/logo.svg" alt="SpendPro"/>
                <div className={styles.footerSocials}>
                  <a href="https://www.linkedin.com/company/spendpro/" target="_blank" rel="noreferrer" onClick={() => {
                    window.dataLayer.push({
                      event: 'linkedIn_click',
                      button_location: 'button_location',
                    });
                  }}>
                    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M4.875 4.07758C3.97753 4.07758 3.25 4.80511 3.25 5.70258V21.9526C3.25 22.85 3.97753 23.5776 4.875 23.5776H21.125C22.0224 23.5776 22.75 22.85 22.75 21.9526V5.70258C22.75 4.80511 22.0224 4.07758 21.125 4.07758H4.875ZM9.23082 8.41386C9.23692 9.44979 8.46149 10.0881 7.54133 10.0835C6.67449 10.079 5.91887 9.38886 5.92344 8.41538C5.92801 7.49979 6.65164 6.76397 7.59161 6.7853C8.54528 6.80663 9.23692 7.50589 9.23082 8.41386ZM13.303 11.4028H10.573H10.5715V20.676H13.4568V20.4596C13.4568 20.0481 13.4565 19.6364 13.4562 19.2246C13.4553 18.1264 13.4543 17.0269 13.46 15.9289C13.4615 15.6623 13.4736 15.3851 13.5422 15.1306C13.7996 14.18 14.6544 13.5661 15.608 13.717C16.2204 13.8128 16.6256 14.1679 16.7962 14.7453C16.9014 15.1062 16.9486 15.4947 16.9532 15.8711C16.9655 17.006 16.9638 18.1409 16.9621 19.2759C16.9614 19.6765 16.9608 20.0773 16.9608 20.4779V20.6745H19.8553V20.4521C19.8553 19.9624 19.8551 19.4728 19.8548 18.9833C19.8542 17.7596 19.8536 16.536 19.8568 15.312C19.8584 14.7589 19.799 14.2136 19.6634 13.6788C19.4608 12.8836 19.0419 12.2254 18.3609 11.7502C17.8779 11.4119 17.3477 11.1941 16.7552 11.1697C16.6877 11.1669 16.6196 11.1632 16.5513 11.1596C16.2483 11.1432 15.9403 11.1265 15.6506 11.185C14.8218 11.351 14.0937 11.7304 13.5437 12.3991C13.4798 12.4758 13.4173 12.5537 13.324 12.6699L13.303 12.6963V11.4028ZM6.15511 20.679H9.02679V11.4089H6.15511V20.679Z" fill="white"/>
                    </svg>
                  </a>
                  <a href="https://x.com/spend_pro" target="_blank" rel="noreferrer" onClick={() => {
                    window.dataLayer.push({
                      event: 'twitter_click',
                      button_location: 'button_location',
                    });
                  }}>
                    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.7512 4.5647H21.818L15.1179 12.1893L23 22.5647H16.8284L11.9946 16.2721L6.46359 22.5647H3.39494L10.5613 14.4093L3 4.5647H9.32828L13.6976 10.3164L18.7512 4.5647ZM17.6748 20.737H19.3742L8.4049 6.29639H6.58133L17.6748 20.737Z" fill="white"/>
                    </svg>
                  </a>
                  <a href="https://www.facebook.com/" target="_blank" rel="noreferrer" onClick={() => {
                    window.dataLayer.push({
                      event: 'facebook_click',
                      button_location: 'button_location',
                    });
                  }}>
                    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.8337 13.8938C23.8337 7.87415 18.9835 2.99426 13.0003 2.99426C7.01724 2.99426 2.16699 7.87415 2.16699 13.8938C2.16699 19.334 6.12857 23.8432 11.3076 24.6609V17.0444H8.55697V13.8938H11.3076V11.4925C11.3076 8.7608 12.925 7.25189 15.3995 7.25189C16.5849 7.25189 17.8245 7.46478 17.8245 7.46478V10.1471H16.4585C15.1128 10.1471 14.693 10.9873 14.693 11.8493V13.8938H17.6976L17.2173 17.0444H14.693V24.6609C19.8721 23.8432 23.8337 19.3342 23.8337 13.8938Z" fill="white"/>
                    </svg>
                  </a>
                  <a href="https://www.instagram.com/" target="_blank" rel="noreferrer" onClick={() => {
                    window.dataLayer.push({
                      event: 'instagramm_click',
                      button_location: 'button_location',
                    });
                  }}>
                    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M17.3333 4.07758H8.66667C5.67513 4.07758 3.25 6.5027 3.25 9.49424V18.1609C3.25 21.1524 5.67513 23.5776 8.66667 23.5776H17.3333C20.3249 23.5776 22.75 21.1524 22.75 18.1609V9.49424C22.75 6.5027 20.3249 4.07758 17.3333 4.07758ZM20.8542 18.1609C20.8482 20.1029 19.2753 21.6758 17.3333 21.6817H8.66667C6.72463 21.6758 5.15178 20.1029 5.14583 18.1609V9.49424C5.15178 7.55221 6.72463 5.97936 8.66667 5.97341H17.3333C19.2753 5.97936 20.8482 7.55221 20.8542 9.49424V18.1609ZM18.1458 9.76508C18.7442 9.76508 19.2292 9.28005 19.2292 8.68174C19.2292 8.08344 18.7442 7.59841 18.1458 7.59841C17.5475 7.59841 17.0625 8.08344 17.0625 8.68174C17.0625 9.28005 17.5475 9.76508 18.1458 9.76508ZM13 8.95258C10.3076 8.95258 8.125 11.1352 8.125 13.8276C8.125 16.52 10.3076 18.7026 13 18.7026C15.6924 18.7026 17.875 16.52 17.875 13.8276C17.8779 12.5338 17.3652 11.2921 16.4503 10.3772C15.5354 9.46238 14.2938 8.94969 13 8.95258ZM10.0208 13.8276C10.0208 15.4729 11.3546 16.8067 13 16.8067C14.6454 16.8067 15.9792 15.4729 15.9792 13.8276C15.9792 12.1822 14.6454 10.8484 13 10.8484C11.3546 10.8484 10.0208 12.1822 10.0208 13.8276Z" fill="white"/>
                    </svg>
                  </a>
                  <a href="https://www.youtube.com/@SpendPro" target="_blank" rel="noreferrer" onClick={() => {
                    window.dataLayer.push({
                      event: 'youtube_click',
                      button_location: 'button_location',
                    });
                  }}>
                    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.3923 8.36798C23.2689 7.91016 23.0277 7.49265 22.6928 7.15699C22.358 6.82133 21.941 6.57924 21.4834 6.45481C19.787 5.98898 12.9999 5.98139 12.9999 5.98139C12.9999 5.98139 6.21387 5.97381 4.51629 6.41906C4.05904 6.54922 3.64293 6.79473 3.3079 7.13204C2.97287 7.46934 2.73018 7.88711 2.60312 8.34523C2.1557 10.0417 2.15137 13.5604 2.15137 13.5604C2.15137 13.5604 2.14704 17.0964 2.5912 18.7756C2.84037 19.704 3.57162 20.4374 4.50112 20.6876C6.21495 21.1535 12.9836 21.1611 12.9836 21.1611C12.9836 21.1611 19.7707 21.1686 21.4672 20.7245C21.9249 20.6003 22.3423 20.3587 22.678 20.0238C23.0137 19.6888 23.2562 19.272 23.3815 18.8146C23.83 17.1191 23.8332 13.6016 23.8332 13.6016C23.8332 13.6016 23.8549 10.0645 23.3923 8.36798ZM10.8289 16.8201L10.8343 10.3201L16.4752 13.5756L10.8289 16.8201Z" fill="white"/>
                    </svg>
                  </a>
                </div>
              </div>
              <div className={styles.footerCol}>
                <Text type={'body-1'} weight={500}>Features</Text>
                <Link to="/">Procure-to-Pay</Link>
                <Link to="/register?type=vendor">Vendor Management</Link>
                <Link to="/">Budget Management</Link>
                <Link to="/">Integrations</Link>
              </div>
              <div className={styles.footerCol}>
                <Text type={'body-1'} weight={500}>Resources</Text>
                <Link to="/" target="_blank" rel="noreferrer" >Guides</Link>
                <Link to="/" target="_blank" rel="noreferrer" >Help Center</Link>
                <Link to="/" target="_blank" rel="noreferrer" >Affiliate Program</Link>
                <Link to="/" target="_blank" rel="noreferrer" >Vendors</Link>
              </div>
              <div className={styles.footerCol}>
                <Text type={'body-1'} weight={500}>Legal</Text>
                <Link to="/terms" target="_blank">Terms of Service</Link>
                <Link to="/privacy" target="_blank">Privacy Policy</Link>
                <Link to="/cookie-policy" target="_blank">Cookie Policy</Link>
                <Link to="/acceptable-use-policy" target="_blank">Acceptable Use Policy</Link>
              </div>
            </div>
            <div className={styles.copyrights}>
              <div className={styles.copyrightsBlock}>
                <span>© {new Date().getFullYear()} SpendPro</span>
              </div>
              <div className={styles.copyrightsBlock}>
                <Link to="https://apps.apple.com/" target="_blank" rel="noreferrer" onClick={() => {
                  window.dataLayer.push({
                    event: 'app_store_click',
                    button_location: 'button_location',
                  });
                  console.log(window.dataLayer);
                }}>IOS Store</Link>
                <Link to="https://play.google.com/" target="_blank" rel="noreferrer" onClick={() => {
                  window.dataLayer.push({
                    event: 'google_play_click',
                    button_location: 'button_location',
                  });
                  console.log(window.dataLayer);
                }}>Google Play Store</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default GetDemo;
