import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from '../../styles/requisition.module.css';
import stylesPo from '../../styles/purchaseOrders.module.css';
import generalStyles from '../../styles/general.module.css';
import Box from '../../components/UI/General/Box';
import Summary from '../../components/Admins/PurchaseOrders/View/Summary';
import * as Button from '../../components/UI/Forms/Button';
import Text from '../../components/UI/Typography/Text';
import GridItem from '../../components/Admins/PurchaseOrders/View/GridItem';
import ApprovalTab from '../../components/Admins/PurchaseOrders/Tabs/ApprovalTab';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import Toast from '../../components/UI/General/Toast';
import DatePicker from 'react-datepicker';
import spmsServiceService from '../../services/spmsService.service';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import { useStore } from '../../store/store';
import TabsSlider from '../../components/UI/General/TabsSlider';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import BackButton from '../../components/shared/BackButton';
import Pagination from '../../components/Admins/ApprovalWorkflow/Overview/Pagination';
import Top from '../../components/Admins/PurchaseOrders/View/Top';
import Icon from '../../components/UI/General/Icon';
import { regExps } from '../../utils/regExps';
import Textarea from '../../components/UI/Forms/Textarea';
import CommentsTab from '../../components/Admins/PurchaseOrders/Tabs/CommentsTab';
import StatusBar from '../../components/Admins/PurchaseOrders/View/StatusBar';
import Label from '../../components/UI/Forms/Label';
import SelectUsers from '../../components/shared/SelectUsers';
import SelectDepartments from '../../components/shared/SelectDepartments';
import SelectVendors from '../../components/shared/SelectVendors';
import Input from '../../components/UI/Forms/Input';
import SelectBudgets from '../../components/shared/SelectBudgets';
import Select from 'react-select';
import moment from 'moment';
import SelectLocations from '../../components/shared/SelectLocations';
import Item from '../../components/Admins/Requisition/Item';

const RequisitionHistory = ({ fetchHistory, handleDate }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * 10;
  const endIndex = startIndex + 10;
  const [values, setValues] = useState(null);

  const nextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };
  const prevPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };
  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await fetchHistory(currentPage, 10);
        setData(response.data.data.content);
        setTotalRows(response.data.data.totalElements);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [fetchHistory, currentPage]);

  return (
    <div className={styles.history}>
      {loading ? (
        <h1>Loading...</h1>
      ) : (
        <>
          {data.map((item) => (
            <div className={styles.historyItem}>
              <div className={styles.historyEvent}>
                <span>{item.actor.name}</span> <b>{item.event.split(' ')[0].toLowerCase()}</b>{' '}
                <span>{item.event.split(' ').splice(1).join(' ')}</span>
              </div>
              <div className={styles.historyDate}>{handleDate(item.createdAt)}</div>
            </div>
          ))}
        </>
      )}
      <Pagination
        changePage={changePage}
        nextPage={nextPage}
        prevPage={prevPage}
        currentPage={currentPage}
        itemsLength={totalRows}
        startIndex={startIndex}
        endIndex={endIndex}
      />
    </div>
  );
};

const RequisitionView = () => {
  const { defaultCurrency } = useStore((state) => state.activeCompany);
  const activeCompany = useStore((state) => state.activeCompany);
  const user = useStore((state) => state.user);
  const { requisitionId } = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState('overview');
  const [taxes, setTaxes] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [values, setValues] = useState(null);
  const [vendorCategories, setVendorCategories] = useState([]);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const location = useLocation();

  const {
    handleSubmit,
    control,
    setError,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    values,
  });
  const categoryWatcher = useWatch({ control, name: 'category' });
  const subCategoryWatcher = useWatch({ control, name: 'subcategory' });
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  let isApprover = useRef(0);
  let isCurrentUserApproved = useRef(false);
  const { fields, append, remove } = useFieldArray({
    name: 'purchaseOrderItems',
    control,
  });
  const accessRequisition = useAccessAllowed('Requests');

  const subtotal = useMemo(() => {
    return values?.purchaseOrderItems
      ? values?.purchaseOrderItems.reduce(
          (acc, current) => acc + (parseFloat(current.subtotal) || 0),
          0,
        )
      : 0;
  }, [values]);

  const taxTotal = useMemo(() => {
    return values?.purchaseOrderItems
      ? values?.purchaseOrderItems.reduce(
          (acc, current) => acc + (parseFloat(current.taxTotal) || 0),
          0,
        )
      : 0;
  }, [values]);
  const totalCost = useMemo(() => {
    return values?.purchaseOrderItems
      ? values?.purchaseOrderItems.reduce(
          (acc, current) => acc + (parseFloat(current.totalCost) || 0),
          0,
        )
      : 0;
  }, [values]);

  const getData = useCallback(async (page, size) => {
    const search = {
      module: 'PurchaseOrder',
      moduleId: requisitionId,
      userId: user.id,
    };
    return await spmsServiceService.getSystemAuditLog(size, page, activeCompany.id, search);
  }, []);

  const onSubmit = async (data, type) => {
    setToast((item) => ({ ...item, opened: false }));
    const newPurchaseOrderItems =
      data.purchaseOrderItems !== undefined && data.purchaseOrderItems.length > 0
        ? data.purchaseOrderItems.map((item) => ({
            ...item,
            generalLedgerId: item.generalLedgerId.value,
            taxId: item.taxId.value.id,
            uom: item.unitType.value,
          }))
        : [];
    const newData = {
      title: data.title,
      companyId: data.companyId.value,
      locationId: data.locationId.value,
      budget: { id: data.budget.value },
      departmentId: data.departmentId.value,
      requester: {
        userId: data.requester.userId,
        email: data.requester.value,
        name: data.requester.label,
      },
      currency: values.currency,
      attachments: values.attachments,
      deliveryDate: moment(data.deliveryDate).format('DD/MM/YYYY'),
      vendorId: data.vendorId.value,
      purchaseOrderNotes: data.requestNotes,
      category: data.category.value,
      subCategory: data.subcategory.value,
      purchaseOrderItems: newPurchaseOrderItems,
    };
    await spmsServiceService
      .updatePurchaseOrder(requisitionId, newData)
      .then((r) => {
        const approveData = {
          notes: data.requestNotes,
          approved: type === 'approved' ? true : type === 'rejected' ? false : undefined,
          approvedPurchaseOrderItems: data.purchaseOrderItems.map((el) => el.id),
        };
        if (type === 'rejected' && data.requestNotes === '') {
          setTab('notes');
          setError('requestNotes', {
            type: 'focus',
            message: 'Please add the reason for rejecting the request',
          });
        } else {
          spmsServiceService
            .approvePurchaseOrder(requisitionId, approveData)
            .then((r) => {
              setToast({
                opened: true,
                message: 'Requisition ' + type,
                type: 'success',
                cb: () => {
                  navigate('/approve/requisitions');
                  setIsButtonsBlocked(false);
                },
              });
            })
            .catch((err) => {
              setIsButtonsBlocked(false);
              setToast({
                opened: true,
                message: err.response.data.message,
                type: 'fail',
              });
            });
        }
      })
      .catch((reason) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message:
            reason.response.data.message !== undefined
              ? reason.response.data.message
              : reason.response.data.error,
          type: 'fail',
        });
      });
    // setIsButtonsBlocked(true);
  };

  const submitForApproval = () => {
    setToast((item) => ({ ...item, opened: false }));
    setIsButtonsBlocked(true);
    if (!values?.attachments.length) {
      setToast({
        opened: true,
        message: 'Documents are required to submit for approval',
        type: 'fail',
        cb: () => {
          setToast((item) => ({ ...item, opened: false }));
          setIsButtonsBlocked(false);
        },
      });
      return;
    }
    spmsServiceService
      .submitForApprovalPurchaseOrder(requisitionId)
      .then((r) => {
        setToast({
          opened: true,
          message: 'Requisition submitted for approval successfully',
          type: 'success',
          cb: () => {
            navigate('/requisitions');
            setIsButtonsBlocked(false);
          },
        });
      })
      .catch((reason) => {
        setToast({
          opened: true,
          message:
            reason.response.data.message !== undefined
              ? reason.response.data.message
              : reason.response.data.error,
          type: 'fail',
          cb: () => {
            setIsButtonsBlocked(false);
          },
        });
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        spmsServiceService
          .getTaxList(activeCompany?.id)
          .then((r) => {
            const temp = r.data.data
              .filter((item) => item.active === true)
              .map((item) => ({
                value: { percentage: item.percentage, id: item.id },
                type: item.type,
              }));
            temp.splice(0, 0, {
              value: { id: null, percentage: 0 },
              type: 'No tax',
            });
            setTaxes(temp);
          })
          .catch((err) => {
            console.log(err);
          }),
        spmsServiceService
          .getVendorCategories()
          .then((res) => {
            const categories = res.data.data;
            if (!res?.data?.data?.length) {
              setVendorCategories([]);
              return;
            }
            setVendorCategories(categories);
          })
          .catch((err) => {
            setVendorCategories([]);
          }),
      ]);
    };
    if (activeCompany !== null) {
      fetchData();
    }
  }, [activeCompany]);

  useEffect(() => {
    const fetchRequisition = async () => {
      await spmsServiceService.getPurchaseOrder(requisitionId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          const {
            deliveryDate,
            department,
            location,
            company,
            currency,
            purchaseOrderItems,
            vendor,
            requester,
            subCategory,
            budget,
            ...other
          } = r.data.data;

          let editRequisitionItems;
          if (purchaseOrderItems !== undefined) {
            if (purchaseOrderItems.length > 0) {
              editRequisitionItems = purchaseOrderItems.map((item) => ({
                ...item,
                generalLedgerId: {
                  label: item.glAccount.glCode,
                  value: item.glAccount.id,
                  text: item.glAccount.description,
                },
                unitType: {
                  label: item.uom,
                  value: item.uom,
                },
                taxId: taxes?.filter((el) => el.value.id === (item?.tax?.id || null))[0],
              }));
            }
          }
          const reqSubCategory = vendorCategories.find(
            (cat) => cat.subCategory === vendor.subCategory,
          );
          isApprover.current =
            r.data.data.approval?.approvers?.filter((item) => item.userId === user.id).length ?? 0;
          isCurrentUserApproved.current =
            r.data.data.approval?.approvers?.find((item) => item.userId === user.id)?.status ===
              'APPROVED' ?? false;
          const editValues = {
            ...other,
            companyId: { label: company.name, value: company.id },
            locationId: { label: location.name, value: location.id },
            departmentId: { label: department.name, value: department.id },
            requester: {
              label: requester.name,
              value: requester.email,
              userId: requester.userId,
            },
            budget: budget ? { label: budget.name, value: budget.id } : undefined,
            currency: currency,
            vendorId: { label: vendor.legalName, value: vendor.id, currency: currency.code },
            purchaseOrderItems: editRequisitionItems,
            deliveryDate:
              deliveryDate !== undefined ? moment(deliveryDate, 'DD/MM/YYYY').toDate() : '',
            category: { label: reqSubCategory.category, value: reqSubCategory.category },
            subcategory: { label: reqSubCategory.subCategory, value: reqSubCategory.subCategory },
          };
          setValues(editValues);
        }
      });
    };
    if (requisitionId && !!vendorCategories.length) {
      fetchRequisition();
    }
  }, [requisitionId, taxes, vendorCategories, trigger]);

  const handleDate = (createdAt) => {
    const date = new Date(createdAt);
    const diff = new Date() - date;
    const daysGone = diff / (1000 * 60 * 60 * 24);
    if (daysGone >= 1) return `${Math.round(daysGone)} day${daysGone > 1 && 's'} ago`;
    const hoursGone = diff / (1000 * 60 * 60);
    if (hoursGone >= 1) return `${Math.round(hoursGone)} hour${hoursGone > 1 && 's'} ago`;
    const minutesGone = diff / (1000 * 60);
    if (minutesGone >= 1) return `${Math.round(minutesGone)} minut${minutesGone > 1 && 'es'} ago`;
    return 'just now';
  };

  const isApproval =
    values?.purchaseOrderState === 'PENDING_APPROVAL' &&
    accessRequisition?.approve &&
    location?.state?.canApprove &&
    isApprover.current > 0 &&
    !isCurrentUserApproved.current;

  return (
    <>
      <Box $mobExtend $asHolder>
        <div className={generalStyles.top}>
          <div>
            <BackButton />
          </div>
          <div className={generalStyles.title}>
            <div className={generalStyles.titleText}>
              <Text type="subtitle" weight={500}>
                {`${values?.title} (${values?.requestNo})`}
              </Text>
              <Text type="body-1" weight={500}>
                {`${values?.vendorId?.label} - ${totalCost} ${values?.currency?.code} `}
              </Text>
            </div>
            <div className={styles.statusContainer}>
              {values && <StatusBar data={values} module="request" $marginTop />}

              <div className={generalStyles.addItemButton}>
                {!!accessRequisition?.create &&
                (values?.purchaseOrderState === 'DRAFT' ||
                  values?.purchaseOrderState === 'REJECTED') ? (
                  <>
                    <Link to={`/requisitions/edit/${requisitionId}`}>
                      <Button.Main $mid $style="blue" type="button" disabled={isButtonsBlocked}>
                        Edit
                      </Button.Main>
                    </Link>
                    <Button.Main
                      disabled={isButtonsBlocked}
                      $mid
                      $style="blue"
                      type="submit"
                      onClick={submitForApproval}
                    >
                      Submit For Approval
                    </Button.Main>
                  </>
                ) : null}
                {isApproval && (
                  <>
                    <Button.Main
                      $mid
                      $style="green"
                      type="submit"
                      form="requisitionForm"
                      disabled={isButtonsBlocked}
                      onClick={handleSubmit((data) => onSubmit(data, 'approved'))}
                    >
                      Approve
                    </Button.Main>
                    <Button.Main
                      $mid
                      $style="red"
                      type="submit"
                      form="requisitionForm"
                      disabled={isButtonsBlocked}
                      onClick={handleSubmit((data) => onSubmit(data, 'rejected'))}
                    >
                      Deny
                    </Button.Main>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`${generalStyles.tabSection} ${generalStyles.underline}`}>
          <TabsSlider selected={tab}>
            <span onClick={() => setTab('overview')}>Overview</span>
            <span onClick={() => setTab('notes')}>Notes</span>
            <span onClick={() => setTab('documents')}>Documents</span>
            {values?.requisitionState === 'PENDING_APPROVAL' && (
              <span onClick={() => setTab('approval')}>Approval</span>
            )}
            <span onClick={() => setTab('history')}>History</span>
          </TabsSlider>
        </div>
        <br />
        {tab === 'overview' && values && (
          <>
            {isApproval ? (
              <div className={generalStyles.fieldsThree}>
                <div className="inp-container">
                  <Label
                    $title="Requester"
                    $isRequired
                    $tooltipText="The user you are raising the requisition on behalf of"
                  />
                  <Controller
                    name="requester"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Requester is required',
                      },
                    }}
                    render={({ field: { ref, ...rest } }) => (
                      <SelectUsers
                        {...rest}
                        isDisabled
                        selectRef={ref}
                        className={
                          errors.hasOwnProperty(rest.name)
                            ? 'react-select-container error'
                            : 'react-select-container'
                        }
                        isClearable={true}
                      />
                    )}
                  />
                </div>
                <div className="inp-container">
                  <Label
                    $title="Department"
                    $tooltipText="Department requesting the items"
                    $isRequired
                  />
                  <Controller
                    name="departmentId"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Department is required',
                      },
                    }}
                    render={({ field: { ref, ...rest } }) => (
                      <SelectDepartments
                        {...rest}
                        isDisabled
                        selectRef={ref}
                        className={
                          errors.hasOwnProperty(rest.name)
                            ? 'react-select-container error'
                            : 'react-select-container'
                        }
                      />
                    )}
                  />
                </div>
                <div className="inp-container">
                  <Label
                    $title="Location"
                    $tooltipText="Physical location items will be delivered to"
                    $isRequired
                  />
                  <Controller
                    name="locationId"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Location is required',
                      },
                    }}
                    render={({ field: { ref, ...rest } }) => (
                      <SelectLocations
                        {...rest}
                        isDisabled
                        selectRef={ref}
                        className={
                          errors.hasOwnProperty(rest.name)
                            ? 'react-select-container error'
                            : 'react-select-container'
                        }
                      />
                    )}
                  />
                </div>
                <div className="inp-container">
                  <Label $isRequired $title="Category" />
                  <Controller
                    name="category"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={
                          errors.hasOwnProperty(field.name)
                            ? 'react-select-container error'
                            : 'react-select-container'
                        }
                        classNamePrefix="react-select"
                        isSearchable={false}
                        placeholder="Select Category"
                        options={Array.from(
                          new Set(vendorCategories.map((cat) => cat.category)),
                        ).map((category) => ({
                          label: category,
                          value: category,
                        }))}
                        onChange={(e) => {
                          field.onChange(e);
                          setValue('subcategory', null);
                          setValue('vendorId', null);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="inp-container">
                  <Label $isRequired $title="Sub Category" />
                  <Controller
                    name="subcategory"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        className={
                          errors.hasOwnProperty(field.name)
                            ? 'react-select-container error'
                            : 'react-select-container'
                        }
                        classNamePrefix="react-select"
                        isDisabled={!categoryWatcher?.value}
                        isSearchable={false}
                        placeholder="Select Sub Category"
                        options={vendorCategories
                          .filter((category) => category.category === categoryWatcher?.value)
                          .map((subcat) => ({
                            label: subcat.subCategory,
                            value: subcat.subCategory,
                          }))}
                      />
                    )}
                  />
                </div>
                <div className="inp-container">
                  <Label
                    $title="Prefered Vendor"
                    $tooltipText="Preferred vendor to supply items"
                    $isRequired
                  />
                  <Controller
                    name="vendorId"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Vendor is required',
                      },
                    }}
                    render={({ field: { ref, ...rest } }) => (
                      <SelectVendors
                        {...rest}
                        selectRef={ref}
                        isDisabled={!categoryWatcher?.value || !subCategoryWatcher?.value}
                        extraFilters={{
                          category: categoryWatcher?.value,
                          subCategory: subCategoryWatcher?.value,
                        }}
                        className={
                          errors.hasOwnProperty(rest.name)
                            ? 'react-select-container error'
                            : 'react-select-container'
                        }
                      />
                    )}
                  />
                </div>
                <div className="inp-container">
                  <Controller
                    name="title"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Name is required',
                      },
                      maxLength: {
                        value: 50,
                        message: 'Maximum 50 characters',
                      },
                      validate: {
                        onlyAllowed: (v) =>
                          regExps.poRequisitionsTitle.test(v) ||
                          'Alphabetic, numeric, &().- characters and space only',
                        onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
                      },
                    }}
                    render={({ field }) => (
                      <Input
                        disabled
                        type="text"
                        placeholder="Enter Description"
                        className={errors.hasOwnProperty(field.name) && 'error'}
                        $label="Description"
                        $labelRequired
                        $tooltip="Descriptive title for requisition"
                        {...field}
                      />
                    )}
                  />
                </div>
                <div className="inp-container">
                  <Controller
                    name="deliveryDate"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Delivery Date is required',
                      },
                      maxLength: {
                        value: 10,
                        message: 'Maximum 10 characters',
                      },
                    }}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        placeholderText="Select Date"
                        selected={field.value}
                        wrapperClassName="custom-datepicker"
                        disabled
                        customInput={
                          <Input
                            {...field}
                            $label="Required By"
                            $labelRequired
                            className={errors.hasOwnProperty(field.name) && 'error'}
                            $tooltip="Desired delivery date of items"
                          />
                        }
                      />
                    )}
                  />
                </div>
                <div className="inp-container">
                  <Label
                    $title="Budget"
                    $tooltipText="Physical location items will be delivered to"
                    $isRequired
                  />
                  <Controller
                    name="budget"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Budget is required',
                      },
                    }}
                    render={({ field: { ref, ...rest } }) => (
                      <SelectBudgets
                        {...rest}
                        selectRef={ref}
                        className={
                          errors.hasOwnProperty(rest.name)
                            ? 'react-select-container error'
                            : 'react-select-container'
                        }
                      />
                    )}
                  />
                </div>
              </div>
            ) : (
              <div className={stylesPo.top}>
                <div className={stylesPo.topCol}>
                  <div className={stylesPo.topRow}>
                    <Text weight={600} type="body-2">
                      Requester
                    </Text>
                    <div className={styles.requisitionValue}>
                      <Text weight={400} type="body-2">
                        {values?.requester?.label}
                      </Text>
                    </div>
                  </div>
                  <div className={stylesPo.topRow}>
                    <Text weight={600} type="body-2">
                      Category
                    </Text>
                    <div className={styles.requisitionValue}>
                      <Text weight={400} type="body-2">
                        {values?.category.label}
                      </Text>
                    </div>
                  </div>
                  <div className={stylesPo.topRow}>
                    <Text weight={600} type="body-2">
                      Description
                    </Text>
                    <div className={styles.requisitionValue}>
                      <Text weight={400} type="body-2">
                        {values.title}
                      </Text>
                    </div>
                  </div>
                </div>
                <div className={stylesPo.topCol}>
                  <div className={stylesPo.topRow}>
                    <Text weight={600} type="body-2">
                      Location
                    </Text>
                    <div className={styles.requisitionValue}>
                      <Text weight={500} type="body-2">
                        {values?.locationId?.label}
                      </Text>
                    </div>
                  </div>

                  <div className={stylesPo.topRow}>
                    <Text weight={600} type="body-2">
                      Sub Category
                    </Text>
                    <div className={styles.requisitionValue}>
                      <Text weight={500} type="body-2">
                        {values?.subcategory.label}
                      </Text>
                    </div>
                  </div>
                  <div className={stylesPo.topRow}>
                    <Text weight={600} type="body-2">
                      Required By
                    </Text>
                    <div className={styles.requisitionValue}>
                      <Text weight={500} type="body-2">
                        {moment(values?.deliveryDate).format('DD/MM/YYYY')}
                      </Text>
                    </div>
                  </div>
                </div>
                <div className={stylesPo.topCol}>
                  <div className={stylesPo.topRow}>
                    <Text weight={600} type="body-2">
                      Department
                    </Text>
                    <div className={styles.requisitionValue}>
                      <Text weight={500} type="body-2">
                        {values?.departmentId?.label}
                      </Text>
                    </div>
                  </div>
                  <div className={stylesPo.topRow}>
                    <Text weight={600} type="body-2">
                      Vendor
                    </Text>
                    <div className={styles.requisitionValue}>
                      <Text weight={500} type="body-2">
                        {values?.vendorId?.label}
                      </Text>
                    </div>
                  </div>
                  <div className={stylesPo.topRow}>
                    <Text weight={600} type="body-2">
                      Budget
                    </Text>
                    <div className={styles.requisitionValue}>
                      <Text weight={500} type="body-2">
                        {values?.budget?.label}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <br />
            <br />

            <div className={styles.itemsTable}>
              <div
                className={
                  isApproval
                    ? styles.itemsTableHead + ' ' + styles.itemsTableHeadReview
                    : styles.itemsTableHead
                }
              >
                {isApproval ? <div /> : null}
                <Text type="body-2" weight={500}>
                  Item Name
                </Text>
                <Text type="body-2" weight={500}>
                  Vendor Code
                </Text>
                <Text type="body-2" weight={500}>
                  Unit Of Measure
                </Text>
                <Text type="body-2" weight={500}>
                  Account
                </Text>
                <Text type="body-2" weight={500}>
                  Quantity
                </Text>
                <Text type="body-2" weight={500}>
                  Unit Price
                </Text>
                <Text type="body-2" weight={500}>
                  Tax
                </Text>
                <Text type="body-2" weight={500}>
                  Subtotal
                </Text>
              </div>
              <div className={styles.tableBody}>
                {fields.map((field, index) => {
                  return isApproval ? (
                    <Item
                      key={field.id}
                      field={field}
                      index={index}
                      register={register}
                      errors={errors}
                      remove={remove}
                      append={append}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      taxes={taxes}
                      name="purchaseOrderItems"
                      isApproval={isApproval}
                    />
                  ) : (
                    <GridItem
                      key={field.id}
                      data={field}
                      id={field.id}
                      currency={values?.budget?.currency}
                      exchangeRate={values?.currency?.rate}
                      budget={values?.budget}
                      showBudget={
                        accessRequisition?.approve &&
                        location?.state?.canApprove &&
                        isApprover.current > 0
                      }
                    />
                  );
                })}
              </div>
            </div>
            <div className={stylesPo.summaryWrapper}>
              <Summary
                subtotal={subtotal}
                taxTotal={taxTotal}
                totalCost={totalCost}
                currency={values?.currency?.code}
                currencyBudget={defaultCurrency}
              />
            </div>
          </>
        )}
        {tab === 'notes' && values && (
          <CommentsTab
            notes={values.notes}
            setIsButtonsBlocked={setIsButtonsBlocked}
            isButtonsBlocked={isButtonsBlocked}
            refetch={() => setTrigger((trigger) => !trigger)}
            setToast={setToast}
            module="request"
          />
        )}
        {tab === 'documents' && <QuotesTab data={values?.attachments} />}
        {tab === 'history' && <RequisitionHistory handleDate={handleDate} fetchHistory={getData} />}
        {tab === 'approval' && <ApprovalTab data={values?.approval?.approvers} />}
      </Box>
      <div className={generalStyles.pageButtons}></div>
      {!!toast.opened && (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      )}
    </>
  );
};

export default RequisitionView;
