import Text from '../../components/UI/Typography/Text';
import React, { useState, useEffect, useMemo, useCallback, Suspense, useLayoutEffect } from 'react';
import styles from '../../styles/vendor.module.css';
import generalStyles from '../../styles/general.module.css';
import Box from '../../components/UI/General/Box';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import TabsSlider from '../../components/UI/General/TabsSlider';
import AddButton from '../../components/shared/AddButton';
import Search from '../../components/UI/General/Search';
import spmsServiceService from '../../services/spmsService.service';
import { Link, useNavigate } from 'react-router-dom';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import { useStore } from '../../store/store';
import Filter from '../../components/UI/General/Filter.jsx';
import { useForm, useWatch } from 'react-hook-form';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend.js';

const CompanyVendorSetup = () => {
  const activeCompany = useStore((state) => state.activeCompany);
  const user = useStore((state) => state.user);
  const [filterText, setFilterText] = useState('');
  const [tab, setTab] = useState('approved');
  const accessVendor = useAccessAllowed('Vendor_Management');
  const accessPurchaser = useAccessAllowed('Purchase_Orders');
  const [refetchTrigger, setRefetchTrigger] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState(filterText);
  const [vendorCategories, setVendorCategories] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (location?.state?.tab) {
      setTab(location?.state?.tab);
    }
  }, [location]);

  const { control, setValue, getValues } = useForm({
    mode: 'onChange',
  });
  const categoryWatcher = useWatch({ control, name: 'category' });
  useEffect(() => {
    spmsServiceService.getVendorCategories().then((res) => {
      const categories = res.data.data;
      setVendorCategories(categories);
    });
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(filterText);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  const getData = useCallback(
    async (page, size) => {
      if (!accessVendor) return;
      const filterValues = getValues();
      const requestBody = {
        vendorName: debouncedValue,
        category: filterValues.category?.value,
        vendorStatus: tab === 'new requests' ? 'NEW' : tab.toUpperCase(),
        subCategory: filterValues.subCategory?.value,
      };
      if (!accessVendor.viewAllCompany) {
        requestBody.createdByUserId = user.id;
      }
      return await spmsServiceService.getVendors(activeCompany.id, requestBody, page, size);
    },
    [debouncedValue, refetchTrigger, tab, activeCompany, accessVendor],
  );
  const filterData = [
    {
      name: 'tier',
      label: 'Tier',
      isMulti: false,
      options: [
        { label: 'Tier 1 - Strategic', value: 'STRATEGIC', description: 'Preferred Vendors' },
        { label: 'Tier 2 - Operational ', value: 'OPERATIONAL', description: 'Secondary Vendors' },
        { label: 'Tier 3 - Transactional', value: 'TRANSACTIONAL', description: 'Support Vendors' },
        { label: 'Tier 4 - Non-Critical', value: 'NON-CRITICAL', description: 'Low-Risk Vendors' },
      ],
    },
    {
      name: 'category',
      label: 'Category',
      options: Array.from(new Set(vendorCategories.map((cat) => cat.category))).map((category) => ({
        label: category,
        value: category,
      })),
      isDisabled: !vendorCategories.length,
    },
    {
      name: 'subCategory',
      label: 'Subcategory',
      options: vendorCategories
        .filter((category) => category.category === categoryWatcher?.value)
        .map((subcat) => ({
          label: subcat.subCategory,
          value: subcat.subCategory,
        })),
      isDisabled: !categoryWatcher?.value,
    },
  ];

  const columns = [
    {
      name: 'Code',
      sortable: true,
      selector: (row) => row.vendorCode,
      omit: tab !== 'approved',
      cell: (row) => (
        <span data-content={'Code'} className={generalStyles.tableValue}>
          <Link to={`/vendors/${activeCompany?.id}/viewActive/${row.vendor.id}`} weight={500}>
            <span>{row.vendorCode ?? 'NO VENDOR CODE'}</span>
          </Link>
        </span>
      ),
    },
    {
      name: 'Name',
      sortable: true,
      selector: (row) => row.vendor.legalName,
      cell: (row) =>
        tab === 'new requests' ? (
          <span data-content={'Name'} className={generalStyles.tableValue}>
            <Link to={`/vendors/${activeCompany?.id}/in-review/${row.vendor.id}`} weight={500}>
              <span>{row.vendor.legalName}</span>
            </Link>
          </span>
        ) : (
          <span data-content={'Name'}>
            <span>{row.vendor.legalName}</span>
          </span>
        ),
    },
    {
      name: 'Tier',
      sortable: true,
      selector: (row) => row.vendor.legalName,
      omit: tab !== 'approved',
      cell: (row) => (
        <span data-content={'Tier'}>
          <span>{readableTitleFromBackend(row.tier)}</span>
        </span>
      ),
    },
    {
      name: 'Category',
      sortable: true,
      selector: (row) => row.vendor.category,
      cell: (row) => (
        <span data-content={'Category'}>
          <span>{row.vendor.category}</span>
        </span>
      ),
    },
    {
      name: 'Subcategory',
      sortable: true,
      selector: (row) => row.vendor.subCategory,
      cell: (row) => (
        <span data-content={'Subcategory'}>
          <span>{row.vendor.subCategory}</span>
        </span>
      ),
    },
    {
      name: 'Requester',
      sortable: true,
      omit: tab === 'approved',
      selector: (row) => row.requester.name,
      cell: (row) => (
        <span data-content={'Requester'}>
          <span>{row?.requester?.name}</span>
        </span>
      ),
    },
    {
      name: 'Department',
      sortable: true,
      selector: (row) => row.departments,
      cell: (row) => (
        <span data-content={'Department'}>
          <span>{row?.departments[0]?.name}</span>
        </span>
      ),
    },
  ];

  const filterCmp = useMemo(
    () => (
      <Search
        type="text"
        $iconName="search"
        $iconColor="#fff"
        placeholder="Search Vendor by Code / Name"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText, tab],
  );

  return (
    <>
      <Box $radius={12} $mobExtend $asHolder $noOverflow>
        <div className={styles.title}>
          <Text type="subtitle" weight={500}>
            Preferred Vendors
          </Text>
        </div>
        {accessPurchaser?.edit && accessPurchaser?.create && (
          <div className={generalStyles.underline}>
            <TabsSlider selected={tab}>
              <span onClick={() => setTab('approved')}>Approved</span>
              <span onClick={() => setTab('new requests')}>New Requests</span>
              <span onClick={() => setTab('invited')}>Invited</span>
              <span onClick={() => setTab('denied')}>Denied</span>
            </TabsSlider>
          </div>
        )}
        {!activeCompany ? (
          <h2 className={styles.loader}>Loading...</h2>
        ) : (
          <>
            <div className={generalStyles.tabSection}>
              <div className={styles.search}>
                {filterCmp}
                <div className={generalStyles.filters}>
                  <Filter
                    control={control}
                    filterData={filterData}
                    setTriggerRefetch={setRefetchTrigger}
                    setValue={setValue}
                  />
                </div>
              </div>
              {accessVendor?.create && (
                <div className={generalStyles.addItemButton}>
                  <AddButton
                    $text="Request New"
                    $click={() => navigate('/vendors/' + activeCompany.id + '/create')}
                  />
                </div>
              )}
            </div>
            <Suspense fallback={<h2 className={styles.loader}>Loading...</h2>}>
              <DataTableBaseRemote
                columns={columns}
                fetchData={getData}
                selectableRows={false}
                paginationResetDefaultPage={false}
              />
            </Suspense>
          </>
        )}
      </Box>
    </>
  );
};

export default CompanyVendorSetup;
