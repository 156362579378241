import { useForm } from 'react-hook-form';
import styles from '../../styles/onboarding.module.css';
import generalStyles from '../../styles/general.module.css';
import Text from '../../components/UI/Typography/Text.jsx';
import * as Button from '../../components/UI/Forms/Button.jsx';
import GradientText from '../../components/UI/General/GradientText.jsx';
import LocationAutocomplete from '../../components/shared/LocationAutocomplete.jsx';
import spmsServiceService from '../../services/spmsService.service.js';
import { useStore } from '../../store/store.js';
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { useNavigate, useParams } from 'react-router-dom';

const LocationOnboarding = ({ nextStep, previousStep, setToast }) => {
  const companyId = useStore((state) => state.company?.id);
  const steps = useStore(useShallow((state) => state.steps));
  const setSteps = useStore((state) => state.setSteps);
  const [locationId, setLocationId] = useState(null);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const { companyId: paramsCompanyId } = useParams();
  const stepsDone = useStore((state) => state.stepsDone);
  const navigate = useNavigate();
  const selectedCompanyId = paramsCompanyId
    ? paramsCompanyId
    : !paramsCompanyId && stepsDone
    ? null
    : companyId;
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    setValue,
    trigger,
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    const locationStep = steps.find((step) => step.type === 'location');
    if (!locationStep.done) return;
    spmsServiceService.getLocations(selectedCompanyId).then((res) => {
      const location = res.data.data.content[0];
      if (location) {
        setLocationId(location.id);
        setValue('address', location.address);
        setValue('city', location.city);
        setValue('street', location.street);
        setValue('country', location.country);
        setValue('state', location.state);
        setValue('postalCode', location.postalCode);
      }
    });
  }, [selectedCompanyId]);

  let count = 0;

  const onSubmit = (data) => {
    if (!isDirty) {
      nextStep();
      return;
    }
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    let requestBody = {
      ...data,
      locationType: 'PRIMARY',
    };
    if (locationId) {
      requestBody = { ...requestBody, id: locationId };
      spmsServiceService
        .updateLocation(selectedCompanyId, requestBody)
        .then((res) => {
          if (!stepsDone) {
            count++;
            if (count === 1) {
              window.dataLayer.push({
                event: 'create_account_location',
                Location: {
                  address: requestBody.address, // Full address
                  street: requestBody.street, // Street number
                  city: requestBody.city, // City Name
                  country: requestBody.country, // Country
                },
              });
              console.log(window.dataLayer);
            }
          }
          setToast({
            opened: true,
            message: 'Saved',
            type: 'success',
            cb: () => {
              setIsButtonsBlocked(false);
              nextStep();
            },
          });
        })
        .catch((err) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: err.response.data?.errors[0]?.message ?? err.response.data.message,
            type: 'fail',
          });
        });
    } else
      spmsServiceService
        .createLocation(selectedCompanyId, requestBody)
        .then((res) => {
          if (!stepsDone) {
            count++;
            if (count === 1) {
              window.dataLayer.push({
                event: 'create_account_location',
                Location: {
                  address: requestBody.address, // Full address
                  street: requestBody.street, // Street number
                  city: requestBody.city, // City Name
                  country: requestBody.country, // Country
                },
              });
              console.log(window.dataLayer);
            }
            setSteps();
          }
          setToast({
            opened: true,
            message: 'Location created',
            type: 'success',
            cb: () => {
              setIsButtonsBlocked(false);
              nextStep();
            },
          });
        })
        .catch((err) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: err.response.data?.errors[0]?.message ?? err.response.data.message,
            type: 'fail',
          });
        });
  };

  return (
    <div className={styles.onboardForm}>
      <div className={styles.formHeading}>
        <Text type="h2" weight={600}>
          Location
        </Text>
        <GradientText $from="#313439b2" $to="#3778ffb2">
          <Text type="h5" weight={600}>
            Add information about your location
          </Text>
        </GradientText>
      </div>
      <div className={styles.inputs}>
        <LocationAutocomplete
          control={control}
          errors={errors}
          setValue={setValue}
          preOpened
          trigger={trigger}
        />
        <div className={generalStyles.pageButtons}>
          <Button.Main
            $primary
            $full
            $style="blue"
            $iconRight
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || isButtonsBlocked}
          >
            {locationId && !isDirty ? 'Continue' : 'Save & Continue'}
          </Button.Main>
          {stepsDone && (
            <Button.Main
              $primary
              $full
              $style="gray"
              $iconRight
              type="submit"
              onClick={() => navigate('/dashboard')}
            >
              Discard
            </Button.Main>
          )}
        </div>
      </div>
      {/* <div className={styles.formBackButton}>
        <Button.ActionLabeled $primary $full $style="blue" type="button" onClick={previousStep}>
          <Text type="body-1" weight={500}>
            Back to previous step
          </Text>
        </Button.ActionLabeled>
      </div> */}
    </div>
  );
};

export default LocationOnboarding;
