import React, { useEffect, useRef, useState } from 'react';
import * as Button from '../../../components/UI/Forms/Button';
import Box from '../../../components/UI/General/Box';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../../components/UI/Forms/Input';
import styles from '../../../styles/companySetup.module.css';
import generalStyles from '../../../styles/general.module.css';
import Label from '../../../components/UI/Forms/Label';
import Select from 'react-select';
import Text from '../../../components/UI/Typography/Text';
import spmsServiceService from '../../../services/spmsService.service';
import Toast from '../../../components/UI/General/Toast';
import { useNavigate } from 'react-router-dom';
import ModalDiscard from '../../../components/shared/ModalDiscard';
import { useStore } from '../../../store/store';

let financialTypes = [
  {
    label: 'Income Statement',
    options: [
      { label: 'Income', value: 'Income' },
      { label: 'Expenses', value: 'Expenses' },
    ],
  },
  {
    label: 'Balance Sheet',
    options: [
      { label: 'Bank', value: 'Bank' },
      { label: 'Fixed Assets', value: 'Fixed Assets' },
      { label: 'Current Assets', value: 'Current Assets' },
      { label: 'Non-Current Assets', value: 'Non-Current Assets' },
      { label: 'Current Liabilities', value: 'Current Liabilities' },
      { label: 'Non-Current Liabilities', value: 'Non-Current Liabilities' },
      { label: 'Equity', value: 'Equity' },
    ],
  },
  {
    label: 'Projects',
    options: [
      { label: 'Projects 1', value: 'Projects1' },
      { label: 'Projects 2', value: 'Projects2' },
    ],
  },
];

const GlAdd = () => {
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      glCode: "",
      description: "",
    }
  });

  const setIsDiscarded = useStore((state) => state.setIsDiscarded);
  // const setFullCompanies = useStore((state) => state.setFullCompanies);
  const { glId, companyId } = useParams();
  const containerRef = useRef(null);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (glId) {
      spmsServiceService.getGlAccount(glId).then((r) => {
        if (r.data.status === 'success') {
          const { glCode, subType, description } = r.data.data;
          // const typeUpd = financialTypes
          //   .flatMap((item) => item.options)
          //   .find((el) => el.value === type);
          reset({
            glCode,
            subType,
            description,
            // type: typeUpd,
          });
        }
      });
    }
  }, [glId]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView(true);
    }
  }, [containerRef.current]);

  const onSubmit = (data) => {
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    // const { type, ...other } = data;
    const newData = {
      ...data,
      // type: type.value,
      companyId: companyId,
    };
    if (glId) {
      spmsServiceService
        .updateGlAccount(glId, newData)
        .then((r) => {
          setIsDiscarded(false)
          setToast({
            opened: true,
            message: 'GL updated successfully',
            type: 'success',
            cb: () => navigate('/gl-setup'),
          });
        })
        .catch((err) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
        });
    } else {
      spmsServiceService
        .createGlAccount(newData)
        .then((r) => {
          setIsDiscarded(false)
          setToast({
            opened: true,
            message: 'GL created successfully',
            type: 'success',
            cb: () => navigate('/gl-setup')
          });
        })
        .catch((err) => {
          setIsButtonsBlocked(false);
          setToast({
            opened: true,
            message: err.response.data.message,
            type: 'fail',
          });
        });
    }
  };

  const formatGroupLabel = (data) => (
    <Text type="body-1" weight={600}>
      {data.label}
    </Text>
  );

  return (
    <Box $noOverflow ref={containerRef} $mobExtend $asHolder $radius={12}>
      <div className={styles.title}>
        <Text weight={500} type="subtitle">
          {!glId ? 'Add' : 'Edit'} Account Code
        </Text>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id="glForm">
        <div className={generalStyles.fieldsThree}>
          <div className="inp-container">
            <Controller
              name="glCode"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Code is required',
                },
                maxLength: {
                  value: 65,
                  message: 'Maximum 65 characters',
                },
                validate: {
                  notAllowed: (v) => !/[!@#$%&?*(){}<>]/.test(v) || 'Not allowed symbols',
                  allowed: (v) =>
                    /^[A-Za-z0-9-\s/]*$/.test(v) || 'Only Alpha and Numerical characters',
                },
              }}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter Code"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  $labelRequired
                  $label="Code"
                  $tooltip="General Ledger number"
                  {...field}
                />
              )}
            />
            {errors.glCode && <p className="error-message">{errors.glCode.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="description"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Description is required',
                },
                maxLength: {
                  value: 65,
                  message: 'Maximum 65 characters',
                },
                validate: {
                  notAllowed: (v) => !/[!@#$%&?*(){}<>]/.test(v) || 'Not allowed symbols',
                  allowed: (v) =>
                    (/^[A-Za-z0-9-/\s]*$/.test(v) && v?.trim() !== '') ||
                    'Only Alpha and Numerical characters',
                },
              }}
              render={({ field }) => (
                <Input
                  type="text"
                  placeholder="Enter Description"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  $label="Description"
                  $labelRequired
                  $tooltip="Description of the General Ledger"
                  {...field}
                />
              )}
            />
            {errors.description && <p className="error-message">{errors.description.message}</p>}
          </div>
          {/* <div className="inp-container">
            <Label $title="Category" $isRequired $tooltipText="Category of the General Ledger" />
            <Controller
              name="type"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Category is required',
                },
              }}
              render={({ field }) => (
                <Select
                  {...field}
                  className={
                    errors.hasOwnProperty(field.name)
                      ? 'react-select-container error'
                      : 'react-select-container'
                  }
                  classNamePrefix="react-select"
                  isSearchable
                  placeholder="Select Category"
                  options={financialTypes}
                  formatGroupLabel={formatGroupLabel}
                  styles={{
                    group: (base) => ({ ...base, padding: 0 }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: '100 !important',
                    }),
                    groupHeading: () => ({ padding: '4px 12px' }),
                  }}
                  hideSelectedOptions={false}
                />
              )}
            />
            {errors.type && <p className="error-message">{errors.type.message}</p>}
          </div> */}
        </div>
      </form>
      <div className={styles.backOverviewButton}>
        <div className={generalStyles.pageButtons}>
          <Button.Main
            $primary
            $style={'blue'}
            type="submit"
            form="glForm"
            disabled={!isDirty || !isValid || isButtonsBlocked}
          >
            Save
          </Button.Main>
          <Button.Main
            $primary
            $style={'gray'}
            onClick={() => isDirty ? setShowModal(true) : navigate('/gl-setup')}
          >
            Discard
          </Button.Main>
        </div>
      </div>

      <ModalDiscard setShowModal={setShowModal} showModal={showModal} navigateTo={'/gl-setup'} condition={isDirty}/>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </Box>
  );
};

export default GlAdd;
