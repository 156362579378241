import React, { useState, Suspense, useRef, useCallback } from 'react';
import generalStyles from '../../styles/general.module.css';
import { useEffect } from 'react';
import spmsServiceService from '../../services/spmsService.service';
import Toast from '../../components/UI/General/Toast';
import Box from '../../components/UI/General/Box';
import BackButton from '../../components/shared/BackButton';
import Select from 'react-select';
import Input from '../../components/UI/Forms/Input';
import Label from '../../components/UI/Forms/Label';
import formStyles from '../../styles/companySetup.module.css';
import vendorStyles from '../../styles/vendor.module.css';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Textarea from '../../components/UI/Forms/Textarea';
import * as Button from '../../components/UI/Forms/Button';
import Documents from '../../components/UI/Forms/Documents';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import Text from '../../components/UI/Typography/Text';
import SelectUsers from '../../components/shared/SelectUsers';
import SelectLocations from '../../components/shared/SelectLocations';
import SelectDepartments from '../../components/shared/SelectDepartments';
import { regExps } from '../../utils/regExps';
import Radio from '../../components/UI/Forms/Radio';
import DataTableBase from '../../components/UI/General/DataTableBase';
import * as yup from 'yup';
import ModalDiscard from '../../components/shared/ModalDiscard';
import { useStore } from '../../store/store';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend';

const VendorSetup = () => {
  const { companyId, vendorId, mode } = useParams();
  // const [formValues, setFormValues] = useState();
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [vendorCategories, setVendorCategories] = useState([]);
  const navigate = useNavigate();
  const [vendorSetupData, setVendorSetupData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const setIsDiscarded = useStore((state) => state.setIsDiscarded);
  const user = useStore((state) => state.user);

  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const isEditingDisabled =
    vendorSetupData?.vendorStatus === 'APPROVED' ||
    mode === 'add' ||
    vendorSetupData?.companiesAssigned > 1 ||
    vendorSetupData?.previouslyApproved;
  const accessVendor = useAccessAllowed('Vendor_Management');

  const vendorSchema = yup.object().shape({
    requester: yup.object().shape({
      value: yup.string().required('Requester is required'),
    }),
    locationId: yup.object().shape({
      value: yup.string().required('Location is required'),
    }),
    departmentId: yup.object().shape({
      value: yup.string().required('Department is required'),
    }),
    legalName: yup
      .string()
      .required('Legal name is required')
      .matches(/^[a-zA-Z0-9@&()-\s]+$/, 'Invalid characters')
      .test('no-empty-spaces', 'Should not be empty', (value) => value?.trim() !== '')
      .max(50, '50 characters max'),
    purchaseDescription: yup
      .string()
      .required('Products are required')
      .matches(regExps.notes, 'Invalid characters')
      .max(50, '50 characters max'),
    benefitsFromVendor: yup
      .string()
      .required('Benefites are required')
      .matches(regExps.notes, 'Invalid characters')
      .max(50, '50 characters max'),
    websiteUrl: yup
      .string()
      .transform((value, originalValue) => (originalValue === '' ? undefined : value))
      .test(
        'no-empty-spaces',
        'Website URL cannot be just empty spaces',
        (value) => value?.trim() !== '',
      )
      .matches(regExps.websiteUrl, 'Invalid characters')
      .max(50, '50 characters max'),
    vendorContactName: yup
      .string()
      .required('Contact name is required')
      .matches(regExps.userName, 'Invalid characters')
      .test('no-empty-spaces', 'Should not be empty', (value) => value?.trim() !== '')
      .max(50, '50 characters max'),
    vendorContactEmail: yup
      .string()
      .required('Contact email is required')
      .matches(regExps.email, 'Invalid characters')
      .test('no-empty-spaces', 'Should not be empty', (value) => value?.trim() !== '')
      .max(50, '50 characters max'),
    registrationNo: yup
      .string()
      .transform((value, originalValue) => (originalValue === '' ? undefined : value))
      .test(
        'no-empty-spaces',
        'Registration number cannot be just empty spaces',
        (value) => value?.trim() !== '',
      )
      .matches(/^[a-zA-Z0-9/-]+$/, 'Invalid characters')
      .max(30, 'Registration Number must be at most 30 characters'),
    category: yup.object().shape({
      value: yup.string().required('Category is required'),
    }),
    subCategory: yup.object().shape({
      value: yup.string().required('Sub Category is required'),
    }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(vendorSchema),
    mode: 'onChange',
    defaultValues: {
      requester: '',
      locationId: '',
      departmentId: '',
      legalName: '',
      purchaseDescription: '',
      benefitsFromVendor: '',
      websiteUrl: '',
      vendorContactName: '',
      vendorContactEmail: '',
      registrationNo: '',
      category: '',
      subCategory: '',
      ndaSigned: 'yes',
    },
    // values: formValues,
  });
  const categoryWatcher = useWatch({ control, name: 'category' });
  const subCategoryWatcher = useWatch({ control, name: 'subCategory' });
  const ndaSigned = useWatch({ control, name: 'ndaSigned' });
  const requiredRef = useRef(false);

  const getData = useCallback(
    async (page, size) => {
      // if (!categoryWatcher?.value || !subCategoryWatcher?.value) return;
      const requestBody = {
        category: categoryWatcher?.value,
        vendorStatus: 'APPROVED',
        subCategory: subCategoryWatcher?.value,
      };

      return await spmsServiceService.getVendors(companyId, requestBody, page, size);
    },
    [categoryWatcher, subCategoryWatcher],
  );

  const columns = [
    {
      name: 'Code',
      sortable: true,
      selector: (row) => row.vendorCode,
      cell: (row) => (
        <span data-content={'Code'} className={generalStyles.tableValue}>
          <Link to={`/vendors/${companyId}/viewActive/${row.vendor.id}`} weight={500}>
            <span>{row.vendorCode}</span>
          </Link>
        </span>
      ),
    },
    {
      name: 'Name',
      sortable: true,
      selector: (row) => row.vendor.legalName,
      cell: (row) => (
        <span data-content={'Name'}>
          <span>{row.vendor.legalName}</span>
        </span>
      ),
    },
    {
      name: 'Tier',
      sortable: true,
      selector: (row) => row.vendor.legalName,
      cell: (row) => (
        <span data-content={'Tier'}>
          <span>{readableTitleFromBackend(row.tier)}</span>
        </span>
      ),
    },
    {
      name: 'Category',
      sortable: true,
      selector: (row) => row.vendor.category,
      cell: (row) => (
        <span data-content={'Category'}>
          <span>{row.vendor.category}</span>
        </span>
      ),
    },
    {
      name: 'Subcategory',
      sortable: true,
      selector: (row) => row.vendor.subCategory,
      cell: (row) => (
        <span data-content={'Subcategory'}>
          <span>{row.vendor.subCategory}</span>
        </span>
      ),
    },
  ];

  // const getVendorCompany = async (vendorId) => {
  //   spmsServiceService.getCompanyVendorById(companyId, vendorId).then((res) => {
  //     const vendorData = res.data.data;
  //     const handledVendorData = {
  //       ...vendorData.vendor,
  //       vendorCode: vendorData.vendorCode,
  //       onceOffPOLimit: vendorData.onceOffPOLimit,
  //       overallAnnualLimit: vendorData.overallAnnualLimit,
  //       vendorNotes: vendorData.vendorNotes,
  //       currency: vendorData.currency,
  //       departments: vendorData.departments,
  //       branches: vendorData.branches,
  //       glAccounts: vendorData.glAccounts,
  //       locations: vendorData.locations,
  //       users: vendorData.users ?? [],
  //       vendorStatus: vendorData.vendorStatus,
  //       vendorAttachments: vendorData.attachments,
  //       companiesAssigned: vendorData.companiesAssigned,
  //       previouslyApproved: vendorData.previouslyApproved,
  //     };
  //     setVendorSetupData(handledVendorData);
  //   });
  // };

  // useEffect(() => {
  //   if (!vendorId) return;
  //   if (mode === 'edit') {
  //     getVendorCompany(vendorId);
  //   } else if (mode === 'add') {
  //     spmsServiceService.getVendor(vendorId).then((res) => {
  //       setVendorSetupData(res.data.data);
  //     });
  //   }
  // }, [vendorId]);

  useEffect(() => {
    const userCompany = user.customCompanies.find((company) => company.companyId === companyId);
    if (userCompany) {
      setValue('requester', {
        label: user.firstName + ' ' + user.lastName,
        value: user.username,
        userId: user.id,
      });
      if (userCompany.locationId) {
        spmsServiceService
          .getLocation(userCompany.companyId, userCompany.locationId)
          .then((res) => {
            const location = res.data.data;
            setValue('locationId', { label: location.name, value: location.id });
          });
      }
      if (userCompany.departmentId) {
        spmsServiceService
          .getDepartment(userCompany.companyId, userCompany.departmentId)
          .then((res) => {
            const department = res.data;
            setValue('departmentId', { label: department.name, value: department.id });
          });
      }
    }
  }, []);

  const files = (files) =>
    files.map((file) => ({
      id: null,
      attachment: file,
      name: file.name,
      type: 'VENDOR_DOC',
    }));

  // useEffect(() => {
  //   if (!vendorSetupData) return;
  //   const vendorPreloadData = {
  //     registrationNo: vendorSetupData.registrationNo,
  //     vendorType: { label: vendorSetupData.vendorType, value: vendorSetupData.vendorType },
  //     legalName: vendorSetupData.legalName,
  //     vendorAddressLine1: vendorSetupData.address.streetAddress ?? '',
  //     vendorAddressLine2: vendorSetupData.address.addressLine2,
  //     vendorCity: vendorSetupData.address.city,
  //     vendorZipCode: vendorSetupData.address.zipCode,
  //     vendorCountry: {
  //       label: vendorSetupData.address.country,
  //       value: vendorSetupData.address.country,
  //     },
  //     vendorState: { label: vendorSetupData.address.state, value: vendorSetupData.address.state },
  //     vendorCode: vendorSetupData.vendorCode,
  //     onceOffPOLimit: vendorSetupData.onceOffPOLimit,
  //     overallAnnualLimit: vendorSetupData.overallAnnualLimit,
  //     vendorEmail: vendorSetupData.email,
  //     vendorPhoneNumber:
  //       isPossiblePhoneNumber(vendorSetupData.phoneNumber) &&
  //       isPossiblePhoneNumber(vendorSetupData.phoneNumber)
  //         ? vendorSetupData.phoneNumber
  //         : '',
  //     defaultCurrency: vendorSetupData.currency
  //       ? {
  //           label: vendorSetupData.currency,
  //           value: vendorSetupData.currency,
  //         }
  //       : undefined,
  //   };
  //   setFormValues(vendorPreloadData);
  // }, [vendorSetupData]);

  useEffect(() => {
    spmsServiceService.getVendorCategories().then((res) => {
      const categories = res.data.data;
      setVendorCategories(categories);
    });
  }, []);

  const submitVendor = async (data) => {
    setIsButtonsBlocked(true);
    requiredRef.current = false;
    setToast((item) => ({ ...item, opened: false }));
    try {
      if (
        data.ndaSigned === 'yes' &&
        (!data.vendorAttachments || !data?.vendorAttachments.length)
      ) {
        requiredRef.current = true;
        setError('vendorAttachments', { type: 'focus', message: 'Files required' });
        setIsButtonsBlocked(false);
        return;
      }
      let vendorFiles = [];
      if (data.vendorAttachments !== undefined && data.vendorAttachments.length > 0) {
        const files = data.vendorAttachments.map(async (item) => ({
          type: 'VENDOR_DOC',
          name: item.name.split(' ').join('-'),
          attachment: await readFile(item.attachment),
        }));
        vendorFiles = await Promise.all(files).then((result) => result);
      }
      const bodyData = {
        vendor: {
          legalName: data.legalName,
          category: data.category.value,
          subCategory: data.subCategory.value,
          vendorContact: {
            name: data.vendorContactName,
            email: data.vendorContactEmail,
            websiteUrl: data.websiteUrl,
            contactType: 'ENTERED_BY_COMPANY',
          },
        },
        requester: {
          userId: data.requester.userId,
          email: data.requester.value,
          name: data.requester.label,
        },
        departmentId: data.departmentId.value,
        locationId: data.locationId.value,
        ndaSigned: data.ndaSigned === 'yes',
        purchaseDescription: data.purchaseDescription,
        benefitsFromVendor: data.benefitsFromVendor,
        attachments: vendorFiles.map((file) => ({
          name: file.name.split(' ').join('-'),
          document: file.attachment,
        })),
      };
      // if (vendorId) {
      // try {
      //   await spmsServiceService.editVendor(companyId, vendorId, bodyData);
      //   if (vendorSetupData.vendorStatus === 'REJECTED' && !forApproval) {
      //     setToast({
      //       opened: true,
      //       message: 'This vendor will now be available in draft',
      //       type: 'success',
      //       cb: () => navigate('/vendors'),
      //     });
      //     return;
      //   }
      //   if (forApproval) {
      //     await spmsServiceService.submitVendorApproval(companyId, vendorId);
      //     setToast({
      //       opened: true,
      //       message: 'Vendor submited for approval',
      //       type: 'success',
      //       cb: () => navigate('/vendors'),
      //     });
      //     return;
      //   }
      //   if (next) {
      //     // getVendorCompany(vendorId);
      //   }
      //   setToast({
      //     opened: true,
      //     message: 'Vendor has been updated',
      //     type: 'success',
      //   });
      // } else {
      await spmsServiceService.createVendorCompany(companyId, bodyData);
      setIsDiscarded(false);
      setToast({
        opened: true,
        message: 'Vendor has been created',
        type: 'success',
        cb: () => {
          navigate('/vendors');
          setIsButtonsBlocked(false);
        },
      });
      // }
    } catch (err) {
      console.log('err', err);
      setToast({
        opened: true,
        message: err.response?.data?.message ? err.response.data.message : 'Something Went Wrong',
        type: 'fail',
      });
      setIsButtonsBlocked(false);
    }
  };

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const filereader = new FileReader();
      filereader.readAsDataURL(file);
      filereader.onload = function () {
        resolve(filereader.result);
      };
      filereader.onerror = function (error) {
        console.log('Error: ', error);
        reject(error);
      };
    });
  };
  console.log(isDirty);
  console.log(Object.keys(dirtyFields).length);
  console.log(isDirty && !!Object.keys(dirtyFields).length);
  return (
    <Box $raduis={12} $mobExtend $asHolder $noOverflow>
      <div className={generalStyles.top}>
        <div>
          <BackButton />
        </div>
        <div className={`${generalStyles.title} ${generalStyles.underline}`}>
          <Text type="subtitle" weight={500}>
            Create Vendor
          </Text>
          <div className={generalStyles.addItemButton}>
            <Button.Main
              $mid
              $style="blue"
              type="submit"
              disabled={isButtonsBlocked}
              onClick={handleSubmit(submitVendor)}
            >
              Submit for Review
            </Button.Main>
            <Button.Main
              $mid
              $style="blank"
              type="button"
              onClick={() =>
                isDirty && !!Object.keys(dirtyFields).length ? setShowModal(true) : navigate(-1)
              }
            >
              Discard
            </Button.Main>
          </div>
        </div>
      </div>
      <div className={generalStyles.tabSection}></div>
      <Suspense fallback={<h2>Loading...</h2>}>
        <form id="cForm" className={formStyles.form}>
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Label
                $title="Requester"
                $tooltipText="The user you are raising the requisition on behalf of"
                $isRequired
              />
              <Controller
                name="requester"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Requester is required',
                  },
                }}
                render={({ field: { ref, ...rest } }) => (
                  <SelectUsers
                    {...rest}
                    selectRef={ref}
                    className={
                      errors.hasOwnProperty(rest.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    isClearable={true}
                  />
                )}
              />
              {errors.requester && (
                <p className="error-message">{errors.requester?.value.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Label
                $title="Location"
                $tooltipText="Physical location items will be delivered to"
                $isRequired
              />
              <Controller
                name="locationId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Location is required',
                  },
                }}
                render={({ field: { ref, ...rest } }) => (
                  <SelectLocations
                    {...rest}
                    selectRef={ref}
                    className={
                      errors.hasOwnProperty(rest.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                  />
                )}
              />
              {errors.locationId && (
                <p className="error-message">{errors.locationId?.value.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Label
                $title="Department"
                $tooltipText="Department requesting the items"
                $isRequired
              />
              <Controller
                name="departmentId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Department is required',
                  },
                }}
                render={({ field: { ref, ...rest } }) => (
                  <SelectDepartments
                    {...rest}
                    selectRef={ref}
                    className={
                      errors.hasOwnProperty(rest.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                  />
                )}
              />
              {errors.departmentId && (
                <p className="error-message">{errors.departmentId?.value.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="legalName"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Enter Name "
                    $label="Name"
                    $labelRequired
                    $tooltip="Legal registered name of the vendor"
                    disabled={!accessVendor?.edit || isEditingDisabled}
                    {...field}
                  />
                )}
              />
              {errors.legalName && <p className="error-message">{errors.legalName.message}</p>}
            </div>
            <div className="inp-container">
              <Label $isRequired $title="Category" $tooltipText="Category" />
              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Category"
                    options={Array.from(new Set(vendorCategories.map((cat) => cat.category))).map(
                      (category) => ({
                        label: category,
                        value: category,
                      }),
                    )}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('subcategory', null);
                    }}
                  />
                )}
              />
              {errors.category && <p className="error-message">{errors.category?.value.message}</p>}
            </div>
            <div className="inp-container">
              <Label $isRequired $title="Sub Category" $tooltipText="Sub Category" />
              <Controller
                name="subCategory"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select Sub Category"
                    options={vendorCategories
                      .filter((category) => category.category === categoryWatcher?.value)
                      .map((subcat) => ({
                        label: subcat.subCategory,
                        value: subcat.subCategory,
                      }))}
                  />
                )}
              />
              {errors.subCategory && (
                <p className="error-message">{errors.subCategory?.value.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="websiteUrl"
                defaultValue=""
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    className={
                      errors.hasOwnProperty(field.name)
                        ? 'react-select-container error'
                        : 'react-select-container'
                    }
                    placeholder="Enter Web URL"
                    $label="Web URL"
                    $tooltip="WEB URL"
                    {...field}
                  />
                )}
              />
              {errors.websiteUrl && <p className="error-message">{errors.websiteUrl.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="vendorContactName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Contact Name"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Contact Name"
                    $tooltip="Vendor contact name"
                    {...field}
                  />
                )}
              />
              {errors.vendorContactName && (
                <p className="error-message">{errors.vendorContactName?.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Controller
                name="vendorContactEmail"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="email"
                    placeholder="Enter Email"
                    className={errors.hasOwnProperty(field.name) ? 'error' : ''}
                    $label="Contact Email"
                    $tooltip="Vendor contact email address"
                    id={field.name}
                    {...field}
                  />
                )}
              />
              {errors.vendorContactEmail && (
                <p className="error-message">{errors.vendorContactEmail?.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Label
                $title="Product/services you are looking to purchase"
                $tooltipText="Product/services you are looking to purchase"
                $isRequired
              />
              <Controller
                name="purchaseDescription"
                control={control}
                rules={{
                  pattern: {
                    value: regExps.notes,
                    message: "Only alpha characters, numbers and - ! . , & : ( ) % + = / @ ' $ € £",
                  },
                }}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    $low
                    $counter
                    $counterMax={150}
                    placeholder="Enter required product/services"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                  />
                )}
              />
              {errors.purchaseDescription && (
                <p className="error-message">{errors.purchaseDescription?.message}</p>
              )}
            </div>
            <div className="inp-container">
              <Label
                $title="Benefit to the company"
                $tooltipText="Benefit to the company"
                $isRequired
              />
              <Controller
                name="benefitsFromVendor"
                control={control}
                rules={{
                  pattern: {
                    value: regExps.notes,
                    message: "Only alpha characters, numbers and - ! . , & : ( ) % + = / @ ' $ € £",
                  },
                }}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    $low
                    $counter
                    $counterMax={150}
                    placeholder="Enter Benefit"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                  />
                )}
              />
              {errors.benefitsFromVendor && (
                <p className="error-message">{errors.benefitsFromVendor?.message}</p>
              )}
            </div>
            <div>
              <Label $title="Signed NDA" $tooltipText="Signed NDA" $isRequired />
              <div className="inp-container">
                <Controller
                  name="ndaSigned"
                  control={control}
                  render={({ field }) => (
                    <div className={vendorStyles.ndaSigned}>
                      <Radio
                        {...field}
                        $size={16}
                        label="Yes"
                        checked={ndaSigned === 'yes'}
                        value={'yes'}
                      />
                      <Radio
                        {...field}
                        $size={16}
                        label="No"
                        checked={ndaSigned === 'no'}
                        value={'no'}
                      />
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
          <br />
          <div className={`${vendorStyles.docs} ${generalStyles.underline}`}>
            <Label $title="Upload support documents" />
            <Documents
              control={control}
              fieldName="vendorAttachments"
              filesSchema={files}
              required={requiredRef.current}
              error={errors?.vendorAttachments?.message}
              isDisable={!accessVendor?.edit}
            />
            {/* {vendorSetupData?.vendorAttachments && (
              <QuotesTab
                data={vendorSetupData?.vendorAttachments}
                deleteDocument={deleteDocument}
              />
            )} */}
          </div>
        </form>
      </Suspense>
      <div className={vendorStyles.prefferedVendorsGrid}>
        <Text weight={500} type={'body-1'}>
          Below a list of matching preferred vendors for your company
        </Text>
        <DataTableBaseRemote
          columns={columns}
          fetchData={getData}
          selectableRows={false}
          paginationResetDefaultPage={false}
        />
      </div>
      <ModalDiscard
        setShowModal={setShowModal}
        showModal={showModal}
        navigateTo={'/vendors'}
        condition={isDirty && !!Object.keys(dirtyFields).length}
      />
      {toast.opened ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </Box>
  );
};

export default VendorSetup;
