import { useEffect, useState } from 'react';
import { useStore } from '../store/store';
import { useShallow } from 'zustand/react/shallow';

import s2pmsService from '../services/spmsService.service';

export const useSubscriptionAllowed = () => {
  const [currentSubscription, setCurrentSubscription] = useState(undefined);
  const user = useStore(useShallow((state) => state.user));
  const cId = user?.customCompanies?.find((item) => item.mainCompany === true)?.companyId;
  const subscriptionChanged = useStore(useShallow((state) => state.subscriptionChanged));

  useEffect(() => {
    const getSubscriptionData = async () => {
      const companyData = await s2pmsService.subscriptionForCompany(cId).then((r) => {
          if (r.data.message === 'Operation Successful') {
            return r.data.data;
          }
        }).catch((err) => {
          if (err?.response?.data?.message === 'Subscription is expired and will not renew.') {
            return { expired: true };
          } else {
            return undefined;
          }
        });
      const planId = companyData?.items?.data?.find(el => el?.object === 'subscription_item')?.plan?.product?.id
      if (!!planId && companyData.expired !== true) {
        const planData = await s2pmsService.subscriptionPlanById(planId).then((r) => {
            if (r.data.message === 'Operation Successful') {
              return r.data.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
        return { company: companyData, plan: planData };
      } else {
        return companyData;
      }
    };
    if (cId !== undefined) {
      getSubscriptionData().then((r) => {
        setCurrentSubscription(r);
      });
    }
  }, [cId, subscriptionChanged]);

  return currentSubscription;
};
