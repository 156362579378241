import React, {useEffect, useState}  from 'react';
import style from '../../../styles/integrations.module.css';
import generalStyles from "../../../styles/general.module.css";
import * as Button from "../../../components/UI/Forms/Button";
import Text from '../../../components/UI/Typography/Text';
import {Controller, useForm, useWatch} from "react-hook-form";
import Toast from "../../../components/UI/General/Toast";
import Select from "react-select";
import Input from '../../../components/UI/Forms/Input';
import styles from '../../../styles/requisition.module.css';
import Status from '../../../components/UI/Typography/Status';
import { useStore } from '../../../store/store';
import integrationsService from '../../../services/integrations.service';

const integrationOptions = [
  {label:'Option 1: with sage 300c PO module',value:'INTEGRATION_WITH_PO'},
  {label:'Option 2: without sage 300c PO module',value:'INTEGRATION_WITHOUT_PO'}
]

const IntegrationEdit = ({integrationName, integrationType}) => {
  const activeCompany = useStore((state) => state.activeCompany);
  const [values, setValues] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState(undefined)
  const [integrationCompanies, setIntegrationCompanies] = useState(undefined)
  const [integrationReady ,setIntegrationReady] = useState(false)
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false)
  const [gls, setGls] = useState(undefined)
  const [vendors, setVendors] = useState(undefined)
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const defaultValues = {
    integrationCompany: null,
    integrationOption: null,
    vendorUrl: '',
    poUrl: '',
    glUrl: '',
    apUrl: '',
    username: '',
    password: '',
    dynamicSql: '',
  }

  const {
    handleSubmit,
    control,
    formState: {errors, isDirty, isValid},
  } = useForm({
    mode: 'onChange',
    defaultValues,
    values,
  });

  const integrationCompanyWatcher = useWatch({ name: 'integrationCompany', control });
  const integrationOptionWatcher = useWatch({ name: 'integrationOption', control });

  useEffect(() => {
    if (!!integrationName && !!activeCompany?.id){
      integrationsService.getCompanyIntegrations(activeCompany?.id).then(r => {
        if (r.data.message === "Operation Successful"){
          const integration = r.data.data.find(item => {
            if (item.integration.productName.toLowerCase() === integrationName.toLowerCase() && item.integration.productType.toLowerCase() === integrationType?.toLowerCase()){
              return item
            }
          })
          if (!!integration){
            const newValues = {
              integrationCompany: {label: integration.properties.find(el => el.key === "ORG_ID")?.value, value: integration.properties.find(el => el.key === "ORG_ID")?.value},
              integrationOption: integration.properties.find(el => el.key === "INTEGRATION_WITH_PO")?.value === true ? integrationOptions[0] : integrationOptions[1],
              vendorUrl: integration.properties.find(el => el.key === "VENDOR_BASE_URL")?.value,
              poUrl: integration.properties.find(el => el.key === "PO_BASE_URL")?.value,
              glUrl: integration.properties.find(el => el.key === "GL_BASE_URL")?.value,
              apUrl: integration.properties.find(el => el.key === "AP_BASE_URL")?.value,
              username: integration.properties.find(el => el.key === "LOGIN_ID")?.value,
              password: integration.properties.find(el => el.key === "PASSWORD")?.value,
              dynamicSql: integration.properties.find(el => el.key === "DYNAMIC_SQL_URL")?.value,
            }
            setValues(newValues)
          }
        }
      });
    }
  },[activeCompany, integrationName])

  useEffect(() => {
    if (!!values){
      setIntegrationReady(true)
    }
  }, [values])

  const onTestConnection = (data) => {
    console.log(data);
    let newData = {
      type: integrationType.toUpperCase(),
      productName: integrationName,
      properties: []
    }
    if (!!data?.username){
      newData.properties.push({
        key: "LOGIN_ID",
        value: data.username
      })
    }
    if (!!data?.password){
      newData.properties.push({
        key: "PASSWORD",
        value: data.password
      })
    }
    if (!!data?.dynamicSql){
      newData.properties.push({
        key: "DYNAMIC_SQL_URL",
        value: data.dynamicSql
      })
    }
    if (!!activeCompany?.id){
      integrationsService.testConnection(activeCompany?.id, newData).then(r => {
        if (r.data.message === "Operation Successful" && r.data?.data?.message !== "Test Connection failed"){
          console.log( r.data?.data);
          const companies = r.data.data.map(item => ({label: item.ORGID, value: item.ORGID}))
          if (!!companies?.length){
            setConnectionStatus(true)
          }else {
            setConnectionStatus(false)
          }
          setIntegrationCompanies(companies)
        }else {
          setConnectionStatus(false)
        }
      }).catch(err => {
        console.log(err);
        setConnectionStatus(false)
      })
    }
  }

  const onSave = (data) => {
    console.log(data);
    setIsButtonsBlocked(true)
    setToast((item) => ({ ...item, opened: false }));
    let newData = {
      type: integrationType.toUpperCase(),
      productName: integrationName,
      properties: []
    }
    if (!!data?.username){
      newData.properties.push({
        key: "LOGIN_ID",
        value: data.username
      })
    }
    if (!!data?.password){
      newData.properties.push({
        key: "PASSWORD",
        value: data.password
      })
    }
    if (!!data?.dynamicSql){
      newData.properties.push({
        key: "DYNAMIC_SQL_URL",
        value: data.dynamicSql
      })
    }
    if (!!data?.integrationCompany?.value){
      newData.properties.push({
        key: "ORG_ID",
        value: data.integrationCompany.value
      })
    }
    if (!!data?.vendorUrl){
      newData.properties.push({
        key: "VENDOR_BASE_URL",
        value: data.vendorUrl
      })
    }
    if (!!data?.glUrl){
      newData.properties.push({
        key: "GL_BASE_URL",
        value: data.glUrl
      })
    }
    if (!!data?.apUrl){
      newData.properties.push({
        key: "AP_BASE_URL",
        value: data.apUrl
      })
    }
    if (!!data?.integrationOption?.value){
      if (data?.integrationOption?.value === 'INTEGRATION_WITH_PO'){
        newData.properties.push({
          key: "INTEGRATION_WITH_PO",
          value: true
        })
        if (!!data?.poUrl){
          newData.properties.push({
            key: "PO_BASE_URL",
            value: data.poUrl
          })
        }
      }else {
        newData.properties.push({
          key: "INTEGRATION_WITHOUT_PO",
          value: true
        })
      }
    }
    console.log(newData);
    if (!!activeCompany?.id){
      integrationsService.createIntegration(activeCompany?.id, newData).then(r => {
        console.log(r);
        if (r.data.message === "Operation Successful"){
          console.log(r.data.data.id);
          setIntegrationReady(r?.data?.data?.id)
        }
      }).catch((reason) => {
        setIsButtonsBlocked(false);
        setToast({
          opened: true,
          message: reason.response.data.message !== undefined ? reason.response.data.message : reason.response.data.error,
          type: 'fail',
        });
      });
    }
  }

  const syncGls = () => {
    integrationsService.syncSageGls(activeCompany?.id).then(r => {
      if (r.data.message === "Operation Successful"){
        setGls(r.data.data)
      }
    })
  }
  const syncVendors = () => {
    integrationsService.syncSageVendors(activeCompany?.id).then(r => {
      if (r.data.message === "Operation Successful"){
        setVendors(r.data.data)
      }
    })
  }

  return (
    <div className={style.tabContainer}>

      <div>
        <div className={style.stepTitle}>
          <Text type={'subtitle'} weight={500} className={style.stepTitle}>Step 1: Get company</Text>
        </div>
        <div className={generalStyles.fieldsThree}>
          <div className="inp-container">
            <Controller
              name="username"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Username is required',
                },
              }}
              render={({ field }) => (
                <Input
                  $small
                  type="text"
                  placeholder="Enter username"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  $label="Login username"
                  $labelRequired
                  {...field}
                />
              )}
            />
            {errors.username && <p className="error-message">{errors.username?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="password"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Password is required',
                },
              }}
              render={({ field }) => (
                <Input
                  $small
                  type="text"
                  placeholder="Enter password"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  $label="Login password"
                  $labelRequired
                  {...field}
                />
              )}
            />
            {errors.password && <p className="error-message">{errors.password?.message}</p>}
          </div>
          <div className="inp-container">
            <Controller
              name="dynamicSql"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Dynamic sql url is required',
                },
              }}
              render={({ field }) => (
                <Input
                  $small
                  type="text"
                  placeholder="Enter Base url for dynamic sql"
                  className={errors.hasOwnProperty(field.name) && 'error'}
                  $label="Base url for dynamic sql"
                  $labelRequired
                  {...field}
                />
              )}
            />
            {errors.dynamicSql && <p className="error-message">{errors.dynamicSql?.message}</p>}
          </div>
        </div>
      </div>

      <div className={style.testConnection}>
        <Button.Main
          $mid
          $style="blue"
          type="submit"
          onClick={handleSubmit((data) => onTestConnection(data))}
          disabled={!isDirty || !isValid || isButtonsBlocked || connectionStatus === true}
        >
          Test Connection
        </Button.Main>
        <Text weight={500} type={'body-1'}>
          Status: {connectionStatus === false ? (
          <span className={style.red}>Test Connection failed</span>
        ) : connectionStatus === true ? (
          <span className={style.green}>Connection was successful</span>
        ) : null}
        </Text>
      </div>

      {!!integrationCompanies ? (
        <div>
          <div className={style.stepTitle}>
            <Text type={'subtitle'} weight={500} className={style.stepTitle}>Step 2 - Select company to integrate with</Text>
          </div>
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="integrationCompany"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Integration company is required',
                  },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={errors.hasOwnProperty(field.name) ? 'react-select-container small error' : 'react-select-container small'}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select option"
                    options={integrationCompanies}
                  />
                )}
              />
              {errors.integrationCompany && <p className="error-message">{errors.integrationCompany.message}</p>}
            </div>
          </div>
        </div>
      ) : null}

      {!!integrationCompanyWatcher?.value ? (
        <div>
          <div className={style.stepTitle}>
            <Text type={'subtitle'} weight={500} className={style.stepTitle}>Step 3 - Select the integration option</Text>
          </div>
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="integrationOption"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Integration option is required',
                  },
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className={errors.hasOwnProperty(field.name) ? 'react-select-container small error' : 'react-select-container small'}
                    classNamePrefix="react-select"
                    isSearchable={false}
                    placeholder="Select option"
                    options={integrationOptions}
                  />
                )}
              />
              {errors.integrationOption && <p className="error-message">{errors.integrationOption.message}</p>}
            </div>
          </div>
        </div>
      ) : null}

      {!!integrationOptionWatcher?.value ? (
        <div>
          <div className={style.stepTitle}>
            <Text type={'subtitle'} weight={500} className={style.stepTitle}>Step 4: Specify the base URL for the following endpoints</Text>
          </div>
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="vendorUrl"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'URL is required',
                  },
                }}
                render={({ field }) => (
                  <Input
                    $small
                    type="text"
                    placeholder="Enter URL"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Base URL - Vendors"
                    $labelRequired
                    {...field}
                  />
                )}
              />
              {errors.vendorUrl && <p className="error-message">{errors.vendorUrl?.message}</p>}
            </div>
            {integrationOptionWatcher?.value === 'INTEGRATION_WITH_PO' ? (
              <div className="inp-container">
                <Controller
                  name="poUrl"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'URL is required',
                    },
                  }}
                  render={({ field }) => (
                    <Input
                      $small
                      type="text"
                      placeholder="Enter URL"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                      $label="Base URL - PO"
                      $labelRequired
                      {...field}
                    />
                  )}
                />
                {errors.poUrl && <p className="error-message">{errors.poUrl?.message}</p>}
              </div>
            ) : null}
            <div className="inp-container">
              <Controller
                name="glUrl"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'URL is required',
                  },
                }}
                render={({ field }) => (
                  <Input
                    $small
                    type="text"
                    placeholder="Enter URL"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Base URL - GL"
                    $labelRequired
                    {...field}
                  />
                )}
              />
              {errors.glUrl && <p className="error-message">{errors.glUrl?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="apUrl"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'URL is required',
                  },
                }}
                render={({ field }) => (
                  <Input
                    $small
                    type="text"
                    placeholder="Enter URL"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Base URL - AP"
                    $labelRequired
                    {...field}
                  />
                )}
              />
              {errors.apUrl && <p className="error-message">{errors.apUrl?.message}</p>}
            </div>
          </div>
        </div>
      ) : null}

      {!!integrationOptionWatcher?.value ? (
        <div>
          <Button.Main
            $mid
            $style="blue"
            type="submit"
            onClick={handleSubmit((data) => onSave(data))}
            disabled={!isDirty || !isValid || isButtonsBlocked}
          >
            Save Integration
          </Button.Main>
        </div>
      ) : null}

      {integrationReady ? (
        <>
          <div>
            <div className={style.stepTitle}>
              <Text type={'subtitle'} weight={500} className={style.stepTitle}>Step 5: Sync General Ledger</Text>
            </div>
            <div className={styles.itemsTable}>
              <div style={{width: "100%"}}>
                <div className={styles.itemsInvoiceTableHead + ' ' + styles.forIntegration}>
                  <Text type="body-1" weight={500}>
                    GL’s Synced
                  </Text>
                  <Text type="body-1" weight={500}>
                    Status
                  </Text>
                  <Text type="body-1" weight={500}>
                    Synced
                  </Text>
                </div>
              </div>
              <div className={styles.itemsTableBody}>
                <div className={style.itemInner}>
                  <div>{gls?.recordsSynced ?? 0}</div>
                  <div>
                    {gls?.syncSuccess === true ? (
                      <Status value={"APPROVED"} text={gls?.message ?? "Success"} pill fit />
                    ) : gls?.syncSuccess === false ? (
                      <Status value={"REJECTED"} text={"Failure"} pill fit />
                    ) : "No status"}
                  </div>
                  <div>
                    <Button.Main
                      $mid
                      $style="blue"
                      onClick={()=> syncGls()}
                      disabled={gls?.syncSuccess === true}
                    >
                      Sync GL’s
                    </Button.Main>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={style.stepTitle}>
              <Text type={'subtitle'} weight={500} className={style.stepTitle}>Step 6: Sync Vendors</Text>
            </div>
            <div className={styles.itemsTable}>
              <div style={{width: "100%"}}>
                <div className={styles.itemsInvoiceTableHead + ' ' + styles.forIntegration}>
                  <Text type="body-1" weight={500}>
                    Vendor's Synced
                  </Text>
                  <Text type="body-1" weight={500}>
                    Status
                  </Text>
                  <Text type="body-1" weight={500}>
                    Synced
                  </Text>
                </div>
              </div>
              <div className={styles.itemsTableBody}>
                <div className={style.itemInner}>
                  <div>{vendors?.recordsSynced ?? 0}</div>
                  <div>
                    {vendors?.syncSuccess === true ? (
                      <Status value={"APPROVED"} text={vendors?.message ?? "Success"} pill fit />
                    ) : vendors?.syncSuccess === false ? (
                      <Status value={"REJECTED"} text={"Failure"} pill fit />
                    ) : "No status"}
                  </div>
                  <div>
                    <Button.Main
                      $mid
                      $style="blue"
                      onClick={() => syncVendors()}
                      disabled={vendors?.syncSuccess === true}
                    >
                      Sync Vendors
                    </Button.Main>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {toast.opened === true ? <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb}/>: null}
    </div>
  );
};

export default IntegrationEdit;
