import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import Box from '../../components/UI/General/Box';
import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/integrations.module.css';
import BackButton from '../../components/shared/BackButton';
import Text from '../../components/UI/Typography/Text';
import * as Button from '../../components/UI/Forms/Button';
import { useLocation } from 'react-router-dom';
import { useStore } from '../../store/store';
import integrationsService from '../../services/integrations.service';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import spmsServiceService from '../../services/spmsService.service';
import Toast from '../../components/UI/General/Toast';

const QuickBooks = () => {
  const location = useLocation();
  const activeCompany = useStore((state) => state.activeCompany);

  const [isConnected, setIsConnected] = useState(null)
  const [companyInfo, setCompanyInfo] = useState(null)
  const [integrationId, setIntegrationId] = useState(undefined)

  const [vendorsResponse, setVendorsResponse] = useState(null)
  const [glsResponse, setGlsResponse] = useState(null)

  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  useEffect(() => {
    if (!!activeCompany?.id){
      integrationsService.getCompanyIntegrations(activeCompany.id).then(r => {
        if (r.data.message === "Operation Successful"){
          const key = r.data?.data?.find(el => el.integration.productName === "QuickBooks Online").properties?.find(el => el.key === 'connected')?.value
          const keyCompany = r.data?.data?.find(el => el.integration.productName === "QuickBooks Online").properties?.find(el => el.key === 'company_name')?.value
          const keyPlan = r.data?.data?.find(el => el.integration.productName === "QuickBooks Online").properties?.find(el => el.key === 'company_plan')?.value
          console.log(key);
          if (key === 'true'){
            setIsConnected(true);
          } else {
            setIsConnected(false)
          }
          if (keyCompany !== undefined && keyPlan !== undefined){
            setCompanyInfo({name: keyCompany, plan: keyPlan})
          }
          setIntegrationId(r.data?.data?.find(el => el.integration.productName === "QuickBooks Online")?.id)
        }
      })
    }
  },[activeCompany])

  useEffect(() => {
    console.log(isConnected);
    if (!!activeCompany?.id && isConnected === false){
      console.log('CREATE');
      integrationsService.createIntegration(activeCompany.id, {
        type: 'FINANCIAL',
        productName: 'QuickBooks Online',
        properties: []
      }).then(r => {
        console.log(r);
      }).catch(err => {
        console.log(err);
        console.log(err.response.data.message === "Integration already exists");
      })
    }
  }, [activeCompany, isConnected, companyInfo])

  const connectHandler = useCallback(() => {
    console.log('connect');
    if (!!activeCompany?.id && isConnected === false){
      integrationsService.activateCompanyIntegration(activeCompany?.id, integrationId).then(r => {
        console.log(r);
        integrationsService.connectQB(activeCompany?.id, window.location.href).then(r => {
          console.log(r.data);
          window.location.href = r.data.data
        })
      }).catch(err => {
        console.log(err);
        setToast({
          opened: true,
          message: "Integration Activation Error",
          type: 'fail',
        });
      })
    }
  },[isConnected, activeCompany])

  const disconnect = () => {
    console.log('disconnect');
    integrationsService.archiveCompanyIntegration(activeCompany?.id, integrationId).then(r => {
      console.log(r);
      integrationsService.disconnectQB(activeCompany?.id).then(r => {
        if (r.data.message === "Operation Successful"){
          console.log(r.data.data);
          if (r.data.data === true){
            setIsConnected(false)
          }
        }
      })
    }).catch(err => {
      console.log(err);
      setToast({
        opened: true,
        message: "Archive Integration Error",
        type: 'fail',
      });
    })
  }

  const syncQBVendorsHandler = () => {
    integrationsService.syncQBVendors(activeCompany?.id).then(r => {
      console.log(r);
      if (r.data.message === "Operation Successful"){
        setVendorsResponse(r.data.data);
      }
    })
  }

  const syncQBGlsHandler = () => {
    integrationsService.syncQBGls(activeCompany?.id).then(r => {
      console.log(r);
      if (r.data.message === "Operation Successful"){
        setGlsResponse(r.data.data);
      }
    })
  }

  const getData = useCallback(async (page, size) => {
      const requestBody = {
        vendorStatus: 'IMPORTED',
      };
      return await spmsServiceService.getVendors(activeCompany.id, requestBody, page, size);
    },[vendorsResponse, activeCompany]);

  useEffect(() => {
    if (!!vendorsResponse?.syncSuccess){
      getData(0, 10)
    }
  },[vendorsResponse, getData])

  const inviteVendor = (vendorId) => {
    spmsServiceService.inviteVendor(activeCompany?.id, vendorId).then((r) => {
      console.log(r);
      setToast({
        opened: true,
        message: 'Vendor invited',
        type: 'success',
      });
    })
    .catch((err) => {
      console.log(err);
      setToast({
        opened: true,
        message: err.response.data.message,
        type: 'fail',
      });
    });
  };

  const columnsVendors = useMemo(() => [
    {
      name: 'Vendor Display Name',
      selector: (row) => row.total,
      sortable: true,
      cell: (row) => (
        <span data-content={'Vendor Display Name'} className={generalStyles.tableValue}>
          <span>{row?.vendor?.legalName ?? '-'}</span>
        </span>
      ),
    },
    {
      name: 'Email',
      selector: (row) => row.total,
      sortable: true,
      cell: (row) => (
        <span data-content={'Email'} className={generalStyles.tableValue}>
          <span>{row?.vendor?.email ?? '-'}</span>
        </span>
      ),
    },
    {
      name: 'Status',
      selector: (row) => row.total,
      sortable: true,
      cell: (row) => (
        <span data-content={'Status'} className={generalStyles.tableValue}>
          <span>{row.vendorStatus}</span>
        </span>
      ),
    },
    {
      name: 'Action',
      cell: (row) => (
        <span data-content={'Action'} className={generalStyles.tableValue}>
          <span className={'link'} onClick={() => inviteVendor(row.vendor.id)}>Invite to portal</span>
        </span>
      ),
    },
  ], []);

  return (
    <Box $mobExtend $asHolder>
      <div className={generalStyles.top}>
        <div><BackButton to={'/integrations'}/></div>
        <div className={generalStyles.title}>
          <Text type="subtitle" weight={500}>QuickBooks Online Integration</Text>
          <div className={generalStyles.addItemButton}>

            { isConnected === false ? (
              <Button.Main $mid $style="blue" type="button" onClick={connectHandler}>
                Connect to Quickbooks Online
              </Button.Main>
            ) : isConnected === true ? (
              <>
                <Text type={'body-1'}>Connected to: <Text weight={500}>{companyInfo?.name}</Text></Text>
                <Button.Main $mid $style="red" type="button" onClick={() => disconnect()}>
                  Disconnect
                </Button.Main>
              </>
            ) : null}

          </div>
        </div>
      </div>

      <div className={styles.overview}>
        <Text type={'subtitle'} weight={500}>Overview</Text>
        <Text type={'body-1'}>SpendPro seamlessly integrates to Quickbooks Online Essentials, Plus and Advanced plans.</Text>
        <Text type={'body-1'} >
          <ul className={styles.list}>
            <li>Sync Account Codes</li>
            <li>Sync Vendors</li>
            <li>Sync Inventory(Plus and Advanced plans only)</li>
          </ul>
        </Text>
        <Text type={'subtitle'} weight={500}>Guide to connect to your Quickbook Online instance</Text>
        <Text type={'body-1'} >
          <ol className={styles.list}>
            <li>Click on the "Connect to Quickbooks Online" button</li>
            <li>You will be directed to the Quickbooks Online authentication page and requsted to enter your Quickbooks
              Online account details
            </li>
            <li>Once the authentication is successful, you will be directed to SendPro to synchronize your existing Quickbooks Online Suppliers and Account Codes(all plans)</li>
            <li>You will have an option to synchronize your inventory with SpendPro if you are on the Pro or Pro Plus plan</li>
          </ol>
        </Text>
        {isConnected === true ? (
          <>
            <div className={styles.table}>
              <div className={styles.tableTop}>
                <Button.Main $mid $style="blue" type="button" onClick={syncQBVendorsHandler} disabled={vendorsResponse?.syncSuccess === true}>Import Vendors</Button.Main>
                {vendorsResponse !== null ? (
                  <Text type={'body-1'}>Status: <Text weight={500}>{!!vendorsResponse.recordsSynced ? `Successfully imported ${vendorsResponse.recordsSynced}` : vendorsResponse.message}</Text></Text>
                ) : null}
              </div>
              <div>
                <Suspense fallback={<h2>Loading...</h2>}>
                  <DataTableBaseRemote
                    columns={columnsVendors}
                    fetchData={getData}
                    selectableRows={false}
                    paginationResetDefaultPage={false}
                  />
                </Suspense>
              </div>
            </div>
            <div className={styles.table}>
              <div className={styles.tableTop}>
                <Button.Main $mid $style="blue" type="button" onClick={syncQBGlsHandler} disabled={glsResponse?.syncSuccess === true}>Import Chart of Accounts</Button.Main>
                {glsResponse !== null ? (
                  <Text type={'body-1'}>Status: <Text weight={500}>{!!glsResponse.recordsSynced ? `Successfully imported ${glsResponse.recordsSynced}` : glsResponse.message}</Text></Text>
                ) : null}
              </div>
            </div>
          </>
        ) : null}
      </div>

      {toast.opened === true ? <Toast message={toast.message} opened={toast.opened} type={toast.type} />: null}
    </Box>
  );
};

export default QuickBooks;
