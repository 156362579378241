import { Navigate, Outlet } from 'react-router-dom';
import { useStore } from '../../store/store';

const ProtectedFetching = () => {
  const stepsDone = useStore((state) => state.stepsDone);
  const haveStepsLoaded = useStore((state) => state.haveStepsLoaded);

  if (!stepsDone && haveStepsLoaded) {
    return <Navigate to="/onboarding" replace />;
  }
  if (stepsDone && haveStepsLoaded) {
    return <Navigate to="/dashboard" replace />;
  }
  return <Outlet />;
};

export default ProtectedFetching;
