import * as React from 'react';
import { useStore } from '../../store/store';
import { useEffect, useState } from 'react';
import spmsServiceService from '../../services/spmsService.service';
import * as Button from '../../components/UI/Forms/Button';

function PricingPage() {
  const user = useStore((state) => state.user);
  const activeCompany = useStore((state) => state.activeCompany);
  const [session, setSession] = useState(null);
  const [link, setLink] = useState(null)

  useEffect(() => {
    if (!!activeCompany?.id) {
      spmsServiceService.stripeSession(activeCompany?.id).then(r => {
        console.log(r.data);
        if (!!r?.data?.client_secret) {
          setSession(r.data.client_secret);
        }
      });

    }
  }, [activeCompany]);
  console.log(session);

  useEffect(() => {
    const fetchPortalUrl = async () => {
      try {
        const response = await spmsServiceService.stripeCustomerPortalSession(activeCompany.id, {returnUrl: window.location.href}).then(r => {
          if (r.data.message === "Operation Successful"){
            return r.data.data
          }
        })
        console.log(response);
        setLink(response.url);
      } catch (err) {
        console.error('Failed to create portal session:', err);
      }
    };
    if (!!session) fetchPortalUrl();
  },[session])

  const cancelSubscription = () => {
    spmsServiceService.cancelSubscription(activeCompany?.id).then(r => {
      console.log(r);
    }).catch(err => {
      console.log(err);
    })
  }
  const resumeSubscription = () => {
    spmsServiceService.resumeCancelledSubscription(activeCompany?.id).then(r => {
      console.log(r);
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <>
      {!!session ? (
        <div>
          <a href={link}>
            <Button.Main $mid $style="blue" type="button">Manage</Button.Main>
          </a><br/>
          <Button.Main $mid $style="blue" type="button" onClick={cancelSubscription}>Cancel Subscription</Button.Main><br/>
          <Button.Main $mid $style="blue" type="button" onClick={resumeSubscription}>Resume Cancelled Subscription</Button.Main>

          <stripe-pricing-table pricing-table-id="prctbl_1R2VIMAYXI1u5lpoRJxxXmwo"
                                publishable-key="pk_test_51QV8z4AYXI1u5lpof3nVEd7d1MUUfkYouac1MPqpG3peGpygoo9SS6GVQcN2U3Un8VsAYH0h2dywpMTQd04ghJkC00HDXnJYjg"
                                client-reference-id={user.id}
                                customer-session-client-secret={session}
          >
          </stripe-pricing-table>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
}

export default PricingPage;
