import React, { useEffect, useState } from 'react';
import integrationsService from '../../services/integrations.service';

const IntegrationLogs = ({companyId, integrationId}) => {
  const [values, setValues] = useState([])

  useEffect(() => {
    integrationsService.getIntegrationLogs(companyId, integrationId).then(r => {
      if (r.data.message === "Operation Successful"){
        console.log(r.data.data);
        setValues(r.data.data)
      }
    })
  }, [])

  return (
    <div>
      <h5>logs info:</h5>
      {!!values ? (
        <ul>
        {
          values.map(item => {
            let arr = []
            for (let propName in item) {
              if (item.hasOwnProperty(propName)) {
                let propValue = item[propName];
                arr.push({label: propName, value: propValue})
              }
            }
            return arr.map(el => (
              <li>{el.label} = {el.value}</li>
            ))
          })
        }
        </ul>
      ) : (
        <div>no logs present for this integration yet</div>
      )}
    </div>
  );
};

export default IntegrationLogs;
