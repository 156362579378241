import spmsServiceService from '../../services/spmsService.service';
import { useStore } from '../../store/store';

export const requisitionLoader = async ({ params }) => {
  console.log(params.requisitionId);
  const user = useStore.getState().user;
  const ccs = user.customCompanies.map((cc) => cc.company.id);
  return await spmsServiceService.getPurchaseOrder(params.requisitionId).then((r) => {
    if (r.data.message === 'Operation Successful') {
      if (!ccs.includes(r.data.data.company.id)) {
        throw new Response('You are not allowed to see this', { status: 405 });
      }
    }
    return null;
  });
};
