import { useEffect } from 'react';
import { useStore } from '../../store/store';
import styled from 'styled-components';
const LoginLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  & span {
    position: absolute;
  }
`;

const LoginLoader = styled.div`
  width: 120px;
  height: 120px;
  background-color: #145ff526;
  border-radius: 50%;
  animation: growShrink 3s infinite;
  display: flex;
  justify-content: center;
  align-content: center;
  @keyframes growShrink {
    0%,
    100% {
      transform: scale(1); /* Original size */
    }
    50% {
      transform: scale(1.5); /* Increased size */
    }
  }
`;

const FetchingData = () => {
  const setSteps = useStore((state) => state.setSteps);
  useEffect(() => {
    setSteps();
  }, []);

  return (
    <LoginLoaderContainer>
      <span>
        <img src="assets/img/login_register/logo_small.svg" alt="Poms" />
      </span>
      <LoginLoader />
    </LoginLoaderContainer>
  );
};

export default FetchingData;
