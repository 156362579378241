import { axiosInstance, baseUrl, s2pmsBaseUrl } from './API';

const integrationsService = {
  testConnection: (companyId, data) => axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/integrations/test-connection', data),
  getCompanyIntegrations: (companyId) => axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/integrations'),
  getCompanyIntegrationsByProduct: (companyId) => axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/integrations/perProduct'),
  createIntegration: (companyId, data) => axiosInstance.post(s2pmsBaseUrl + '/company/' + companyId + '/integrations', data),
  getCompanyIntegration: (companyId, integrationId) => axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/integrations/' + integrationId),
  updateCompanyIntegration: (companyId, integrationId, data) => axiosInstance.put(s2pmsBaseUrl + '/company/' + companyId + '/integrations/' + integrationId, data),
  activateCompanyIntegration: (companyId, integrationId) => axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/integrations/' + integrationId + '/activate'),
  archiveCompanyIntegration: (companyId, integrationId) => axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/integrations/' + integrationId + '/archive'),
  getIntegrationLogs: (companyId, integrationId) => axiosInstance.get(s2pmsBaseUrl + '/company/' + companyId + '/integrations/' + integrationId + '/sync-logs'),
  // Super admin
  getSystemIntegrations: () => axiosInstance.get(baseUrl + '/integrations'),
  getSingleSystemIntegration: (integrationId) => axiosInstance.get(baseUrl + '/integrations/' + integrationId),
  createSystemIntegration: (data) => axiosInstance.post(baseUrl + '/integrations', data),
  editSystemIntegration: (integrationId, data) => axiosInstance.put(baseUrl + '/integrations/update/' + integrationId, data),
  activateSystemIntegration: (integrationId) => axiosInstance.post(baseUrl + '/integrations/' + integrationId + '/activate'),
  archiveSystemIntegration: (integrationId) => axiosInstance.delete(baseUrl + '/integrations/' + integrationId + '/archive'),
  // SAGE
  getSageCompanies: () => axiosInstance.get(baseUrl + '/sage/companies'),
  syncSageGls: (companyId) => axiosInstance.post(baseUrl + '/sage/sync-gls/company/{company-id}?companyId=' + companyId),
  syncSageVendors: (companyId) => axiosInstance.post(baseUrl + '/sage/sync-vendors/company/' + companyId),
  // QuickBooks
  connectQB: (companyId, redirectUrl) => axiosInstance.post(baseUrl + '/quick-books/connect/' + companyId + '?frontendRedirectUri=' + redirectUrl),
  disconnectQB: (companyId) => axiosInstance.post(baseUrl + '/quick-books/disconnect/' + companyId),
  getQBItems: (companyId) => axiosInstance.get(baseUrl + '/quick-books/items/' + companyId),
  syncQBGls: (companyId) => axiosInstance.post(baseUrl + '/quick-books/sync-gls/' + companyId),
  syncQBItems: (companyId) => axiosInstance.post(baseUrl + '/quick-books/sync-items/' + companyId),
  syncQBVendors: (companyId) => axiosInstance.post(baseUrl + '/quick-books/sync-vendors/' + companyId),
};

export default integrationsService;
