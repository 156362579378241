import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import generalStyles from '../../styles/general.module.css'
import style from '../../styles/integrations.module.css'
import integrationsService from '../../services/integrations.service';
import { useStore } from '../../store/store';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend';
import moment from 'moment';
import spmsServiceService from '../../services/spmsService.service';

const Integrations = () => {
  const company = useStore((state) => state.company);
  const [integrations, setIntegrations] = useState({})

  useEffect(() => {
    if (!!company?.id){
      integrationsService.getCompanyIntegrationsByProduct(company.id).then(async r => {
        if (r.data.message === "Operation Successful"){
          const withImageArray = await Promise.all(
            Object.keys(r.data.data).map(async key => {
              return await Promise.all(
                r.data.data[key].map(async (el) => {
                  const image = el?.attachments?.reduce((latest, current) => moment(current.dateUploaded).isAfter(moment(latest.dateUploaded)) ? current : latest, el?.attachments[0])
                  if (!!image?.key && !image?.key.includes('null')){
                    const img = await Promise.all([
                      await spmsServiceService.getAttachmentsByKey(image?.key).then(r => r.data.message === "Operation Successful" ? r.data.data : null)
                    ])
                    return {...el, attachments: img[0]}
                  }else {
                    return {...el, attachments: null}
                  }
                })
              )
            })
          )
          const sorted = await withImageArray.flat().reduce((acc, obj) => {
            const value = obj['productType']
            if (!acc[value]){
              acc[value] = []
            }
            acc[value].push(obj)
            return acc
          }, {})
          setIntegrations(sorted)
        }
      })
    }
  }, [company])

  return (
    <div>
      <div className={style.titleBlock}>
        <Text weight={500} type={'h3'}>Integrations</Text>
        <Text type={'body-1'} className={style.subtitle}>SpendPro seamlessly integrates the below enterprise Accounting and Enterprise Resource Planning (ERP) solutions</Text>
      </div>

      {Object.keys(integrations).map(key => (
        <div key={key} className={style.section}>
          <Text weight={500} type={'h4'}>{readableTitleFromBackend(key)}</Text>
          <div className={generalStyles.fieldsThree}>
            {integrations[key].map((item, index) => {
              const atLeastOneActive = !!integrations[key].filter(el => el.active === true)?.length
              if (atLeastOneActive){
                return(
                  <div className={!!item.active ? style.itemBox : style.itemBox + ' ' + style.itemBoxDisabled} key={index}>
                    {!!item.active ? <Text type={'body-2'} className={style.itemStatus}>Active</Text> : null}
                    <img className={style.itemLogo} src={!!item.attachments ? item.attachments : 'assets/img/integrations/integrations.png'} alt={item.productName}/>
                    <Text type={'body-1'}>{item.productName}</Text>
                    {!!item.active ? (
                      <Link
                        to={item.productName === 'QuickBooks Online' ? '/integrations/quick-books' : '/integrations/setting'}
                        className={'link'} state={{
                        productName: item.productName,
                        productType: item.productType,
                      }}>See more</Link>
                    ) : (
                      <span>See more</span>
                    )}
                  </div>
                );
              }else {
                return(
                  <div className={style.itemBox} key={index}>
                    <img className={style.itemLogo} src={!!item.attachments ? item.attachments : 'assets/img/integrations/integrations.png'} alt={item.productName}/>
                    <Text type={'body-1'}>{item.productName}</Text>
                    <Link
                      to={item.productName === 'QuickBooks Online' ? '/integrations/quick-books' : '/integrations/setting'}
                      className={'link'} state={{
                      productName: item.productName,
                      productType: item.productType,
                    }}>See more</Link>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Integrations;
