import React, { useEffect, useState, Suspense, useMemo, useCallback } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import { Link } from 'react-router-dom';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import generalStyles from '../../styles/general.module.css';
import DataTableBaseRemote from '../../components/UI/General/DataTableBaseRemote';
import Input from '../../components/UI/Forms/Input';
import SpmsService from '../../services/spmsService.service';
import Modal from '../../components/UI/Modal/Modal';
import Textarea from '../../components/UI/Forms/Textarea';
import { Controller, useForm } from 'react-hook-form';
import ModalInner from '../../components/UI/Modal/ModalInner';
import { useStore } from '../../store/store';
import Toast from '../../components/UI/General/Toast';
import modalStyles from '../../styles/log_reg.module.css';
import style from '../../styles/users.module.css';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import Box from '../../components/UI/General/Box';
import TabsSlider from '../../components/UI/General/TabsSlider';
import { reducedText } from '../../utils/reducedText';
import Export from '../../components/shared/Export';
import AddButton from '../../components/shared/AddButton';
import spmsServiceService from '../../services/spmsService.service';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend';
import { nf } from '../../utils/nf';
import Filter from '../../components/UI/General/Filter';
import Search from '../../components/UI/General/Search';

const PurchaseOrders = () => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
  });
  const user = useStore((state) => state.user);
  const activeCompany = useStore((state) => state.activeCompany);
  const [filterText, setFilterText] = useState(undefined);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [itemId, setItemId] = useState(undefined);
  const [showRecallModal, setShowRecallModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const [toExport, setToExport] = useState([]);
  const [totalRows, setTotalRows] = useState(0);

  const accessPO = useAccessAllowed('Purchase_Orders');

  const closeModal = () => {
    reset();
    setShowRecallModal(false);
  };

  const columns = useMemo(
    () => [
      {
        name: 'Order No',
        wrap: true,
        sortable: true,
        selector: (row) => row?.title,
        cell: (row) => {
          const link =
            tab === 'pending' || tab === 'approved' || tab === 'closed'
              ? '/purchase-orders/'
              : '/purchase-orders/edit/';
          return (
            <span data-content={'Order No'} className={generalStyles.tableValue}>
              <Link to={link + row.id}>
                <span>{row?.purchaseOrderNo}</span>
              </Link>
            </span>
          );
        },
      },
      {
        name: 'Title',
        wrap: true,
        sortable: true,
        selector: (row) => row?.title,
        cell: (row) => {
          return (
            <span data-content={'Title'} className={generalStyles.tableValue}>
              <span>{reducedText(row?.title)}</span>
            </span>
          );
        },
      },
      {
        name: 'Location',
        cell: (row) => (
          <span data-content={'Location'} className={generalStyles.tableValue}>
            <span>{row?.locationName}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.locationName,
      },
      {
        name: 'Department',
        cell: (row) => (
          <span data-content={'Department'} className={generalStyles.tableValue}>
            <span>{row?.department?.name}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.department?.name,
      },
      {
        name: 'Vendor',
        cell: (row) => (
          <span data-content={'Vendor'} className={generalStyles.tableValue}>
            <span>{row?.vendor?.legalName}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.vendor?.legalName,
      },
      {
        name: 'Requester',
        cell: (row) => (
          <span data-content={'Requester'} className={generalStyles.tableValue}>
            <span>{row?.requester?.name}</span>
          </span>
        ),
        sortable: true,
        selector: (row) => row?.requester?.name,
      },
      {
        name: 'Total Amount',
        cell: (row) => {
          const cur = row?.currency?.code ?? '';
          return (
            <span data-content={'Total Amount'} className={generalStyles.tableValue}>
              <span>{cur + ' ' + nf.format(row?.total)}</span>
            </span>
          );
        },
        sortable: true,
        selector: (row) => row?.total,
      },
      {
        name: 'Status',
        cell: (row) => {
          return (
            <span data-content={'Status'} className={generalStyles.tableValue}>
              <span>{readableTitleFromBackend(row.purchaseOrderState)}</span>
            </span>
          );
        },
        sortable: true,
        selector: (row) => row?.purchaseOrderState,
      },
    ],
    [tab, accessPO],
  );

  const discardPurchaseOrder = (id) =>
    SpmsService.discardPurchaseOrder(id)
      .then((r) => {
        console.log(r);
        setShowDiscardModal(false);
        setResetPaginationToggle(!resetPaginationToggle);
        setTrigger((state) => !state);
      })
      .catch((err) => {
        console.log(err);
      });

  const recallPurchaseOrder = (data) => {
    setToast((item) => ({ ...item, opened: false }));
    SpmsService.recallPurchaseOrder(itemId, data.reason)
      .then((res) => {
        setToast({
          opened: true,
          message: 'Purchase order recalled successfully.',
          type: 'success',
          cb: navigate('/purchase-orders/overview/drafts'),
        });
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
        });
      });
    closeModal();
  };

  // const closePO = (id) => {
  //   setToast((item) => ({ ...item, opened: false }));
  //   spmsServiceService
  //     .closePurchaseOrder(id)
  //     .then((r) => {
  //       console.log(r);
  //       setTrigger((state) => !state);
  //       setToast({
  //         opened: true,
  //         message: 'Purchase order closed successfully.',
  //         type: 'success',
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setToast({
  //         opened: true,
  //         message: err.response.data.message,
  //         type: 'fail',
  //       });
  //     });
  // };

  const getData = useCallback(
    (page, size) => {
      if (!accessPO) return;
      const filterValues = getValues();
      console.log(filterValues);
      const search = {
        purchaseOrderState: 'APPROVED',
        // purchaseOrderState: filterValues.status?.value ?? undefined,
        companyId: activeCompany.id,
        title: filterText,
        vendorId: filterValues.vendor?.value,
        requesterId: filterValues.requester?.userId,
      };
      if (!accessPO?.viewAllCompany && !accessPO?.viewAllDepartmentLocation && accessPO?.viewOwn) {
        search.requesterUserId = user.id;
      } else if (!accessPO?.viewAllCompany && accessPO?.viewAllDepartmentLocation) {
        const userCompany = user.customCompanies.find(
          (company) => company.companyId === activeCompany.id,
        );
        search.departmentId = userCompany.departmentId;
        search.locationId = userCompany.locationId;
      } else {
        search.departmentId = filterValues.department?.value;
        search.locationId = filterValues.location?.value;
      }

      return SpmsService.getPurchaseOrders(size, page, search, activeCompany.id);
    },
    [tab, filterText, trigger, activeCompany.id, accessPO],
  );

  useEffect(() => {
    SpmsService.getPurchaseOrders(1, 0, {
      companyId: activeCompany.id,
    }).then((r) => {
      setTotalRows(r.data.data.totalElements);
    });
  }, []);

  useEffect(() => {
    const getExp = async () => {
      const response = await spmsServiceService.getPurchaseOrders(totalRows, 0, {
        companyId: activeCompany.id,
        title: filterText,
      });
      const csvData = response.data.data.content.map((item) => ({
        'PO Title': item.title,
        'PO No': item.purchaseOrderNo,
        Location: item.location.name,
        Dept: item.department.name,
        Vendor: item.vendor.legalName,
        Requester: item.requester.name,
        'PO Budget Amount': item?.currency?.code + ' ' + item?.total?.toFixed(2),
        Status: item.purchaseOrderState,
        'Required Date': item.deliveryDate,
        Received: readableTitleFromBackend(item.fulfillmentStatus),
        Invoiced: readableTitleFromBackend(item.invoiceStatus),
        Paid: readableTitleFromBackend(item.paymentStatus),
      }));
      setToExport(csvData);
    };
    if (totalRows === 0) {
      console.log('no results for export');
    } else {
      getExp();
    }
  }, [filterText, trigger, totalRows, activeCompany.id]);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      getData(0, 10);
    }
    return () => {
      ignore = true;
    };
  }, [getData]);

  const filterData = [
    {
      name: 'location',
      label: 'Location',
      isMulti: false,
      fetchType: 'location',
    },
    {
      name: 'department',
      label: 'Department',
      isMulti: false,
      fetchType: 'department',
    },
    {
      name: 'vendor',
      label: 'Vendor',
      isMulti: false,
      fetchType: 'vendor',
    },
    {
      name: 'requester',
      label: 'Requester',
      isMulti: false,
      fetchType: 'user',
    },
    {
      name: 'status',
      label: 'Status',
      isMulti: false,
      options: [
        { label: 'Approved', value: 'APPROVED' },
        { label: 'Received', value: 'RECEIVED' },
        { label: 'Invoiced Paid', value: 'INVOICED_PAID' },
        { label: 'Closed', value: 'CLOSED' },
      ],
    },
  ];

  const filterCmp = useMemo(
    () => (
      <Search
        type="text"
        placeholder="Search by title"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText, resetPaginationToggle],
  );

  return (
    <>
      <Box $mobExtend $asHolder $noOverflow>
        <Text type="subtitle" weight={500}>
          Orders Overview
        </Text>
        <br />
        <div className={generalStyles.tabSection}>
          <div className={generalStyles.search}>
            {filterCmp}
            {accessPO?.viewAllCompany && (
              <div className={generalStyles.filters}>
                <Filter
                  control={control}
                  filterData={filterData}
                  setTriggerRefetch={setTrigger}
                  setValue={setValue}
                />
              </div>
            )}
          </div>
        </div>
        <Suspense fallback={<h2>Loading...</h2>}>
          <DataTableBaseRemote
            columns={columns || []}
            selectableRows={false}
            paginationResetDefaultPage={resetPaginationToggle}
            fetchData={getData}
          />
        </Suspense>
      </Box>

      <Modal
        $show={showRecallModal}
        $close={closeModal}
        $title="Recall Purchase Order"
        $radius={12}
        $maxWidth="540px"
      >
        <ModalInner>
          <form onSubmit={handleSubmit(recallPurchaseOrder)}>
            <div className="inp-container">
              <Controller
                name="reason"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Recall reason is required',
                  },
                }}
                render={({ field }) => (
                  <Textarea
                    placeholder="Enter the reason here"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Reason to recall the Purchase Order"
                    $labelRequired
                    $counter
                    $counterMax={500}
                    {...field}
                  />
                )}
              />
              {errors.reason && <p className="error-message">{errors.reason?.message}</p>}
            </div>
            <br />
            <div className={generalStyles.pageButtons}>
              <Button.Main $primary $style="pink" type="submit" disabled={!isValid}>
                Save
              </Button.Main>
            </div>
          </form>
        </ModalInner>
      </Modal>
      <Modal
        $show={showDiscardModal}
        $close={() => setShowDiscardModal(false)}
        $title={'Discard'}
        $radius={16}
        $noCloseSign
      >
        <div className={modalStyles.modalTextOnly} style={{ textAlign: 'center' }}>
          <Text type="subtitle">Do you want to continue to permanently delete this entry?</Text>
          <div
            className={generalStyles.pageButtons + ' ' + style.legalEntitySetupBtns}
            style={{ justifyContent: 'center' }}
          >
            <Button.Main
              $primary
              $style="pink"
              type="button"
              onClick={() => setShowDiscardModal(false)}
            >
              No
            </Button.Main>
            <Button.Main
              $primary
              $style="gray"
              type="button"
              onClick={() => discardPurchaseOrder(itemId)}
            >
              Yes
            </Button.Main>
          </div>
        </div>
      </Modal>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default PurchaseOrders;
