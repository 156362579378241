import React, { useEffect, useRef, useState } from 'react';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button';
import Text from '../../components/UI/Typography/Text';
import Box from '../../components/UI/General/Box';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import styles from '../../styles/requisition.module.css';
import Textarea from '../../components/UI/Forms/Textarea';
import spmsServiceService from '../../services/spmsService.service';
import { useStore } from '../../store/store';
import Toast from '../../components/UI/General/Toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import TabsSlider from '../../components/UI/General/TabsSlider';
import ApprovalTab from '../../components/Admins/PurchaseOrders/Tabs/ApprovalTab';
import moment from 'moment';
import BackButton from '../../components/shared/BackButton';
import Label from '../../components/UI/Forms/Label';
import Documents from '../../components/UI/Forms/Documents';
import Select from 'react-select';
import Input from '../../components/UI/Forms/Input';
import DatePicker from 'react-datepicker';
import { paymentMethods } from '../../constants/paymentMethods';
import { regExps } from '../../utils/regExps';
import Icon from '../../components/UI/General/Icon';

const PaymentsCreate = (props) => {
  const [invoiceId, setInvoiceId] = useState(props?.invoiceId);
  const [linkedInvoices, setLinkedInvoices] = useState([]);
  const { orderId, paymentItemId } = useParams();
  const navigate = useNavigate();
  const { currencies } = useStore((state) => state);
  const userId = useStore((state) => state.user?.id);
  const [values, setValues] = useState(null);
  const [taxes, setTaxes] = useState(null);
  const [tab, setTab] = useState('overview');
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [show, setShow] = useState(true);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const {
    handleSubmit,
    control,
    clearErrors,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      invoice: null,
      date: '',
      paymentMethod: '',
      payUsing: '',
      reference: '',
      paymentCurrency: '',
      amount: '',
      paymentNotes: '',
      files: [],
    },
    values,
  });

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_) => resolve(reader.result.split(';base64,')[1]);
      reader.onerror = (e) => reject(e);
    });
  }

  const onSubmit = async (data) => {
    console.log(data);
    setIsButtonsBlocked(true);
    setToast((item) => ({ ...item, opened: false }));
    const { files } = data;
    let newFiles = [];
    if (files !== undefined && files.length > 0) {
      const filesArray = files.map(async (item) => ({
        name: item.name,
        document: await getBase64(item.attachment),
      }));
      newFiles = await Promise.all(filesArray).then((result) => result);
    }
    const updData = {
      invoice: {
        id: invoiceId,
        invoiceNumber: data.invoiceNumber,
      },
      paymentMethod: data.paymentMethod.value,
      payUsing: data.payUsing.value,
      currency: data.paymentCurrency.value,
      amount: parseFloat(data.amount),
      reference: data.reference,
      date: data.date,
      notes: data.paymentNotes,
      attachments: newFiles,
    };
    console.log(updData);
    if (!!orderId) {
      if (!!paymentItemId) {
        await spmsServiceService
          .updatePurchaseOrderPayment(orderId, paymentItemId, updData)
          .then((r) => {
            console.log(r);
            setToast({
              opened: true,
              message: 'Payment updated successfully',
              type: 'success',
              cb: () => {
                navigate('/purchase-orders/' + orderId, { state: { tabToSwitch: 'payment' } });
                props.refetch();
              },
            });
          })
          .catch((reason) => {
            console.log(reason);
            setIsButtonsBlocked(false);
            setToast({
              opened: true,
              message:
                reason.response.data.message !== undefined
                  ? reason.response.data.message
                  : reason.response.data.error,
              type: 'fail',
            });
          });
      } else {
        await spmsServiceService
          .createPurchaseOrderPayment(orderId, updData)
          .then((r) => {
            console.log(r);
            setToast({
              opened: true,
              message: 'Payment created successfully',
              type: 'success',
              cb: () => {
                if (!!props?.fromPo) {
                  navigate('/purchase-orders/' + orderId);
                  props.refetch();
                } else {
                  navigate('/purchase-orders/' + orderId, { state: { tabToSwitch: 'payment' } });
                  props.refetch();
                }
              },
            });
          })
          .catch((err) => {
            console.log(err);
            setIsButtonsBlocked(false);
            setToast({
              opened: true,
              message:
                err.response.data.message !== undefined
                  ? err.response.data.message
                  : err.response.data.error,
              type: 'fail',
            });
          });
      }
    }
  };

  useEffect(() => {
    if (!!paymentItemId && !!orderId) {
      spmsServiceService
        .getPurchaseOrderPayment(orderId, paymentItemId)
        .then((r) => {
          console.log(r.data.data);
          if (r.data.message === 'Operation Successful') {
            const { date, paymentMethod, payUsing, invoice, notes, attachments, ...rest } =
              r.data.data;
            const data = {
              ...rest,
              paymentNotes: notes,
              invoiceNumber: invoice.invoiceNumber,
              paymentMethod: paymentMethods.find((el) => el.value === paymentMethod),
              payUsing: paymentMethods.find((el) => el.value === payUsing),
              date: new Date(date),
              paymentCurrency: { label: rest?.currency, value: rest?.currency },
              totalInvoiceAmount: rest?.amount,
              paymentsAttachments: attachments,
            };
            setValues(data);
            setInvoiceId(invoice.id);
            setShow(!!paymentItemId && r.data.data.paymentStatus === 'PARTIALLY_PAID');
          }
        })
        .catch((err) => {
          console.log(err);
          setToast({
            opened: true,
            message: err?.response?.data?.message ?? 'some error',
            type: 'fail',
          });
        });
    }
  }, [orderId, paymentItemId]);

  useEffect(() => {
    if (!invoiceId) {
      spmsServiceService.getPurchaseOrderInvoices(orderId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          console.log(r.data.data);
          setLinkedInvoices(
            r.data.data
              .filter((el) => el.approvalStatus === 'APPROVED')
              .map((item) => ({ label: item.invoiceNumber, value: item.id })),
          );
        }
      });
    }
  }, [orderId, invoiceId]);

  // below can be deleted

  useEffect(() => {
    const fetchOrder = async () => {
      await spmsServiceService.getPurchaseOrder(orderId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          console.log(r.data.data);
          const {
            purchaseOrderItems,
            currency,
            totalInvoicedAmount,
            total,
            purchaseOrderNo,
            attachments,
            invoiceStatus,
            ...rest
          } = r.data.data;
          setValues((s) => ({
            ...s,
            ...rest,
            totalInvoicedAmount,
            purchaseOrderNo: purchaseOrderNo,
            poTotal: total,
          }));
        }
      });
    };
    if (orderId !== undefined) {
      fetchOrder();
    }
  }, [orderId, invoiceId, taxes, userId]);

  useEffect(() => {
    const fetchInvoice = async () => {
      await spmsServiceService.getPurchaseOrderInvoice(orderId, invoiceId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          console.log(r.data.data);
          const { invoiceDueDate, invoiceIssueDate, ...other } = r.data.data;
          setValues((s) => ({
            ...s,
            ...other,
            invoiceIssueDate: new Date(invoiceIssueDate),
            invoiceDueDate: new Date(invoiceDueDate),
          }));
        }
      });
    };
    if (!!orderId && !!invoiceId) {
      fetchInvoice();
    }
  }, [invoiceId, orderId]);

  const files = (files) => files.map((file) => ({ attachment: file, name: file.name }));
  console.log(values);
  return (
    <>
      <Box $mobExtend $asHolder>
        <div className={generalStyles.top}>
          <div>
            <Button.ActionLabeled
              onClick={() =>
                navigate('/purchase-orders/' + orderId, { state: { tabToSwitch: 'payment' } })
              }
            >
              <Icon $width={20} $height={20} $icon="arrow-right" $color="#145ff5" />
              <Text weight={500}>Back</Text>
            </Button.ActionLabeled>
          </div>
          {!!invoiceId ? (
            <div className={generalStyles.title}>
              <Text type="subtitle" weight={500}>
                Create Payment ({values?.invoiceNumber})
              </Text>
              {show ? (
                <div className={generalStyles.addItemButton}>
                  <Button.Main
                    $mid
                    $style="blue"
                    type="submit"
                    onClick={handleSubmit((data) => onSubmit(data))}
                    disabled={!isDirty || isButtonsBlocked}
                  >
                    Submit
                  </Button.Main>
                  <Button.Main
                    $mid
                    $style="gray"
                    type="button"
                    onClick={() => {
                      if (!!props?.fromPo) {
                        navigate('/purchase-orders/' + orderId);
                      } else {
                        navigate(-1);
                      }
                    }}
                  >
                    Discard
                  </Button.Main>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="inp-container">
              <Label $title="Linked Invoice" />
              <Select
                classNamePrefix="react-select"
                isSearchable={false}
                placeholder="Select Payment Method"
                menuPortalTarget={document.body}
                menuPosition={'absolute'}
                menuPlacement={'bottom'}
                menuShouldScrollIntoView={false}
                options={linkedInvoices || []}
                onChange={(e) => {
                  console.log(e.value);
                  setInvoiceId(e.value);
                }}
              />
            </div>
          )}
        </div>
        {!!invoiceId ? (
          <div className={`${generalStyles.tabSection} ${generalStyles.underline}`}>
            <TabsSlider selected={tab}>
              <span onClick={() => setTab('overview')}>Overview</span>
              <span onClick={() => setTab('notes')}>Notes</span>
              <span onClick={() => setTab('documents')}>Documents</span>
            </TabsSlider>
          </div>
        ) : null}

        {tab === 'overview' && values && (
          <>
            {!!invoiceId ? (
              <>
                <Text type="subtitle" weight={500}>
                  Invoice Details
                </Text>
                <br />
                <div className={generalStyles.fieldsThree}>
                  <div className={styles.invoiceTopItem}>
                    <Text weight={600} type={'body-2'}>
                      Inv №
                    </Text>
                    <Text type={'body-2'}>{values?.invoiceNumber}</Text>
                  </div>
                  <div className={styles.invoiceTopItem}>
                    <Text weight={600} type={'body-2'}>
                      PO №
                    </Text>
                    <Text type={'body-2'}>{values?.purchaseOrderNo}</Text>
                  </div>
                  <div className={styles.invoiceTopItem}>
                    <Text weight={600} type={'body-2'}>
                      Vendor
                    </Text>
                    <Text type={'body-2'}>{values?.vendor?.legalName}</Text>
                  </div>
                  <div className={styles.invoiceTopItem}>
                    <Text weight={600} type={'body-2'}>
                      Issue Date
                    </Text>
                    <Text type={'body-2'}>
                      {moment(values?.invoiceDueDate).format('DD/MM/YYYY')}
                    </Text>
                  </div>
                  <div className={styles.invoiceTopItem}>
                    <Text weight={600} type={'body-2'}>
                      Due Date
                    </Text>
                    <Text type={'body-2'}>
                      {moment(values?.invoiceDueDate).format('DD/MM/YYYY')}
                    </Text>
                  </div>
                  <div className={styles.invoiceTopItem}>
                    <Text weight={600} type={'body-2'}>
                      Amount
                    </Text>
                    <Text type={'body-2'}>
                      {values?.vendor?.vendorFinancialInfo?.currency?.code}{' '}
                      {values?.totalInvoiceAmount}
                    </Text>
                  </div>
                </div>
                <br />
              </>
            ) : null}

            <div className={generalStyles.underline} />
            <br />

            <div className={generalStyles.fieldsThree}>
              <div className="inp-container">
                {!show ? (
                  <>
                    <Label $title="Payment Date" />
                    <Text type={'body-1'}>{moment(values?.date).format('DD/MM/YYYY')}</Text>
                  </>
                ) : (
                  <>
                    <Controller
                      name="date"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Payment date is required',
                        },
                      }}
                      render={({ field: { ref, ...rest } }) => {
                        return (
                          <DatePicker
                            {...rest}
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            placeholderText="Enter Payment date"
                            selected={rest.value}
                            disabled={!show}
                            wrapperClassName="custom-datepicker"
                            customInput={
                              <Input
                                $label="Payment Date"
                                $labelRequired
                                className={errors.hasOwnProperty(rest.name) && 'error'}
                              />
                            }
                            onChange={(e) => {
                              rest.onChange(e);
                              trigger(rest.name);
                            }}
                          />
                        );
                      }}
                    />
                    {errors.date && <p className="error-message">{errors.date.message}</p>}
                  </>
                )}
              </div>
              <div className="inp-container">
                <Label $title="Payment Method" $isRequired={show} />
                {!show ? (
                  <Text type={'body-1'}>{values?.paymentMethod?.label}</Text>
                ) : (
                  <>
                    <Controller
                      name="paymentMethod"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Payment Method is required',
                        },
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className={
                            errors.hasOwnProperty(field.name)
                              ? 'react-select-container error'
                              : 'react-select-container'
                          }
                          classNamePrefix="react-select"
                          isSearchable={false}
                          isDisabled={!show}
                          placeholder="Select Payment Method"
                          menuPortalTarget={document.body}
                          menuPosition={'absolute'}
                          menuPlacement={'bottom'}
                          menuShouldScrollIntoView={false}
                          options={paymentMethods || []}
                        />
                      )}
                    />
                    {errors.paymentMethod && (
                      <p className="error-message">{errors.paymentMethod.message}</p>
                    )}
                  </>
                )}
              </div>
              <div className="inp-container">
                <Label $title="Pay Using" $isRequired={show} />
                {!show ? (
                  <Text type={'body-1'}>{values?.paymentMethod?.label}</Text>
                ) : (
                  <>
                    <Controller
                      name="payUsing"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Pay Using is required',
                        },
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className={
                            errors.hasOwnProperty(field.name)
                              ? 'react-select-container error'
                              : 'react-select-container'
                          }
                          classNamePrefix="react-select"
                          isSearchable={false}
                          isDisabled={!show}
                          placeholder="Select Pay Using"
                          menuPortalTarget={document.body}
                          menuPosition={'absolute'}
                          menuPlacement={'bottom'}
                          menuShouldScrollIntoView={false}
                          options={paymentMethods || []}
                        />
                      )}
                    />
                    {errors.payUsing && <p className="error-message">{errors.payUsing.message}</p>}
                  </>
                )}
              </div>
              <div className="inp-container">
                {!show ? (
                  <>
                    <Label $title="Payment Ref" />
                    <Text type={'body-1'}>{values?.reference}</Text>
                  </>
                ) : (
                  <>
                    <Controller
                      name="reference"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Payment Ref is Required',
                        },
                        maxLength: {
                          value: 50,
                          message: 'Maximum 50 characters',
                        },
                        validate: {
                          allowed: (v) =>
                            /^[-&()0-9A-Za-z\s]*$/.test(v) ||
                            'Alphabetic, numeric, &()- characters and space only',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Payment Ref"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          $label="Payment Ref"
                          $labelRequired
                          disabled={!show}
                          {...field}
                        />
                      )}
                    />
                    {errors.reference && (
                      <p className="error-message">{errors.reference.message}</p>
                    )}
                  </>
                )}
              </div>
              <div className="inp-container">
                <Label $title="Currency" $isRequired={show} />
                {!show ? (
                  <Text type={'body-1'}>{values?.paymentCurrency?.label}</Text>
                ) : (
                  <>
                    <Controller
                      name="paymentCurrency"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Currency is required',
                        },
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          className={
                            errors.hasOwnProperty(field.name)
                              ? 'react-select-container error'
                              : 'react-select-container'
                          }
                          classNamePrefix="react-select"
                          placeholder="Select currency"
                          menuPortalTarget={document.body}
                          menuPosition={'absolute'}
                          menuPlacement={'bottom'}
                          menuShouldScrollIntoView={false}
                          options={currencies}
                        />
                      )}
                    />
                    {errors.paymentCurrency && (
                      <p className="error-message">{errors.paymentCurrency.message}</p>
                    )}
                  </>
                )}
              </div>
              <div className="inp-container">
                <Label $title="Amount" $isRequired={show} />
                {!show ? (
                  <Text type={'body-1'}>{values?.amount}</Text>
                ) : (
                  <>
                    <Controller
                      name="amount"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: 'Amount is required',
                        },
                        maxLength: {
                          value: 10,
                          message: 'Maximum 10 characters',
                        },
                        min: {
                          value: 0.01,
                          message: 'Min 0.01',
                        },
                        validate: {
                          allowed: (v) => /^[0-9]+([.][0-9]{1,2})?$/.test(v) || 'Max depth 0.00',
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          type="text"
                          placeholder="Enter Amount"
                          className={errors.hasOwnProperty(field.name) && 'error'}
                          disabled={!show}
                          {...field}
                        />
                      )}
                    />
                    {errors.amount && <p className="error-message">{errors.amount.message}</p>}
                  </>
                )}
              </div>
            </div>
          </>
        )}
        {tab === 'notes' && (
          <>
            {!show ? (
              <Text type={'body-1'}>{values?.paymentNotes ?? '-'}</Text>
            ) : (
              <div className="inp-container">
                <Controller
                  name="paymentNotes"
                  control={control}
                  rules={{
                    maxLength: {
                      value: 500,
                      message: 'Maximum 500 characters',
                    },
                    validate: {
                      allowed: (v) =>
                        regExps.notes.test(v) || 'Only Alpha and Numerical characters',
                    },
                  }}
                  render={({ field }) => (
                    <Textarea
                      {...field}
                      $low
                      $counter
                      $counterMax={500}
                      placeholder="Enter notes"
                      className={errors.hasOwnProperty(field.name) && 'error'}
                    />
                  )}
                />
                {errors.paymentNotes && (
                  <p className="error-message">{errors.paymentNotes.message}</p>
                )}
              </div>
            )}
          </>
        )}
        {tab === 'documents' && (
          <div className={styles.attachmentsBlockItem}>
            {!!values?.paymentsAttachments?.length && (
              <QuotesTab data={values?.paymentsAttachments} />
            )}
            {show ? (
              <div>
                <Label $title="Upload Supporting Documents" />
                <Documents
                  control={control}
                  fieldName="files"
                  filesSchema={files}
                  error={errors?.files?.message || errors?.files?.root?.message}
                  clearErrors={clearErrors}
                />
              </div>
            ) : null}
          </div>
        )}
      </Box>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default PaymentsCreate;
