import React, { useEffect, useMemo, useState } from 'react';
import DataTableBase from '../../UI/General/DataTableBase';
import Checkbox from '../../UI/Forms/Checkbox';

const Permissions = ({
  register,
  resources,
  roleName,
  setValue,
  getValues,
  initValues,
  isClientAdmin,
}) => {
  const [rowsViewDisabled, setRowsViewDisabled] = useState([]);
  useEffect(() => {
    const permissions = getValues('permissionResponse');
    console.log(permissions);
    if (permissions) {
      const rowsDisabled = [];
      permissions.forEach((row, index) => {
        if (
          row.authorizationTypes &&
          (row.authorizationTypes?.includes('CREATE') ||
            row.authorizationTypes?.includes('APPROVE') ||
            row.authorizationTypes?.includes('VIEW_ALL_COMPANY'))
        ) {
          rowsDisabled.push(index);
        }
      });
      setRowsViewDisabled(rowsDisabled);
    }
  }, [resources, getValues('permissionResponse')]);

  const columns = useMemo(
    () => [
      {
        name: 'System Module',
        width: '300px',
        selector: (row, index) => (
          <>
            <input
              type="hidden"
              {...register(`permissionResponse.${index}.resourceId`)}
              defaultValue={row.resourceId}
            />
            {row.resourceDescription}
          </>
        ),
      },
      {
        name: 'View All (Company)',
        width: '180px',
        cell: (row, index) => {
          const condition = getValues('permissionResponse')[index].authorizationTypes;
          let disabled;
          if (Array.isArray(condition)) {
            disabled = condition.includes('APPROVE');
          }
          return (
            <div>
              <Checkbox
                {...register(`permissionResponse.${index}.authorizationTypes[]`)}
                defaultValue={'VIEW_ALL_COMPANY'}
                defaultChecked={
                  Array.isArray(row.authorizationTypes) &&
                  row.authorizationTypes.includes('VIEW_ALL_COMPANY')
                }
                onChange={(e) => {
                  register(`permissionResponse.${index}.authorizationTypes[]`).onChange(e);
                  if (row?.resourceName !== 'Configuration') {
                    const moduleValues = getValues('permissionResponse')[index]?.authorizationTypes;
                    if (moduleValues.includes('VIEW_ALL_COMPANY')) {
                      ['VIEW_ALL_DEPARTMENT_LOCATION', 'VIEW_OWN'].forEach((value) => {
                        if (!moduleValues.includes(value)) moduleValues.push(value);
                      });
                      setRowsViewDisabled((rows) => {
                        const newRows = [...rows, index];
                        return [...new Set(newRows)];
                      });
                      console.log('moduleValues', moduleValues);
                      setValue(`permissionResponse.${index}.authorizationTypes[]`, moduleValues);
                    } else {
                      console.log('moduleValues', moduleValues);
                      setRowsViewDisabled((rows) => rows.filter((row) => row !== index));
                      ['VIEW_ALL_DEPARTMENT_LOCATION', 'VIEW_OWN'].forEach((value) => {
                        let index = moduleValues.indexOf(value);
                        if (index !== -1) moduleValues.splice(index, 1);
                      });
                      setValue(`permissionResponse.${index}.authorizationTypes[]`, moduleValues);
                    }
                  }
                }}
                $size={20}
                disabled={
                  ['CLIENT_ADMIN', 'END_USER'].includes(roleName) ||
                  (rowsViewDisabled.includes(index) && disabled)
                }
              />
            </div>
          );
        },
      },
      {
        name: 'View All (Own Department & Location)',
        width: '290px',
        cell: (row, index) => {
          const condition = getValues('permissionResponse')[index].authorizationTypes;
          let disabled;
          if (Array.isArray(condition)) {
            disabled = condition.includes('VIEW_ALL_COMPANY');
          }
          console.log('row.resourceName', row.resourceName);

          return (isClientAdmin && ['Configuration'].includes(row?.resourceName)) ||
            ['Budget_Management', 'Vendor_Management', 'Insights'].includes(row.resourceName) ? (
            <span>-</span>
          ) : (
            <div>
              <Checkbox
                {...register(`permissionResponse.${index}.authorizationTypes[]`)}
                defaultValue={'VIEW_ALL_DEPARTMENT_LOCATION'}
                defaultChecked={
                  Array.isArray(row.authorizationTypes) &&
                  row.authorizationTypes.includes('VIEW_ALL_DEPARTMENT_LOCATION')
                }
                $size={20}
                disabled={
                  ['CLIENT_ADMIN', 'END_USER'].includes(roleName) ||
                  (rowsViewDisabled.includes(index) && disabled)
                }
              />
            </div>
          );
        },
      },
      {
        name: 'View Own',
        cell: (row, index) => {
          const condition = getValues('permissionResponse')[index].authorizationTypes;
          let disabled;
          if (Array.isArray(condition)) {
            disabled =
              condition.includes('VIEW_ALL_COMPANY') ||
              condition.includes('VIEW_ALL_DEPARTMENT_LOCATION') ||
              condition.includes('CREATE');
          }
          return isClientAdmin && ['Configuration'].includes(row?.resourceName) ? (
            <span>-</span>
          ) : (
            <div>
              <Checkbox
                {...register(`permissionResponse.${index}.authorizationTypes[]`)}
                defaultValue={'VIEW_OWN'}
                defaultChecked={
                  Array.isArray(row.authorizationTypes) &&
                  row.authorizationTypes.includes('VIEW_OWN')
                }
                $size={20}
                disabled={
                  ['CLIENT_ADMIN', 'END_USER'].includes(roleName) ||
                  (rowsViewDisabled.includes(index) && disabled)
                }
              />
            </div>
          );
        },
      },
      {
        name: 'Create',
        cell: (row, index) =>
          isClientAdmin && ['Purchase_Orders', 'Configuration'].includes(row?.resourceName) ? (
            <span>-</span>
          ) : (
            <div>
              <Checkbox
                {...register(`permissionResponse.${index}.authorizationTypes[]`)}
                defaultValue={'CREATE'}
                defaultChecked={
                  Array.isArray(row.authorizationTypes) && row.authorizationTypes.includes('CREATE')
                }
                onChange={(e) => {
                  register(`permissionResponse.${index}.authorizationTypes[]`).onChange(e);
                  const moduleValues = getValues('permissionResponse')[index]?.authorizationTypes;
                  if (moduleValues.includes('CREATE')) {
                    ['VIEW_OWN', 'EDIT'].forEach((value) => {
                      if (!moduleValues.includes(value)) moduleValues.push(value);
                    });
                    setRowsViewDisabled((rows) => {
                      const newRows = [...rows, index];
                      return [...new Set(newRows)];
                    });
                    console.log('moduleValues', moduleValues);
                    setValue(`permissionResponse.${index}.authorizationTypes[]`, moduleValues);
                  } else {
                    setRowsViewDisabled((rows) => rows.filter((row) => row !== index));
                    ['VIEW_OWN', 'EDIT'].forEach((value) => {
                      let index = moduleValues.indexOf(value);
                      if (index !== -1) moduleValues.splice(index, 1);
                    });
                    console.log('moduleValues', moduleValues);
                    setValue(`permissionResponse.${index}.authorizationTypes[]`, moduleValues);
                  }
                }}
                $size={20}
                disabled={['CLIENT_ADMIN', 'END_USER'].includes(roleName)}
              />
            </div>
          ),
      },
      {
        name: 'Edit',
        cell: (row, index) =>
          isClientAdmin && ['Purchase_Orders', 'Configuration'].includes(row?.resourceName) ? (
            <span>-</span>
          ) : (
            <div>
              <Checkbox
                {...register(`permissionResponse.${index}.authorizationTypes[]`)}
                defaultValue={'EDIT'}
                defaultChecked={
                  Array.isArray(row.authorizationTypes) && row.authorizationTypes.includes('EDIT')
                }
                $size={20}
                disabled={['CLIENT_ADMIN', 'END_USER'].includes(roleName)}
                onChange={(e) => {
                  register(`permissionResponse.${index}.authorizationTypes[]`).onChange(e);
                  const moduleValues = getValues('permissionResponse')[index]?.authorizationTypes;
                  if (moduleValues.includes('EDIT')) {
                    setRowsViewDisabled((rows) => {
                      const newRows = [...rows, index];
                      return [...new Set(newRows)];
                    });
                    ['VIEW_OWN', 'CREATE'].forEach((value) => {
                      if (!moduleValues.includes(value)) moduleValues.push(value);
                    });
                    console.log('moduleValues', moduleValues);
                    setValue(`permissionResponse.${index}.authorizationTypes[]`, moduleValues);
                  } else {
                    setRowsViewDisabled((rows) => rows.filter((row) => row !== index));
                    ['VIEW_OWN', 'CREATE'].forEach((value) => {
                      let index = moduleValues.indexOf(value);
                      if (index !== -1) moduleValues.splice(index, 1);
                    });
                    console.log('moduleValues', moduleValues);
                    setValue(`permissionResponse.${index}.authorizationTypes[]`, moduleValues);
                  }
                }}
              />
            </div>
          ),
      },
      {
        name: 'Archive',
        cell: (row, index) =>
          isClientAdmin && ['Purchase_Orders', 'Configuration'].includes(row?.resourceName) ? (
            <span>-</span>
          ) : (
            <div>
              <Checkbox
                {...register(`permissionResponse.${index}.authorizationTypes[]`)}
                defaultValue={'DELETE'}
                defaultChecked={
                  Array.isArray(row.authorizationTypes) && row.authorizationTypes.includes('DELETE')
                }
                $size={20}
                disabled={['CLIENT_ADMIN', 'END_USER'].includes(roleName)}
                onChange={(e) => {
                  register(`permissionResponse.${index}.authorizationTypes[]`).onChange(e);
                  const moduleValues = getValues('permissionResponse')[index]?.authorizationTypes;
                  if (moduleValues.includes('DELETE')) {
                    setRowsViewDisabled((rows) => {
                      const newRows = [...rows, index];
                      return [...new Set(newRows)];
                    });
                    if (!moduleValues.includes('VIEW_OWN')) moduleValues.push('VIEW_OWN');
                    setValue(`permissionResponse.${index}.authorizationTypes[]`, moduleValues);
                  } else {
                    if (!moduleValues.find((module) => ['EDIT', 'CREATE'].includes(module))) {
                      setRowsViewDisabled((rows) => rows.filter((row) => row !== index));
                    }
                  }
                }}
              />
            </div>
          ),
      },
      {
        name: 'Approve',
        cell: (row, index) => (
          <>
            {['CLIENT_ADMIN', 'END_USER'].includes(roleName) ||
            (isClientAdmin &&
              ['Receipt', 'Payment', 'Purchase_Orders', 'Configuration'].includes(
                row?.resourceName,
              )) ? (
              <span>-</span>
            ) : (
              <Checkbox
                {...register(`permissionResponse.${index}.authorizationTypes[]`)}
                defaultValue={'APPROVE'}
                defaultChecked={
                  Array.isArray(row.authorizationTypes) &&
                  row.authorizationTypes.includes('APPROVE')
                }
                onChange={(e) => {
                  const init = initValues.find(
                    (el) => el.resourceId === row.resourceId,
                  )?.authorizationTypes;
                  register(`permissionResponse.${index}.authorizationTypes[]`).onChange(e);
                  const moduleValues = getValues(
                    `permissionResponse.${index}.authorizationTypes[]`,
                  );
                  if (moduleValues.includes('APPROVE')) {
                    setRowsViewDisabled((rows) => {
                      const newRows = [...rows, index];
                      return [...new Set(newRows)];
                    });
                    ['VIEW_ALL_COMPANY', 'VIEW_ALL_DEPARTMENT_LOCATION', 'VIEW_OWN'].forEach(
                      (value) => {
                        if (!moduleValues.includes(value)) moduleValues.push(value);
                      },
                    );
                    setValue(`permissionResponse.${index}.authorizationTypes[]`, moduleValues);
                  } else {
                    if (!moduleValues.find((module) => ['APPROVE'].includes(module))) {
                      setRowsViewDisabled((rows) => rows.filter((row) => row !== index));
                    }
                    ['VIEW_ALL_COMPANY', 'VIEW_ALL_DEPARTMENT_LOCATION', 'VIEW_OWN'].forEach(
                      (value) => {
                        if (!init?.includes(value)) {
                          let index = moduleValues.indexOf(value);
                          if (index !== -1) moduleValues.splice(index, 1);
                        }
                      },
                    );
                    setValue(`permissionResponse.${index}.authorizationTypes[]`, moduleValues);
                  }
                }}
                $size={20}
              />
            )}
          </>
        ),
      },
    ],
    [register, resources, rowsViewDisabled],
  );
  return <DataTableBase data={resources} columns={columns} pagination={false} />;
};

export default Permissions;
