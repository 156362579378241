import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Text from '../../components/UI/Typography/Text';
import Icon from '../../components/UI/General/Icon';
import styles from '../../styles/dashboard.module.css';
import { useStore } from '../../store/store';
import { useShallow } from 'zustand/react/shallow';
import { useAccessAllowed } from '../../hooks/useAccessAllowed';
import budgetService from '../../services/budgetService.service';
import generalStyles from '../../styles/general.module.css';
import spmsServiceService from '../../services/spmsService.service';
import Box from '../../components/UI/General/Box';
import VendorDashboard from '../VendorProfile/VendorDashboard';

function Dashboard() {
  const user = useStore(useShallow((state) => state.user));
  const activeCompany = useStore((state) => state.activeCompany);
  const [cardData, setCardData] = useState(null);

  const access = useAccessAllowed([
    'Requests',
    'Vendor_Management',
    'Budget_Management',
    'Payment',
    'Receipt',
    'Invoice',
  ]);

  const hasAnyApprovePermission = useMemo(() => !!access ? Object.values(access).find(el => el.approve === true) : undefined, [access])

  useEffect(() => {
    if (!!activeCompany?.id) {
      spmsServiceService.getMetrics(activeCompany?.id).then((r) => {
        if (r.data.message === 'Operation Successful') {
          const dashboardOverview = {
            user: [
              {
                name: 'Pending Approval',
                icon: 'approval-workflow',
                link: '/requisitions',
                state: { status: 'PENDING_APPROVAL' },
                value: parseInt(r.data.data.myRequisitionsPendingApproval ?? 0),
              },
              {
                name: 'Approved',
                icon: 'purchase-orders',
                link: '/requisitions',
                state: { status: 'APPROVED' },
                value: parseInt(r.data.data.myApprovedPurchaseOrders ?? 0),
              },
              {
                name: 'Received',
                icon: 'invoices',
                link: '/requisitions',
                state: { status: 'RECEIVED' },
                value: parseInt(r.data.data.myReceivedPurchaseOrders ?? 0),
              },
              {
                name: 'Closed',
                icon: 'password-check',
                link: '/requisitions',
                state: { status: 'CLOSED' },
                value: parseInt(r.data.data.myCompletedPurchaseOrders ?? 0),
              },
            ],
            pendingUser: [
              {
                name: 'Requests',
                icon: 'requests',
                link: '/approve/requisitions',
                value: parseInt(r.data.data.requisitionsPendingMyApproval ?? 0),
              },
              {
                name: 'Budget',
                icon: 'purchase-orders',
                link: '/approve/budget',
                value: parseInt(r.data.data.budgetRequestsPendingMyApproval ?? 0),
              },
              {
                name: 'Invoiced',
                icon: 'invoices',
                link: '/approve/invoices',
                value: parseInt(r.data.data.invoicesPendingMyApproval ?? 0),
              },
              {
                name: 'Vendors',
                icon: 'password-check',
                link: '/approve/vendor',
                value: parseInt(r.data.data.vendorsPendingMyApproval ?? 0),
                hide: !access?.vendor_management?.approve,
              },
            ],
          };
          setCardData(dashboardOverview);
        }
      });
    }
  }, [activeCompany, access]);

  return !!user.externalId ? (
    <VendorDashboard user={user} />
  ) : (
    <div className={styles.dashboard}>
      <Text type="h3" weight={400}>
        Hello, {user.firstName}!
      </Text>
      <div className={generalStyles.fieldsTwo}>
        <Box>
          <div className={styles.dashboardTitle}>
            <Text weight={600} type="subtitle">
              My Requests
            </Text>
          </div>
          <div className={generalStyles.fieldsTwo}>
            {cardData?.user?.map((item, index) => (
              <Link key={index} to={item.link} state={item.state}>
                <div className={styles.dashboardItem}>
                  <div className={styles.dashboardIcon}>
                    <Icon $icon={item.icon} $width={20} $height={20} $color="#fff" />
                  </div>
                  <div>
                    <Text className={styles.dashboardValue} weight={500} type="body-1">
                      {item.value}
                    </Text>
                    <div className={styles.dashboardSub}>
                      <Text weight={400} type="body-2">
                        {item.name}
                      </Text>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </Box>
        {!!hasAnyApprovePermission && (
          <Box>
            <div className={styles.dashboardTitle}>
              <Text weight={600} type="subtitle">
                Pending my Approval
              </Text>
            </div>
            <div className={generalStyles.fieldsTwo}>
              {cardData?.pendingUser?.map((item, index) => (
                <Link key={index} to={item.link} state={item.state}>
                  <div className={styles.dashboardItem}>
                    <div className={styles.dashboardIcon}>
                      <Icon $icon={item.icon} $width={20} $height={20} $color="#fff" />
                    </div>
                    <div>
                      <Text className={styles.dashboardValue} weight={500} type="body-1">
                        {item.value}
                      </Text>
                      <div className={styles.dashboardSub}>
                        <Text weight={400} type="body-2">
                          {item.name}
                        </Text>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </Box>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
