import { useState, useMemo, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';

import Icon from '../../components/UI/General/Icon';
import DataTableBase from '../../components/UI/General/DataTableBase';
import * as Button from '../../components/UI/Forms/Button';
import Dropdown from '../../components/UI/General/Dropdown';
import { Menu, MenuItem } from '../../components/UI/General/Menu';
import { ProgressBarWrapper, ProgressBarFill } from '../../components/UI/General/ProgressBar';

import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/budgets.module.css';
import Text from '../../components/UI/Typography/Text';
import EditApprovedGl from './EditApprovedGl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import spmsServiceService from '../../services/spmsService.service';
import Toast from '../../components/UI/General/Toast';
import budgetServiceService from '../../services/budgetService.service';
import { readableTitleFromBackend } from '../../utils/readableTitleFromBackend';
import moment from 'moment';

const ExpandedComponent = ({ data, getMonthNameByIndex }) => {
  const orderData = () => {
    if (!data.allocations) return [];
    const dataByYears = {};
    for (let i = 0; i < data.allocations.length; i++) {
      const [_month, year] = data.allocations[i].month.split('-');
      if (dataByYears[year]) {
        continue;
      } else {
        dataByYears[year] = data.allocations.filter((allocation) => {
          const [_filterMonth, filterYear] = allocation.month.split('-');
          return filterYear === year;
        });
      }
    }
    let orderedData = [];
    for (const months of Object.values(dataByYears)) {
      console.log('iteration', months);
      const orderedMonths = months.sort((a, b) => {
        const [aMonth, _aYear] = a.month.split('-');
        const [bMonth, _bYear] = b.month.split('-');
        return aMonth - bMonth;
      });
      orderedData = [...orderedData, ...orderedMonths];
    }
    return orderedData;
  };

  return (
    <div className={styles.expandedGlContainer}>
      <Text weight={600}>Budget Used / Budget Allocated </Text>
      <div className={generalStyles.fieldsSix}>
        {orderData().map((allocation) => (
          <div className={styles.expandedGlLine} key={allocation.month}>
            <Text weight={600}>{getMonthNameByIndex(allocation.month)}:</Text>
            <Text weight={500}>
              {allocation.totalPOApprovedAmount ?? 0} / {allocation.amount}
            </Text>
          </div>
        ))}
      </div>
    </div>
  );
};

const BudgetDetailsGlList = ({ monthsToRender, budget }) => {
  const [editableGl, setEditableGl] = useState(null);
  const [requisitions, setRequisitions] = useState(false);
  const { companyId, budgetId } = useParams();
  const allMonths = moment.months().map((month) => month.toLowerCase());

  const navigate = useNavigate();

  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  useEffect(() => {
    spmsServiceService
      .getCompany(companyId)
      .then((res) => setRequisitions(res.data.data.requisitionsRequired));
  }, []);

  const calculateUsedPercantage = (allocations) => {
    if (!allocations) return 0;
    const spentAmount = allocations.reduce((acc, curr) => acc + curr.totalPOApprovedAmount, 0);
    const allocated = allocations.reduce((acc, curr) => acc + curr.amount, 0);
    return !spentAmount && !allocated ? 0 : ((spentAmount / allocated) * 100).toFixed(0);
  };

  const columns = useMemo(
    () => [
      {
        name: 'Account Code',
        selector: (row) => row?.glAccountName || '-',
        sortable: true,
      },
      {
        name: 'Department',
        selector: (row) => row?.departmentName || '-',
        wrap: true,
      },
      {
        name: 'Location',
        selector: (row) => row?.locationName || '-',
      },
      {
        name: 'Total Allocated',
        cell: (row) => (
          <NumericFormat
            value={row?.glBudgetValue}
            displayType="text"
            thousandSeparator=","
            prefix={budget.currency + ' '}
            decimalScale={2}
          />
        ),
        sortable: true,
      },
      {
        name: 'Used',
        cell: (row) => {
          const percantage = calculateUsedPercantage(row.allocations);
          return (
            <div>
              <div className={styles.budgetUsed}>{percantage} %</div>
              <ProgressBarWrapper width={75}>
                <ProgressBarFill progress={percantage} />
              </ProgressBarWrapper>
            </div>
          );
        },
        sortable: false,
      },
      // {
      //   name: 'Action',
      //   allowOverflow: true,
      //   omit: budget.budgetStatus === 'ARCHIVED',
      //   button: true,
      //   cell: (row) => {
      //     return (
      //       <Dropdown collapsible className={generalStyles.actionMenu}>
      //         <Dropdown.Header>
      //           <Button.Action $style="white" $width={32} $height={32}>
      //             <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
      //           </Button.Action>
      //         </Dropdown.Header>
      //         <Dropdown.Body>
      //           <Menu className={generalStyles.actionMenuList}>
      //             <>
      //               <MenuItem onClick={() => setEditableGl(row)}>Edit</MenuItem>
      //               <Link to={'/purchase-orders/create'}>
      //                 <MenuItem>Create PO</MenuItem>
      //               </Link>
      //               {requisitions && (
      //                 <Link to={'/requisitions/create'}>
      //                   <MenuItem>Create Requisition</MenuItem>
      //                 </Link>
      //               )}
      //             </>
      //           </Menu>
      //         </Dropdown.Body>
      //       </Dropdown>
      //     );
      //   },
      // },
    ],
    [requisitions],
  );

  const editApprovedBudget = async (glData) => {
    setToast((item) => ({ ...item, opened: false }));
    const filteredGlItems = budget.glLineItems.filter((gl) => gl.id !== glData.id);
    const updatedGLItems = [...filteredGlItems, glData];
    const updatedBudget = {
      name: budget.name,
      currency: budget.currency,
      startDate: budget.startDate,
      endDate: budget.endDate,
      budgetType: budget.budgetType,
      budgetStatus: budget.budgetStatus,
      glLineItems: updatedGLItems,
    };
    budgetServiceService
      .editBudgetItem(updatedBudget, companyId, budgetId)
      .then((_response) => {
        navigate('/budgets/pending');
      })
      .catch((error) => {
        setToast({
          opened: true,
          message: error.response.data.message,
          type: 'fail',
        });
      });
  };

  const getMonthNameByIndex = (data) => {
    const [month, year] = data.split('-');
    const monthString = readableTitleFromBackend(allMonths[parseFloat(month) - 1]);
    return `${monthString} ${year}`;
  };

  return (
    <>
      <DataTableBase
        pagination
        data={budget?.glLineItems}
        columns={columns}
        expandOnRowClicked
        expandableRows
        expandableRowsComponent={editableGl ? EditApprovedGl : ExpandedComponent}
        expandableRowExpanded={(row) => row.id === editableGl?.id}
        onRowClicked={() => setEditableGl(null)}
        expandableRowsComponentProps={
          editableGl
            ? { setEditableGl, setToast, editApprovedBudget, getMonthNameByIndex, monthsToRender }
            : { getMonthNameByIndex }
        }
        separatePagination
      />
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </>
  );
};

export default BudgetDetailsGlList;
