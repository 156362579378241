import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import xlsxParser from 'xlsx-parse-json';
import styles from '../../../styles/companySetup.module.css';
import generalStyles from '../../../styles/general.module.css';
import { useStore } from '../../../store/store';
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from '../../../components/UI/General/Dropdown';
import Icon from '../../../components/UI/General/Icon';
import * as Button from '../../../components/UI/Forms/Button.jsx';
import { Menu, MenuItem } from '../../../components/UI/General/Menu';
import spmsServiceService from '../../../services/spmsService.service';
import Input from '../../../components/UI/Forms/Input';
import Box from '../../../components/UI/General/Box';
import Text from '../../../components/UI/Typography/Text';
import AddButton from '../../../components/shared/AddButton';
import DataTableBaseRemote from '../../../components/UI/General/DataTableBaseRemote';
import Toast from '../../../components/UI/General/Toast';

const GlSetup = () => {
  const companyId = useStore((state) => state.activeCompany?.id);
  const [allGlAmount, setAllGlAmount] = useState(null);
  const [tab, setTab] = useState('all');
  const [filterText, setFilterText] = useState('');
  const [debouncedValue, setDebouncedValue] = useState(filterText);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const [isArchivingDisabled, setIsArchivingDisabled] = useState(false);
  const navigate = useNavigate();
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  const hiddenFileInput = useRef(null);
  const columns = useMemo(
    () => [
      {
        name: 'Account Code',
        sortable: true,
        selector: (row) => row.glCode,
        cell: (row) => (
          <span data-content={'Account Code'} className={generalStyles.tableValue}>
            <Link to={'/gl-setup/' +companyId+'/edit/'+ row.id}>
              <span>{row?.glCode}</span>
            </Link>
          </span>
        ),
      },
      {
        name: 'Description',
        sortable: true,
        selector: (row) => row.description,
        cell: (row) => (
          <span data-content={'Description'}>
            <span>{row.description}</span>
          </span>
        ),
      },
      {
        name: 'Status',
        selector: (row) => row.type,
        sortable: true,
        cell: (row) => (
          <span data-content={'Status'} className={generalStyles.tableValue}>
            <span>{row.active ? 'Active' : 'Archived'}</span>
          </span>
        ),
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  {row.active ? (
                    <>
                      {!isArchivingDisabled && (
                        <MenuItem onClick={() => archiveGl([row.id])}>Archive</MenuItem>
                      )}
                      <MenuItem onClick={() => navigate(`/gl-setup/${companyId}/edit/${row.id}`)}>
                        Edit
                      </MenuItem>
                    </>
                  ) : (
                    <MenuItem onClick={() => activateGl([row.id])}>Activate</MenuItem>
                  )}
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [tab, isArchivingDisabled],
  );

  const archiveGl = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .deleteGlAccount(id)
      .then((r) => {
        setToast({
          opened: true,
          message: 'Archived',
          type: 'success',
        });
        setTriggerRefetch((triggerRefetch) => !triggerRefetch);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.message,
          type: 'fail',
        });
      });
  };

  const activateGl = (id) => {
    spmsServiceService
      .activateGlAccount(id)
      .then((r) => {
        setToast({
          opened: true,
          message: 'Activated',
          type: 'success',
        });
        setTriggerRefetch((triggerRefetch) => !triggerRefetch);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.message,
          type: 'fail',
        });
      });
  };

  useEffect(() => {
    const checkArchivingAvailable = async () => {
      const res = await spmsServiceService.getFilteredGlAccounts(companyId, 0, 2, {
        active: true,
      });
      const totalActiveElements = res.data.data.totalElements;
      setIsArchivingDisabled(totalActiveElements <= 1);
    };
    checkArchivingAvailable();
  }, [triggerRefetch]);

  const getData = useCallback(
    async (page, size) => {
      const search = {
        glCode: debouncedValue,
      };
      if (tab !== 'all') {
        search.active = tab === 'active';
      }
      const response = await spmsServiceService.getFilteredGlAccounts(
        companyId,
        page,
        size,
        search,
      );
      if (!allGlAmount) setAllGlAmount(response.data.data.totalElements);
      return response;
    },
    [companyId, tab, debouncedValue, triggerRefetch],
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(filterText);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      getData(0, 10);
    }
    return () => {
      ignore = true;
    };
  }, [getData]);

  const handleTabClick = (value) => {
    setTab(value);
    setResetPaginationToggle(!resetPaginationToggle);
    setToggleClearRows(!toggledClearRows);
  };

  const filterCmp = useMemo(
    () => (
      <Input
        type="text"
        $iconName="search"
        $iconColor="#fff"
        placeholder="Search by GL Code"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText],
  );

  const getTemplate = () => {
    spmsServiceService
      .getAttachmentsByKey('poms-upload-templates/POMS-GL-Import-Template.xlsx')
      .then((res) => {
        const path = res.data.data;
        const link = document.createElement('a');
        link.href = path;
        link.download = path.toString().split('/').pop();
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const uploadImportedFile = async (file) => {
    setToast((item) => ({ ...item, opened: false }));
    try {
      const data = await xlsxParser.onFileSelection(file);
      const firstSheet = data.Sheet1;
      const json = JSON.stringify(firstSheet);
      await spmsServiceService.uploadGlBulk(companyId, json);
      getData(0, 10);
      setTriggerRefetch((triggerRefetch) => !triggerRefetch);
      setToast({
        opened: true,
        message: 'The Gl was imported',
        type: 'success',
      });
    } catch (err) {
      setToast({
        opened: true,
        message: err.response.data.message,
        type: 'fail',
      });
    } finally {
      hiddenFileInput.current.value = null;
    }
  };

  const [toExport, setToExport] = useState([]);
  // useEffect(() => {
  //   const getExp = async () => {
  //     const response = await spmsServiceService.getFilteredGlAccounts(companyId, 0, allGlAmount, {
  //       glCode: debouncedValue,
  //     });
  //     const csvData = response.data.data.content.map((item) => ({
  //       'GL Code': item.glCode,
  //       Description: item.description,
  //       'Financial Type': item.type,
  //       Branches:
  //         item.branches !== undefined && allBranches !== null && item.branches.length > 0
  //           ? 'Limited'
  //           : 'All',
  //       Departments:
  //         item.departments !== undefined && allDepartments !== null && item.departments.length > 0
  //           ? 'Limited'
  //           : 'All',
  //       Users:
  //         item.users !== undefined && allUsers !== null && item.users.length > 0
  //           ? 'Limited'
  //           : 'All',
  //       Status: item.active !== undefined && item.active !== false ? 'Active' : 'Archived',
  //     }));
  //     setToExport(csvData);
  //   };
  //   if (allGlAmount === null) {
  //     console.log('no results for export');
  //   } else {
  //     getExp();
  //   }
  // }, [companyId, debouncedValue, trigger, allGlAmount]);

  return (
    <Box $mobExtend $asHolder $radius={12} $noOverflow>
      <div className={styles.title}>
        <Text type="subtitle" weight={500}>
          Account Codes
        </Text>
      </div>
      <div className={styles.departmentBox}>
        <div className={generalStyles.tabSection}>
          <div />
          <div className={generalStyles.addItemButton}>
            <div className={styles.importButton}>
              <Dropdown collapsible className={generalStyles.actionMenu}>
                <Dropdown.Header>
                  <Button.Main $style="blue" type="button">
                    <Icon $width={20} $height={20} $icon="add" $color="white" />
                    <Text weight={400}>Import from Excel</Text>
                  </Button.Main>
                </Dropdown.Header>
                <Dropdown.Body>
                  <Menu className={styles.actionMenuList}>
                    <MenuItem onClick={getTemplate}>Download the Template</MenuItem>
                    <MenuItem onClick={() => hiddenFileInput.current.click()}>
                      <span>
                        Upload GL
                        <input
                          hidden
                          ref={hiddenFileInput}
                          className={styles.importInput}
                          type="file"
                          onChange={(e) => {
                            uploadImportedFile(e.target.files[0]);
                          }}
                        />
                      </span>
                    </MenuItem>
                  </Menu>
                </Dropdown.Body>
              </Dropdown>
            </div>
            <AddButton $click={() => navigate(`/gl-setup/${companyId}/add`)} />
          </div>
        </div>
        <DataTableBaseRemote columns={columns || []} selectableRows={false} fetchData={getData} />
      </div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </Box>
  );
};

export default GlSetup;
