import styled, { css } from 'styled-components';
import styles from '../../styles/onboarding.module.css';
import headerStyles from '../../styles/loggedInLayout.module.css';
import Text from '../../components/UI/Typography/Text';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import CompanyOnboard from './CompanyOnboard';
import LocationOnboarding from './LocationOnboarding';
import GradientText from '../../components/UI/General/GradientText';
import DepartmentOnboarding from './DepartmentOnboarding';
import GLOnboarding from './GLOnboarding';
import Toast from '../../components/UI/General/Toast';
import { useStore } from '../../store/store';
import { useShallow } from 'zustand/react/shallow';
import { Auth } from 'aws-amplify';
import Dropdown from '../../components/UI/General/Dropdown';
import VendorLegalOnboarding from './VendorLegalOnboarding';
import VendorContactOnboarding from './VendorContactOnboarding';
import spmsServiceService from '../../services/spmsService.service';
import VendorAddressOnboarding from './VendorAddressOnboarding';
import VendorFinanceInfo from './VendorFinanceOnboarding';
import VendorDocsOnboarding from './VendorDocsOnboarding';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const Step = styled.div`
  display: flex;
  gap: 20px;
  color: #0c2b6bb2;
  & span {
    border: 1px solid #0c2b6bb2;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    ${({ $last }) => {
      if (!$last) {
        return css`
          &:after {
            content: '';
            position: absolute;
            top: 29px;
            left: 50%;
            width: 1px;
            height: 52px;
            background-color: #0c2b6bb2;
            z-index: -1;
          }
        `;
      }
    }}
  }
`;

const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const user = useStore((state) => state.user);
  const isUserVendor = user.roles.find((role) => role.role.name === 'VENDOR');
  const steps = useStore(useShallow((state) => state[isUserVendor ? 'vendorSteps' : 'steps']));
  const stepsDone = useStore((state) => state.stepsDone);
  const { companyId } = useParams();
  const [vendorData, setVendorData] = useState(null);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });

  async function logout() {
    await Auth.signOut().then(() => {
      sessionStorage.removeItem('selfsetup');
      window.dataLayer.push({
        event: 'sign_out',
        method: 'method',
        userId: CryptoJS.AES.encrypt(user.id, process.env.REACT_APP_AES_SECRET).toString(), // SHA-256 hashed unique user id from backend
        email: CryptoJS.AES.encrypt(user.email, process.env.REACT_APP_AES_SECRET).toString(), // SHA-256 hashed unique e-mail address from backend
      });
      console.log(window.dataLayer);
    });
  }

  useEffect(() => {
    if (!stepsDone) {
      const lastStepIndex = steps.findIndex((step) => !step.done);
      setCurrentStep(lastStepIndex);
    } else {
      if (companyId) {
        spmsServiceService.getMetrics(companyId).then((res) => {
          const metrix = res.data.data;
          console.log(metrix);
          const localSteps = { hasLocations: 1, hasDepartments: 2, hasGl: 3 };
          for (const key in localSteps) {
            if (!metrix[key]) {
              setCurrentStep(localSteps[key]);
              break;
            }
          }
        });
      } else {
        setCurrentStep(0);
      }
    }
  }, [steps, user, companyId]);

  useEffect(() => {
    const vendorId = user.externalId;
    if (!vendorId) return;
    spmsServiceService.getVendor(vendorId).then((res) => {
      setVendorData(res.data.data);
    });
  }, [user]);

  const nextStep = () => {
    if (currentStep === steps.length - 1) return;
    setCurrentStep(currentStep + 1);
  };

  const previousStep = () => {
    if (currentStep === 0) return;
    setCurrentStep(currentStep - 1);
  };
  const stepsComponents = {
    ca: [
      <CompanyOnboard nextStep={nextStep} setToast={setToast} />,
      <LocationOnboarding nextStep={nextStep} previousStep={previousStep} setToast={setToast} />,
      <DepartmentOnboarding nextStep={nextStep} previousStep={previousStep} setToast={setToast} />,
      <GLOnboarding previousStep={previousStep} setToast={setToast} />,
    ],
    vendor: [
      <VendorLegalOnboarding nextStep={nextStep} setToast={setToast} vendorData={vendorData} />,
      <VendorContactOnboarding
        nextStep={nextStep}
        previousStep={previousStep}
        setToast={setToast}
      />,
      <VendorAddressOnboarding
        nextStep={nextStep}
        previousStep={previousStep}
        setToast={setToast}
      />,
      <VendorFinanceInfo previousStep={previousStep} setToast={setToast} nextStep={nextStep} />,
      <VendorDocsOnboarding
        previousStep={previousStep}
        setToast={setToast}
        vendorData={vendorData}
      />,
    ],
  };

  return (
    <div className={`${styles.onboardingContainer} ${stepsDone && styles.newCompany}`}>
      {!stepsDone && (
        <div className={styles.onboardingSidebar}>
          <div className={styles.sidebarLogo}>
            <img src="/assets/img/login_register/spendproLogo.svg" alt="SpendPro-Logo" />
          </div>
          <div className={styles.onboardHeading}>
            <Text type="h2" weight={600}>
              Welcome!
            </Text>
            <GradientText $from="#313439" $to="#3778FF">
              <Text className={styles.headingSubText} type="h5" weight={400}>
                Create your SpendPro account in a few clicks
              </Text>
            </GradientText>
          </div>
          <div className={styles.sidebarSteps}>
            {steps.map((step, index) => (
              <Step key={index} $last={steps.length === index + 1} className={styles.sidebarStep}>
                <span>
                  {steps[index].done ? (
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 0C23.2843 0 30 6.71573 30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0ZM19.8295 10.4545L13.125 17.159L10.1705 14.2045C9.73115 13.7652 9.01884 13.7652 8.5795 14.2045C8.14017 14.6438 8.14017 15.3562 8.5795 15.7955L12.3295 19.5455C12.7688 19.9848 13.4812 19.9848 13.9205 19.5455L21.4205 12.0455C21.8598 11.6062 21.8598 10.8938 21.4205 10.4545C20.9812 10.0152 20.2688 10.0152 19.8295 10.4545Z"
                        fill="#0C2B6B"
                      />
                    </svg>
                  ) : (
                    <Text type="body-2">{index + 1}</Text>
                  )}
                </span>

                <Text type="subtitle" weight={currentStep === index ? 600 : 400}>
                  {step.text}
                </Text>
              </Step>
            ))}
          </div>
        </div>
      )}
      <div className={styles.onboardFormContainer}>
        {!stepsDone && (
          <div className={styles.onboardLogout}>
            <div className={headerStyles.headerBlock}>
              <Dropdown collapsible>
                <Dropdown.Header $activeClass={headerStyles.active}>
                  <div className={headerStyles.headerUser}>
                    <div className={headerStyles.headerAvatar}>
                      {user.profileImage !== null ? (
                        <img
                          src={'data:image/jpg;base64,' + user.profileImage}
                          alt={user.firstName + ' ' + user.lastName}
                          title={user.firstName + ' ' + user.lastName}
                        />
                      ) : (
                        <Text weight={600} type="subtitle">
                          {user.firstName && user.lastName
                            ? user.firstName.charAt(0) + user.lastName.charAt(0)
                            : 'V'}
                        </Text>
                      )}
                    </div>
                  </div>
                </Dropdown.Header>
                <Dropdown.Body>
                  <div className={headerStyles.headerDrop}>
                    <div className={headerStyles.headerDropSection}>
                      <div className={headerStyles.headerDropUserInfo}>
                        <div className={headerStyles.headerAvatar}>
                          {user.profileImage !== null ? (
                            <img
                              src={'data:image/jpg;base64,' + user.profileImage}
                              alt={user.firstName + ' ' + user.lastName}
                              title={user.firstName + ' ' + user.lastName}
                            />
                          ) : (
                            <Text weight={600} type="subtitle">
                              {user.firstName && user.lastName
                                ? user.firstName.charAt(0) + user.lastName.charAt(0)
                                : 'V'}
                            </Text>
                          )}
                        </div>
                        <div>
                          <Text type="body-2" weight={600}>
                            {user && `${user.firstName} ${user.lastName ? user.lastName : ''}`}
                          </Text>
                          <span>
                            <Text type="body-3" weight={500}>
                              {user && user.email}
                            </Text>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={headerStyles.headerDropSection}>
                      <Text as="a" className="active" type="body-2" weight={500} onClick={logout}>
                        Sign out
                      </Text>
                    </div>
                  </div>
                </Dropdown.Body>
              </Dropdown>
            </div>
          </div>
        )}
        {stepsComponents[isUserVendor ? 'vendor' : 'ca'][currentStep]}
        <div className={styles.onboardProgress}>
          {steps.map((step, index) => (
            <span key={step.text} className={index === currentStep ? styles.progressActive : ''} />
          ))}
        </div>
      </div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </div>
  );
};

export default Onboarding;
