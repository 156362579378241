import React, { useEffect, useState } from 'react';
import generalStyles from '../../styles/general.module.css';
import styles from '../../styles/systemRolesPermissions.module.css';
import * as Button from '../../components/UI/Forms/Button';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '../../components/UI/General/Box';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../components/UI/Forms/Input';
import Toast from '../../components/UI/General/Toast';
import Permissions from '../../components/Admins/SystemRolesAndPermissions/Permissions';
import requestsService from '../../services/requestsService.service';
import { useStore } from '../../store/store';
import ModalDiscard from '../../components/shared/ModalDiscard';
import BackButton from '../../components/shared/BackButton';
import Text from '../../components/UI/Typography/Text';

const CreateRole = () => {
  const user = useStore((state) => state.user);
  const companyId = useStore((state) => state.company?.id);
  const company = useStore((state) => state.company);
  const { roleId } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState();
  const [roleName, setRoleName] = useState();
  const [resources, setResources] = useState([]);
  const [editPermissions, setEditPermissions] = useState();
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const setIsDiscarded = useStore((state) => state.setIsDiscarded);
  const permissionsChanged = useStore((state) => state.permissionsChanged);
  const setPermissionsChanged = useStore((state) => state.setPermissionsChanged);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    formState: { errors, isValid, isDirty, dirtyFields },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      companyId: '',
      roleName: '',
      roleDescription: '',
      permissions: [],
    },
    values,
  });
  console.log(dirtyFields);
  const isClientAdmin = user?.roles
    ?.filter((r) => r?.company?.id === companyId)
    ?.map((el) => el?.role?.name)
    .includes('CLIENT_ADMIN');

  const onSubmit = (data) => {
    setToast((item) => ({ ...item, opened: false }));
    setIsButtonsBlocked(true);
    const { permissionResponse, companyId: roleCompanyId, ...other } = data;
    let idOfCompany = companyId;
    if (roleCompanyId !== '' && roleCompanyId !== null) {
      idOfCompany = roleCompanyId;
    }
    if (company.parentId !== undefined && (roleCompanyId === '' || roleCompanyId === null)) {
      idOfCompany = company.parentId;
    }
    const newData = {
      ...other,
      companyId: idOfCompany,
      permissions: permissionResponse.map((item) => {
        if (typeof item.authorizationTypes === 'string') {
          item.authorizationTypes = [item.authorizationTypes];
        }
        return {
          resourceId: parseInt(item.resourceId),
          authorizationTypes: item.authorizationTypes !== false ? item.authorizationTypes : [],
        };
      }),
    };
    if (roleId !== undefined) {
      requestsService
        .editRoleByCompany(roleId, idOfCompany, newData)
        .then((r) => {
          setIsDiscarded(false);
          setPermissionsChanged(!permissionsChanged);
          setToast({
            opened: true,
            message: 'Role updated successfully',
            type: 'success',
            cb: () => {
              setIsButtonsBlocked(false);
              navigate('/user-roles-and-permissions/active');
            },
          });
        })
        .catch((err) => {
          console.log(err);
          setToast({
            opened: true,
            message: err.response.data?.errors[0]?.message ?? err.response.data.message,
            type: 'fail',
          });
          setIsButtonsBlocked(false);
        });
    } else {
      requestsService
        .createRole(newData)
        .then((r) => {
          setIsDiscarded(false);
          setToast({
            opened: true,
            message: 'Role created successfully',
            type: 'success',
            cb: () => {
              navigate('/user-roles-and-permissions/active');
              setIsButtonsBlocked(false);
            },
          });
        })
        .catch((err) => {
          setToast({
            opened: true,
            message: err.response.data?.errors[0]?.message ?? err.response.data.message,
            type: 'fail',
          });
          setIsButtonsBlocked(false);
        });
    }
  };

  useEffect(() => {
    const getRoleData = async () => {
      await requestsService.getRoleById(roleId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          const { permissionResponse, ...other } = r.data.data;
          setValues(other);
          setRoleName(r.data.data.roleName);
          setEditPermissions(permissionResponse);
        }
      });
    };
    if (roleId !== undefined) {
      getRoleData();
    }
  }, [roleId]);

  useEffect(() => {
    requestsService.getResources().then((r) => {
      if (r.data.message === 'Operation Successful') {
        if (roleId !== undefined) {
          if (editPermissions !== undefined) {
            const mergedArray = r.data.data.map((item1) => {
              const item2 = editPermissions.find((item) => item.resourceId === item1.resourceId);
              return { ...item1, authorizationTypes: item2?.authorizationTypes };
            });
            setResources(mergedArray);
          }
        } else {
          setResources(r.data.data);
        }
      }
    });
  }, [roleId, editPermissions]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box $radius={12} className={styles.formInner}>
          <div className={generalStyles.top}>
            <div>
              <BackButton />
            </div>
            <div className={generalStyles.title}>
              <div className={generalStyles.titleText}>
                <Text type="subtitle" weight={500}>
                  {!!roleId ? 'Edit' : 'Create'} Role
                </Text>
              </div>
              <div className={generalStyles.addItemButton}>
                {!['CLIENT_ADMIN', 'END_USER'].includes(roleName) && (
                  <Button.Main
                    $mid
                    $style="blue"
                    type="submit"
                    disabled={!isDirty || isButtonsBlocked}
                  >
                    {roleId ? 'Update' : 'Save'}
                  </Button.Main>
                )}
                <Button.Main
                  $mid
                  $style="gray"
                  onClick={() =>
                    isDirty && !!Object.keys(dirtyFields).length ? setShowModal(true) : navigate(-1)
                  }
                  type="button"
                >
                  {['CLIENT_ADMIN', 'END_USER'].includes(roleName) ? 'Back to Roles' : 'Cancel'}
                </Button.Main>
              </div>
            </div>
          </div>
          <div className={styles.rolesFields}>
            <div className="inp-container">
              <Controller
                name="companyId"
                control={control}
                render={({ field }) => <input type="hidden" {...field} />}
              />
              <Controller
                name="roleName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Role name is required',
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Role Name"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Role Name"
                    $labelRequired
                    $tooltip="Name of the role"
                    disabled={values?.type === 'SYSTEM'}
                    {...field}
                    value={
                      field.value === 'CLIENT_ADMIN'
                        ? 'Site Admin'
                        : field.value === 'END_USER'
                        ? 'User'
                        : field.value
                    }
                  />
                )}
              />
              {errors.roleName && <p className="error-message">{errors.roleName.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="roleDescription"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Role description is required',
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    $label="Description"
                    $labelRequired
                    $tooltip="More information about the role"
                    placeholder="Enter Description"
                    disabled={values?.type === 'SYSTEM'}
                    className={errors.hasOwnProperty(field.name) && 'error'}
                  />
                )}
              />
              {errors.roleDescription && (
                <p className="error-message">{errors.roleDescription.message}</p>
              )}
            </div>
          </div>
          <Permissions
            register={register}
            resources={
              !!isClientAdmin
                ? resources
                : resources.filter((resource) => resource.resourceName !== 'Configuration')
            }
            roleName={roleName}
            setValue={setValue}
            getValues={getValues}
            initValues={editPermissions ?? []}
            isClientAdmin={isClientAdmin}
          />
        </Box>
      </form>
      <ModalDiscard
        setShowModal={setShowModal}
        showModal={showModal}
        navigateTo={'/user-roles-and-permissions/active'}
        condition={isDirty && !!Object.keys(dirtyFields).length}
      />
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default CreateRole;
